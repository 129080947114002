import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

import {
  MarketingPreferencesDS,
  buildValidationSchema,
  Link,
  ButtonWithStates
} from '@comicrelief/component-library';

import {
  fieldMappings
} from './_utils';

import {
  MarketingPrefsWrapper,
  ButtonHolder,
  MarketingPrefsSubmitCopy,
  MarketingPrefsShowHide
} from './Prize.styles';

import { formatMarketingPreferences } from './formatMarketingPreferences';
import sendMarketingPreferences from './sendMarketingPreferences';
import { marketingPrefencesCopyTop, marketingPrefencesCopyBottom } from './_marketingPrefencesCopy';

const MarketingPreferencesForm = ({ handleScroll }) => {
  const [marketingPrefSelected, setMarketingPrefSelected] = useState(false);
  const [marketingPrefsSubmitError, setMarketingPrefsSubmitError] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  /* Marketing Prefences form config */
  const validationOverrides = {
    // As these values are being provided by the main form, hide the inputs
    mp_permissionEmail: { hideInput: true },
    mp_permissionSMS: { hideInput: true },
    mp_permissionPhone: { hideInput: true },
    // Completely remove Postal option, as per 'legitimate interest' privacy policy
    mp_permissionPost: { disableOption: true }
  };

  const {
    mpValidationSchema,
    mpValidationOptions
  } = buildValidationSchema(validationOverrides);

  const marketingPrefMethods = useForm({
    resolver: yupResolver(mpValidationSchema),
    mode: 'onBlur',
    shouldFocusError: true
  });

  const {
    control, watch, handleSubmit, setValue, formState: {
      isSubmitting, isSubmitted
    }
  } = marketingPrefMethods;

  useEffect(() => {
    // Retrieve user details stored previously
    const details = JSON.parse(localStorage.getItem('userDetails'));

    if (details) {
      /* As we're now doing this on first render, need to use setValue here
      rather than setting react-hook-form's (pre-render) defaultValues config */
      Object.keys(fieldMappings).forEach((mpField) => {
        const mappedField = fieldMappings[mpField];
        setValue(mpField, (details && details[mappedField]) ?? '');
      });

      /* Store details in state, also used as a flag to render form itself;
      if there's no userDetails available, the form won't be rendered */
      setUserDetails(details);

      // Clean-up storage
      localStorage.removeItem('userDetails');
    }
  }, []);

  /* As the Permission checkboxes aren't "required" in the MPs (so an unchecked form
    is 'technically' valid as to not interfere with any context), we need to do this
    slightly convoluted check to set the 'disable' prop of the submit button */
  const emailWatch = watch('mp_permissionEmail');
  const smsWatch = watch('mp_permissionSMS');
  const phoneWatch = watch('mp_permissionPhone');

  const mpSelected = (emailWatch && emailWatch.length > 0)
     || (smsWatch && smsWatch.length > 0)
     || (phoneWatch && phoneWatch.length > 0);

  useEffect(() => {
    setMarketingPrefSelected(mpSelected);
  }, [mpSelected]);

  const onMarketingPrefSubmit = async (formData) => {
    if (mpSelected) {
      try {
        /* Create the required submission object from a few supporter fields,
        and the Marketing Preference fields */
        const formattedMpFields = formatMarketingPreferences(userDetails, formData);
        await sendMarketingPreferences(formattedMpFields);
      } catch (error) {
        setMarketingPrefsSubmitError(true);
        Sentry.captureException(error);
      }
      handleScroll();
    }
  };

  return (
    <>
      <MarketingPrefsWrapper>

        {!(isSubmitted || marketingPrefsSubmitError) && (
          <FormProvider {...marketingPrefMethods}>
            <form
              onSubmit={handleSubmit(onMarketingPrefSubmit)}
              noValidate
            >
              <MarketingPrefsShowHide show={userDetails}>
                <MarketingPreferencesDS
                  control={control}
                  mpValidationOptions={mpValidationOptions}
                  copyTop={marketingPrefencesCopyTop}
                  copyBottom={marketingPrefencesCopyBottom}
                  formContext={marketingPrefMethods}
                />

                <ButtonHolder>
                  <ButtonWithStates
                    type="submit"
                    loading={isSubmitting}
                    disabled={!marketingPrefSelected}
                    loadingText="Submitting..."
                  >
                    Submit your preferences
                  </ButtonWithStates>
                </ButtonHolder>
              </MarketingPrefsShowHide>

            </form>
          </FormProvider>
        )}

        {(isSubmitted && !marketingPrefsSubmitError) && (
        <MarketingPrefsSubmitCopy tag="h2" size="lg" uppercase data-test="prize-mp-success">
          Thanks for submitting your preferences!
        </MarketingPrefsSubmitCopy>
        )}

        {(isSubmitted && marketingPrefsSubmitError) && (
          <>
            <MarketingPrefsSubmitCopy tag="h2" size="xl" uppercase data-test="prize-mp-failure">
              Oops, we had a technical issue with your preferences
            </MarketingPrefsSubmitCopy>

            <MarketingPrefsSubmitCopy tag="p">
              Please try updating your preferences
              {' '}
              <Link href="https://www.comicrelief.com/update-your-preferences/" type="standard" target="blank">here</Link>
              {' '}
              instead.
            </MarketingPrefsSubmitCopy>
          </>
        )}
      </MarketingPrefsWrapper>
    </>
  );
};

MarketingPreferencesForm.propTypes = {
  handleScroll: PropTypes.func.isRequired
};

export default MarketingPreferencesForm;
