import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  spacing, containers, Link, allowListed
} from '@comicrelief/component-library';
import { External, Internal } from '@comicrelief/component-library/dist/components/Atoms/Icons/index';

import renderRichText from '../RichText/RichText';

const Container = styled.div`
  width: 100%;
  max-width: ${containers.medium};
  margin: ${spacing('lg')} auto;
`;

const Wrapper = styled.div`
  position: relative;
  padding: ${spacing('lg')};
  margin: 0 ${spacing('m')};
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  background: ${({ theme }) => theme.color('white')};
`;

const InnerWrapper = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    max-width: ${containers.small};
  }
`;

const CTA = styled.div`
  position: absolute;
  right: ${spacing('m')};
  bottom: -${spacing('m')};
`;

const BiteSize = ({ data }) => {
  const {
    body, ctaLabel, ctaLink
  } = data;

  const isallowListed = allowListed(ctaLink);
  const target = isallowListed ? 'self' : 'blank';

  const icon = target === 'blank' ? <External colour="white" /> : <Internal colour="white" />;

  return (
    <Container>
      <Wrapper>
        <InnerWrapper>
          {renderRichText(body, 'white', 'Banner')}
        </InnerWrapper>
        {ctaLink && ctaLabel ? (
          <CTA>
            <Link
              color="red"
              href={ctaLink}
              target={target}
              type="button"
              icon={icon}
            >
              {ctaLabel}
            </Link>
          </CTA>
        ) : null}
      </Wrapper>
    </Container>
  );
};

BiteSize.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.shape({
      json: PropTypes.shape({})
    }),
    ctaLabel: PropTypes.string,
    ctaLink: PropTypes.string
  }).isRequired
};

export default BiteSize;
