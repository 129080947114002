import styled from 'styled-components';

const ContentSection = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const CenterSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RectangleWhiteBackground = styled.div`
  transform: rotate(1deg);
  background: ${({ theme }) => theme.color('white')};
  border-radius: 2px;
  width: 100%;
  height: 100%;
`;

const RectangleHandDrawn = styled.div`
  transform: rotate(-1deg);
  padding: 10px;
  border-radius: 3px;
  border: 3px solid ${({ theme }) => theme.color('black')};
  border-left: none;
  ::before {
    content: '';
    position: absolute;
    left: -2px;
    width: 3px;
    height: calc(100% - 7px);
    background: ${({ theme }) => theme.color('black')};
  }
  width: 100%;
  height: 100%;
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* Setup for 16:9 sized videos */
  width: 600px;
  height: 337px;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    width: 800px;
    height: 450px;
  }
  @media ${({ theme }) => theme.allBreakpoints('Nav')} {
    width: 900px;
    height: 506;
  }
  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    width: 1000px;
    height: 562px;
  }
  > video {
    width: 100%;
    height: 100%;
  }
`;

export {
  ContentSection,
  CenterSection,
  RectangleWhiteBackground,
  RectangleHandDrawn,
  VideoWrapper
};
