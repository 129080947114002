import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { VideoBanner as VideoBannerBase } from '@comicrelief/component-library';

const Wrapper = styled.div`
  width: 100%;
  video {
    display: block;
    /* fix 2px gap in safari */
    margin-left: -1px;
    width: calc(100% + 2px);
  }
`;

const VideoBanner = (props) => {
  const { data } = props;
  const {
    backgroundVideo, poster, muteAudio, loop,
    showPosterImageAfterPlaying, showUserControls, autoplayOnPageLoad
  } = data;

  const placeholder = poster?.fixed?.src;
  const video = backgroundVideo?.file?.url;

  // It's a Chrome requirement to mute any autoplay videos:
  // See https://developer.chrome.com/blog/autoplay/
  const isMuted = autoplayOnPageLoad ? true : muteAudio;

  return (
    <Wrapper>
      <VideoBannerBase
        placeholder={placeholder}
        video={video}
        autoPlay={autoplayOnPageLoad}
        muted={isMuted}
        controls={showUserControls}
        showPosterAfterPlaying={showPosterImageAfterPlaying}
        loop={loop}
      />
    </Wrapper>
  );
};

VideoBanner.propTypes = {
  data: PropTypes.shape({
    backgroundVideo: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    }),
    poster: PropTypes.shape({
      fixed: PropTypes.shape({
        src: PropTypes.string.isRequired
      })
    }),
    showPosterImageAfterPlaying: PropTypes.bool.isRequired,
    showUserControls: PropTypes.bool.isRequired,
    autoplayOnPageLoad: PropTypes.bool.isRequired,
    loop: PropTypes.bool.isRequired,
    muteAudio: PropTypes.bool.isRequired
  }).isRequired
};

export default VideoBanner;
