import renderBody from '../RichText/RichText';

const formatNodes = (nodes, bgColour) => {
  const formattedNodes = [];

  nodes.forEach((item) => {
    const thisItem = item;
    thisItem.copy = renderBody(thisItem.copy, bgColour);
    formattedNodes.push(thisItem);
  });

  return formattedNodes;
};

export default formatNodes;
