import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import SplashLogo from './SplashLogo';
import CircleButton from './CircleButton';
import MainHeading from './MainHeading';
import SoundEffectsOnSvg from './images/SoundEffectsOnSvg';
import SoundEffectsOffSvg from './images/SoundEffectsOffSvg';
import {
  StyledNosesContainer,
  StyledNoseWrapper1,
  StyledNoseWrapper2,
  StyledNoseWrapper3,
  StyledNoseWrapper4,
  StyledSoundIconWrapper,
  StyledSoundIcon,
  StyledAnimationWrapper1,
  StyledAnimationWrapper2,
  StyledAnimationWrapper3,
  StyledAnimationWrapper4
} from './JokeGenStep1.styles';

export default function Step1({
  step,
  setStep,
  soundOn,
  setSoundOn,
  playSounds,
  iconColor
}) {
  const handleCircleButtonClick = () => {
    setStep(2);

    window.dataLayer.push({
      event: 'clickCTA',
      buttonCTAtext: 'Enter'
    });

    if (soundOn === true) {
      playSounds(true, 'step2');
    }
  };

  const handleSoundIconClick = () => {
    setSoundOn(!soundOn);
    if (soundOn === false) {
      playSounds(true, 'step1');
    } else {
      playSounds(false, 'step1');
    }
  };

  const [splash, setSplash] = React.useState(true);

  /**
   * Curtain reveal background colour change
   */
  React.useEffect(() => {
    const interval = setInterval(() => {
      setSplash(false);
    }, 2000);

    return () => {
      clearTimeout(interval);
    };
  }, [splash]);

  return (
    <>

      {splash
        && <SplashLogo />}

      {!splash
        && (
        <>
          <MainHeading
            headingText="The Red Nose Comedy Club"
            headingColor={({ theme }) => theme.color('white')}
            step={step}
          />

          <StyledNosesContainer>

            <StyledNoseWrapper1>
              <StyledAnimationWrapper1>
                <StaticImage
                  src="./images/nose-4.png"
                  alt="Red nose face 4"
                  placeholder="none"
                />
              </StyledAnimationWrapper1>
            </StyledNoseWrapper1>

            <StyledNoseWrapper2>
              <StyledAnimationWrapper2>
                <StaticImage
                  src="./images/nose-1.png"
                  alt="Red nose face 1"
                  placeholder="none"
                />
              </StyledAnimationWrapper2>
            </StyledNoseWrapper2>

            <StyledNoseWrapper3>
              <StyledAnimationWrapper3>
                <StaticImage
                  src="./images/nose-3.png"
                  alt="Red nose face 3"
                  placeholder="none"
                />
              </StyledAnimationWrapper3>
            </StyledNoseWrapper3>

            <StyledNoseWrapper4 blueNose>
              <StyledAnimationWrapper4>
                <StaticImage
                  src="./images/nose-2-blue.png"
                  alt="Red nose face 2"
                  placeholder="none"
                />
              </StyledAnimationWrapper4>
            </StyledNoseWrapper4>

          </StyledNosesContainer>

          <CircleButton
            buttonText="Enter"
            step={step}
            buttonClick={handleCircleButtonClick}
            shadowColor={({ theme }) => theme.color('rnd_24_red_dark')}
          />

          <StyledSoundIconWrapper>
            <StyledSoundIcon
              onClick={handleSoundIconClick}
              iconColor={iconColor}
              aria-label="Sound on off button"
              data-testid="sound-button-step1"
            >
              {soundOn
                ? <SoundEffectsOnSvg colour={({ theme }) => theme.color('rnd_24_red_dark')} />
                : <SoundEffectsOffSvg colour={({ theme }) => theme.color('rnd_24_red_dark')} />}
            </StyledSoundIcon>
          </StyledSoundIconWrapper>

        </>
        )}

    </>
  );
}

Step1.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  soundOn: PropTypes.bool.isRequired,
  setSoundOn: PropTypes.func.isRequired,
  playSounds: PropTypes.func.isRequired,
  iconColor: PropTypes.func.isRequired
};
