import React from 'react';
import styled from 'styled-components';
import WobblyText from './WobblyText';

const HeadingWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export default function Heading() {
  const font1 = '3rem';
  const font2 = '3.5rem';

  return (
    <HeadingWrapper>
      <WobblyText
        headingText="Are you"
        headingColor="white"
        fontSize={font1}
      />
      <WobblyText
        headingText="rebel"
        headingColor="black"
        fontSize={font2}
      />
      <WobblyText
        headingText="enough to push..."
        headingColor="white"
        fontSize={font1}
      />
    </HeadingWrapper>
  );
}
