import React, { useState } from 'react';
import WobblyText from './WobblyText';
import DrawnCircle from './DrawnCircle';
import Squiggle from './Squiggle';

import {
  Container,
  HandDrawnCircle,
  Button,
  ButtonEdge,
  ButtonInner,
  TextWrapper,
  ButtonHeading,
  WobblyTextWrapper,
  SquiggleWrapper
} from './redButtonStyles';

export default function RedButton() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container>

      <HandDrawnCircle isHovered={isHovered}>
        <DrawnCircle />
      </HandDrawnCircle>

      <Button
        type="submit"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ButtonEdge>
          <ButtonInner>
            <TextWrapper>
              <ButtonHeading>
                The big
                {' '}
                <br />
                red button of
              </ButtonHeading>
              <WobblyTextWrapper>
                <WobblyText
                  headingText="Rebellion"
                  headingColor="black"
                  fontSize="3.8rem"
                />
              </WobblyTextWrapper>
              <SquiggleWrapper>
                <Squiggle />
              </SquiggleWrapper>
            </TextWrapper>
          </ButtonInner>
        </ButtonEdge>
      </Button>

    </Container>
  );
}
