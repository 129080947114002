import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { snakeCase } from 'lodash';

import { spacing, containers } from '@comicrelief/component-library';
import renderRichText from '../RichText/RichText';

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme, bg }) => theme.color(bg)};
`;

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  max-width: ${containers.small};

  padding: ${spacing('lg')} ${spacing('m')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('xl')} ${spacing('xl')};
    text-align: center;
  }
`;

const Banner = ({ data }) => {
  const {
    body, backgroundColour
  } = data;

  const bannerBg = snakeCase(backgroundColour || 'white');

  return (
    <Container bg={bannerBg}>
      <Wrapper>
        {body && renderRichText(body, bannerBg, 'Banner')}
      </Wrapper>
    </Container>
  );
};

Banner.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.shape({
      json: PropTypes.shape({})
    }),
    backgroundColour: PropTypes.string
  }).isRequired
};

export default Banner;
