import React from 'react';
import { SearchResult } from '@comicrelief/component-library';
import PropTypes from 'prop-types';
import moment from 'moment';
import kebabCase from 'lodash/kebabCase';
import pressReleaseDefaultImage from './assets/search_press-release.png';
import newsArticleDefaultImage from './assets/search_news-article.png';
import defaultImage from './assets/search_webpage.png';

const COPY_MAX_LENGTH = 500;

const Hit = ({ hit }) => {
  const { contentType } = hit;
  const fields = hit.fields ? hit.fields : hit;
  const {
    title,
    type,
    path,
    logo,
    image,
    theme,
    url,
    role,
    displayDate,
    introduction,
    summary,
    name,
    description
  } = fields;
  let imageUrl;
  let imageDescription = '';
  let processedUrl = '';
  let processedDate = '';
  let processedType = '';
  let processedIntro = summary || introduction || description;
  const processedTitle = title || name;
  if (logo && (logo?.fields?.file?.url) && (logo?.fields?.title)) {
    imageUrl = logo.fields.file.url;
    imageDescription = logo.fields.title;
  } else if (image && (image?.fields?.file?.url) && (image?.fields?.title)) {
    imageUrl = image.fields.file.url;
    imageDescription = image.fields.title;
  }
  switch (contentType) {
    case 'newsArticle':
      processedType = 'Article';
      processedUrl = `/${type === 'News Article' ? 'news' : 'press-releases'}/${path}`;
      processedDate = moment(displayDate).format('Do MMMM YYYY');
      if (!imageUrl) {
        imageUrl = type === 'News Article' ? newsArticleDefaultImage : pressReleaseDefaultImage;
      }
      break;
    case 'pagePartner':
      processedUrl = `/partners/${path}`;
      break;
    case 'person':
      processedIntro = processedIntro || role;
      processedUrl = `/about-comic-relief/${path}`;
      break;
    case 'landingPage':
    case 'pageDefault':
      processedUrl = `/${path}`;
      if (hit?.fields?.imageSeo?.fields?.file?.url) { // Check Landing Page SEO field is not empty
        imageUrl = hit.fields.imageSeo.fields.file.url;
      }
      break;
    default: {
      // projects does not have contentType
      const urlParts = url.split('/');
      processedUrl = `/projects/${kebabCase(theme)}/${urlParts[urlParts.length - 1]}`;
      break;
    }
  }
  if (!imageUrl) {
    imageUrl = defaultImage;
  }
  if (!imageDescription) {
    imageDescription = processedTitle;
  }
  if (processedIntro) {
    // strip html tags
    processedIntro = processedIntro.replace(/<\/?[^>]+(>|$)/g, '');
    // strip emoji icons
    processedIntro = processedIntro.replace(/[\uE000-\uF8FF]+/g, '');
    // shorten too long text
    if (processedIntro.length > COPY_MAX_LENGTH) {
      processedIntro = processedIntro.substr(0, COPY_MAX_LENGTH - 10);
      processedIntro = processedIntro.substr(
        0,
        Math.min(processedIntro.length, processedIntro.lastIndexOf(' '))
      );
      processedIntro = `${processedIntro}...`;
    }
  }
  return (
    <SearchResult
      href={processedUrl}
      type={processedType}
      imageLow={imageUrl}
      images={imageUrl}
      date={processedDate}
      title={processedTitle}
      copy={processedIntro}
      alt={imageDescription}
      smallImageWidth="35%"
    />
  );
};

Hit.propTypes = {
  hit: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool
    ])
  ).isRequired
};
export default Hit;
