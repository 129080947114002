import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import { CardDs } from '@comicrelief/component-library';
import isInternalUrl from '../../../utils/isInternalUrl';
import CtaLinkIcon from '../../CtaLinkIcon/CtaLinkIcon';
import renderRichText from '../../RichText/RichText';
import CarouselButton from './_CarouselButtons';
import { Container, Slide } from './CardsCarousel.style';

const CardsCarousel = ({ carouselData, isCarousel }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { cards } = carouselData;
  const carouselBg = snakeCase(carouselData.backgroundColour || 'white');

  const chosenCard = (cardIndex) => {
    const {
      body: cardBody,
      id,
      image,
      backgroundColour,
      link,
      linkLabel
    } = cards[cardIndex];

    const fallback = image?.fallback?.src;
    const imageLow = image?.placeHolder?.src;
    const images = image?.fluid?.srcSet;
    const bgColour = snakeCase(backgroundColour || 'white');
    const description = image?.description ? image.description : '';
    const isInternalLink = link && isInternalUrl(link);
    const target = isInternalLink ? 'self' : 'blank';

    return (
      <Slide key={id}>
        <CardDs
          target={target}
          link={link}
          linkLabel={linkLabel}
          imageLow={imageLow}
          image={fallback}
          images={images}
          backgroundColor={bgColour}
          imageAltText={description}
          icon={<CtaLinkIcon url={link} label={linkLabel} />}
          isCarousel
        >
          {cardBody && renderRichText(cardBody, bgColour, 'card')}
        </CardDs>
      </Slide>
    );
  };

  return (
    <>
      {(carouselData.cards.length > 0) && (
        <Container
          bgColour={carouselBg}
          isCarousel={isCarousel}
        >
          {chosenCard((slideIndex - 1 + cards.length) % cards.length)}
          {chosenCard(slideIndex)}
          {chosenCard((slideIndex + 1 + cards.length) % cards.length)}

          <CarouselButton
            slideIndex={slideIndex}
            setSlideIndex={setSlideIndex}
            carouselData={cards}
            blurColour={carouselBg}
            direction="left"
          />
          <CarouselButton
            slideIndex={slideIndex}
            setSlideIndex={setSlideIndex}
            carouselData={cards}
            blurColour={carouselBg}
            direction="right"
          />
        </Container>
      )}
    </>
  );
};

const cardPropTypes = PropTypes.shape({
  body: PropTypes.shape({
    raw: PropTypes.string.isRequired
  }),
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageLow: PropTypes.string.isRequired,
  images: PropTypes.string.isRequired,
  backgroundColour: PropTypes.string
});

CardsCarousel.propTypes = {
  isCarousel: PropTypes.bool.isRequired,
  carouselData: PropTypes.shape({
    backgroundColour: PropTypes.string,
    cards: PropTypes.arrayOf(cardPropTypes).isRequired
  }).isRequired
};

export default CardsCarousel;
