import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { spacing } from '@comicrelief/component-library';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${spacing('xsm')};

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin: -${spacing('sm')};
  }

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin: -${spacing('md')};
  }

  ${({ withMarginBottom }) => withMarginBottom && css`
    margin-bottom: ${spacing('xsm')};

    @media ${({ theme }) => theme.allBreakpoints('M')} {
      margin-bottom: ${spacing('sm')};
    }

    @media ${({ theme }) => theme.allBreakpoints('L')} {
      margin-bottom: ${spacing('md')};
    }
  `}
`;

const Column = styled.div`
  width: ${({ mobileColumns }) => Math.floor(10000 / mobileColumns) / 100}%;
  padding: ${spacing('sm')};

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: ${({ columns }) => Math.floor(10000 / columns) / 100}%;
  }

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    padding: ${spacing('md')};
  }
`;

const Grid = ({
  columns = 4,
  mobileColumns = 2,
  withMarginBottom = false,
  children,
  ...rest
}) => (
  <Container withMarginBottom={withMarginBottom} {...rest}>
    {React.Children.map(
      children,
      item => (item
        ? <Column key={item.key} columns={columns} mobileColumns={mobileColumns}>{item}</Column>
        : '')
    )}
  </Container>
);

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  columns: PropTypes.number,
  mobileColumns: PropTypes.number,
  withMarginBottom: PropTypes.bool
};

export default Grid;
