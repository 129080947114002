import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledProgressBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 2%;
  margin-top: 3vh;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin-top: 5vh;
  }
`;

const StyledBar = styled.div`
  height: 9px;
  background: black;
  border-radius: 10px;
  width: 46%;
  background: ${({ theme }) => theme.color('white')};
`;

const StyledRightBar = styled(StyledBar)`
  background: ${props => (props.step === 3 ? 'white' : 'rgba(255, 255, 255, 0.4)')};
`;

export default function BottomProgressBar({ step }) {
  return (
    <StyledProgressBar>

      <StyledBar />

      <StyledRightBar step={step} />

    </StyledProgressBar>
  );
}

BottomProgressBar.propTypes = {
  step: PropTypes.number.isRequired
};
