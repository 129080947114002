import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.color('black')};
  border-bottom: 2px solid ${({ theme }) => theme.color('black')};;
  font-weight: normal;
  font-size: inherit;
  padding: 0;
  :hover {
    color: ${({ theme }) => theme.color('black')};
    border-bottom: 2px solid ${({ theme }) => theme.color('black')};;
    font-weight: normal;
  }
`;

const Anchor = (props) => {
  const { dest, children } = props;

  const goToAnchor = (e) => {
    e.preventDefault();

    const id = dest.slice(1);
    const destination = document.getElementById(id);

    if (destination) {
      destination.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'start'
        }
      );
    }
  };

  return (
    <Button onClick={e => goToAnchor(e)} type="standard">
      {children}
    </Button>
  );
};

Anchor.propTypes = {
  dest: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Anchor;
