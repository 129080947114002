import React from 'react';

export default function ArrowRight() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="background" d="M2.02318 55.5C2.02318 58.5683 13.4365 94.7373 43.9986 94.7373C74.5607 94.7373 97.4986 69.3396 93.9986 43.3991C89.6729 11.3394 61.5028 -2.16059 36.5029 3.33935C11.5031 8.83929 -0.0646714 36.6281 1.66953 52.8498C4.86108 82.7158 33.2975 101.203 61.4986 92.8396C93.0515 83.4807 104.337 46.906 85.4986 21.3464C65.2053 -3.54969 36.1534 1.15565 27.9365 8.5" fill="white" />
      <path d="M2.02318 55.5C2.02318 58.5683 13.4365 94.7373 43.9986 94.7373C74.5607 94.7373 97.4986 69.3396 93.9986 43.3991C89.6729 11.3394 61.5028 -2.16058 36.5029 3.33935C11.5031 8.83929 -0.0646714 36.6281 1.66953 52.8498C4.86108 82.7158 33.2975 101.203 61.4986 92.8396C93.0515 83.4807 104.337 46.906 85.4986 21.3464C65.2053 -3.54969 36.1534 1.15564 27.9365 8.5" stroke="#191B1D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.2977 48.724C26 48.724 35.2327 48.3591 44.1164 48.3591C53 48.3591 65.3239 48.6695 71.723 47.3364C67.2444 52.4509 61.1976 56.0609 57.4295 61.0107C62.6563 56.4531 67.8823 51.894 73.1091 47.3364C67.9958 43.8176 62.8824 40.2987 57.769 36.7799" stroke="#1D1E1C" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
