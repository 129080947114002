import styled, { css } from 'styled-components';
import {
  StyledIconButton
} from './JokeGenShared.styles';
import {
  faceJiggle, facePendulum, faceTwirl, faceLoopTheLoop, soundButtonSwell, faceZoom
} from './animations';

const zoomAnimation = css`
  ${faceZoom} 1s linear
`;

const StyledAnimationWrapper1 = styled.div`
  animation: ${zoomAnimation}, ${faceLoopTheLoop} 3.4s ease-in-out infinite 1s;
`;

const StyledAnimationWrapper2 = styled.div`
  animation: ${zoomAnimation}, ${faceJiggle} 1.2s linear infinite 1s;
`;

const StyledAnimationWrapper3 = styled.div`
  animation: ${zoomAnimation}, ${faceTwirl} 2.4s ease-in-out infinite 1s;
`;

const StyledAnimationWrapper4 = styled.div`
  animation: ${zoomAnimation}, ${facePendulum} 2.5s ease-in-out infinite 1s;
`;

const StyledNosesContainer = styled.div`
  margin: 0 auto 1vh;
  width: 90%;
  max-width: 550px;
  height: 49%;
  position: relative;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin: 1vh auto 1vh;
    height: 40vh;
  }
`;

const StyledNoseWrapper = styled.div`
  width: 34%;
  padding: 7%;
  position: absolute;
  border: 3px solid ${({ theme }) => theme.color('white')};
  border-radius: 50%;
  background: ${({ blueNose, theme }) => (blueNose === true ? theme.color('blue') : theme.color('red'))};
  box-shadow: 12px 8px ${({ theme }) => theme.color('rnd_24_red_dark')};
`;

const StyledNoseWrapper1 = styled(StyledNoseWrapper)`
  top: 0%;
  left: 35%;
`;

const StyledNoseWrapper2 = styled(StyledNoseWrapper)`
  top: 25%;
  right: 0%;
`;

const StyledNoseWrapper3 = styled(StyledNoseWrapper)`
  top: 38%;
  left: 3%;
`;

const StyledNoseWrapper4 = styled(StyledNoseWrapper)`
  bottom: 0%;
  left: 38%;
`;

/**
 * Sound Icon
 */
const StyledSoundIconWrapper = styled.div`
  width: 80%;
  margin: -6vh auto 0;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: 60%;
    margin: -3vh auto 0;
  }
  display: flex;
  justify-content: flex-end;
`;

const StyledSoundIcon = styled(StyledIconButton)`
  animation: ${soundButtonSwell} 2.5s ease-in-out infinite;
`;

export {
  StyledNosesContainer,
  StyledNoseWrapper1,
  StyledNoseWrapper2,
  StyledNoseWrapper3,
  StyledNoseWrapper4,
  StyledSoundIconWrapper,
  StyledSoundIcon,
  StyledAnimationWrapper1,
  StyledAnimationWrapper2,
  StyledAnimationWrapper3,
  StyledAnimationWrapper4
};
