/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */
import React from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { snakeCase } from 'lodash';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import * as BaseComponents from '@comicrelief/component-library';
import {
  Internal,
  External
} from '@comicrelief/component-library/dist/components/Atoms/Icons/index';
import LocalComponents from '..';

import {
  changeTextColor, changeIconColor, changeLinkColor, changeButtonColor
} from './utils';
import bold from './bold';
import heading from './heading';
import Anchor from '../Anchor/Anchor';
import EmailSignUp from '../EmailSignUp/EmailSignUp';
import EmbeddableVideo from '../EmbeddableVideo/EmbeddableVideo';

// Allow overriding CL components to add extra/ modify props
const Components = { ...BaseComponents, ...LocalComponents };
const {
  Text,
  Link,
  allowListed,
  Picture,
  RichText,
  Accordion,
  Countdown,
  spacing
} = Components;

const MarkupVideo = styled(RichText)`
  padding-top: 56.25%;
  height: 0px;
  position: relative;
  margin: 5rem 0;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const MarkupText = styled(RichText)`
  position: relative;
  margin-bottom: 1rem;
`;

const Image = styled(Picture)`
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 5rem auto;
  img {
    border-radius: 1rem;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 20px;
  }
`;

const List = styled.ul`
  text-align: left;
`;

const OrderedList = styled.ol`
  text-align: left;
`;

const Quote = styled.div`
  margin: ${spacing('xl')} 0;
  padding-left: ${spacing('xl')};
  position: relative;
  :before {
    content: '“';
    font-size: 80px;
    position: absolute;
    width: 40px;
    color: ${({ theme }) => theme.color('red')};
    left: -3px;
    top: -10px;
    font-family: ${({ theme }) => theme.fontFamilies('Montserrat')};
    font-weight: bold;
  }
`;

const isAnchor = link => link.charAt(0) === '#';

const options = {
  renderMark: {
    [MARKS.BOLD]: children => bold(children, options),
    [MARKS.ITALIC]: children => <i>{children}</i>
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => heading(node, children, options, 'h1', 'super'),
    [BLOCKS.HEADING_2]: (node, children) => {
      const isSMDS = options.type === 'SingleMessageDs';
      const fontSize = isSMDS ? 'xl' : 'xxl';
      const lineHeight = isSMDS ? '2rem' : '3rem';

      return heading(node, children, options, 'h2', fontSize, lineHeight);
    },
    [BLOCKS.HEADING_3]: (node, children) => heading(node, children, options, 'h3', 'xl', '2rem'),
    [BLOCKS.HEADING_4]: (node, children) => heading(node, children, options, 'h4', 'm', '1.25rem'),
    [BLOCKS.HEADING_5]: (node, children) => heading(node, children, options, 'h5', 's', '1rem'),
    // We currently don't actually have any styles for H6; the tag gets rendered with the correct
    // tag and fonts etc., just the spacing options and so on don't do anything. So, I'm adding this
    // for completeness, but have also suggested we implement styles for the additional option soon.
    [BLOCKS.HEADING_6]: (node, children) => heading(node, children, options, 'h6', 's', '1rem'),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const isCard = options.type === 'card';
      const isDescriptor = options.type === 'descriptor';
      const isDefaultPage = options.type === 'defaultPage';
      const fontSize = isCard || isDescriptor ? 's' : 'm';
      const thisTextColour = changeTextColor(options.background);
      const thisMobileTextColour = options.mobileTextColour;

      // If this component doesn't have button colour options, use
      // the provided background colour to determine its colour
      const thisButtonColour = options.buttonColour || changeButtonColor(options.background);
      const thisIconColour = changeIconColor(thisButtonColour);

      // Use the 'desktop' btn colour options if there's no mobile equivalents for this component:
      const thisMobileButtonColour = options.mobileButtonColour || thisButtonColour;
      const thisMobileIconColour = changeIconColor(thisMobileButtonColour);

      if (children.length === 1 && children[0] === '') {
        return null;
      }

      let childrenCopy = children;

      /* Determine if this Link is the only thing in the paragraph,
       *  NOT an anchor link and NOT in a Card */
      const ownChild = childrenCopy[0] === ''
        && childrenCopy[2] === ''
        && children[1]
        && children[1].props
        && childrenCopy[1].props.href !== undefined;

      if (
        ownChild
        && !isAnchor(childrenCopy[1].props.href)
        && !isCard
        && !isDefaultPage
        && !isDescriptor
      ) {
        // If so, change from the default 'standard' link to a button
        // with the appropriate colour and icon
        childrenCopy = React.cloneElement(childrenCopy[1], {
          type: 'button',
          color: thisButtonColour,
          mobileColour: thisMobileButtonColour,
          icon: (
            <Internal
              colour={thisIconColour}
              mobileColour={thisMobileIconColour}
            />
          )
        });
      }
      // If link is external change icon
      if (
        ownChild
        && !isDescriptor
        && childrenCopy
        && childrenCopy.props
        && childrenCopy.props.target === 'blank'
      ) {
        childrenCopy = React.cloneElement(childrenCopy, {
          type: 'button',
          color: thisButtonColour,
          mobileColour: thisMobileButtonColour,
          icon: (
            <External
              colour={thisIconColour}
              mobileColour={thisMobileIconColour}
            />
          )
        });
      }
      return (
        <ThemeConsumer>
          {theme => (
            <Text
              tag="p"
              family={theme.font.regular}
              color={thisTextColour}
              size={fontSize}
              mobileColor={thisMobileTextColour}
            >
              {childrenCopy}
            </Text>
          )}
        </ThemeConsumer>
      );
    },
    [BLOCKS.QUOTE]: (node) => {
      const { content } = node;
      // quote is wrapping paragraph and paragraph overrides quote style
      const text = content[0].content[0].value;
      const thisMobileTextColour = options.mobileTextColour;

      return (
        <ThemeConsumer>
          {theme => (
            <Quote>
              <Text
                style={{ fontStyle: 'italic' }}
                tag="p"
                family={theme.font.regular}
                color="red"
                size="m"
                mobileColor={thisMobileTextColour}
              >
                {text}
              </Text>
            </Quote>
          )}
        </ThemeConsumer>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.LIST_ITEM]: (node, children) => {
      const link = children.filter(child => child)[0].props.children.props;
      const isCard = options.type === 'card';
      const fontSize = isCard ? 'sm' : 'l';

      if (link) {
        const copy = link.children[0];
        const { href, target, color } = link;
        const thisLinkType = changeLinkColor(options.background);

        return (
          <li>
            <Text size={fontSize} tag="span" color={color}>
              <Link
                type={thisLinkType}
                href={href}
                target={target}
                mobileColor={options.mobileTextColour}
              >
                {copy}
              </Link>
            </Text>
          </li>
        );
      }
      return <li>{children}</li>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const { data } = node;
      const externaLink = data && data.uri;
      const isInternalLink = allowListed(externaLink);
      const isInternalPath = externaLink.charAt(0) === '/';
      const target = isInternalLink || isAnchor(externaLink) ? 'self' : 'blank';
      const noopener = isInternalLink || isAnchor(externaLink) ? null : 'noopener';
      const thisLinkType = changeLinkColor(options.background);

      if (isInternalLink || isInternalPath) {
        return (
          <Link type={thisLinkType} href={externaLink}>
            {children}
          </Link>
        );
      }

      if (isAnchor(externaLink)) {
        return <Anchor dest={externaLink}>{children}</Anchor>;
      }

      return (
        children[0] !== '' && (
          <Link
            type={thisLinkType}
            href={externaLink}
            target={target}
            rel={noopener}
          >
            {children}
          </Link>
        )
      );
    },
    // TODO: Not been used due loop memory leak issue in the past
    // new gatsby version has fixed it we can re implement it
    [INLINES.ENTRY_HYPERLINK]: () => null,
    [INLINES.ASSET_HYPERLINK]: ({ data }, children) => {
      const { target = {} } = data;
      const { file } = target;
      const thisLinkType = changeLinkColor(options.background);

      return (
        <Link
          type={thisLinkType}
          href={file && file.url}
          target="blank"
          rel="noopener"
        >
          {children}
        </Link>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => {
      const { target = {} } = data;
      const {
        description, title, fallback, fluid
      } = target;

      if (fluid) {
        return (
          <Image
            alt={description || title}
            imageLow={fallback && fallback.src}
            images={fluid && fluid.srcSet}
          />
        );
      }

      return <span>Use link to asset instead</span>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => {
      const { target } = data;
      /* eslint no-underscore-dangle: 0 */
      const componentId = target ? target.__typename : 'none';
      const colour = target && target.countdownFontColour
        ? target.countdownFontColour
        : null;
      const isIframe = target?.snippetCode && target?.snippetCode?.snippetCode.includes('iframe');

      switch (componentId) {
        case 'ContentfulHtml':
        case 'ContentfulHtmlCode':
          if (isIframe) {
            return <MarkupVideo markup={target.snippetCode.snippetCode} />;
          }
          return <MarkupText markup={target.snippetCode.snippetCode} />;
        case 'ContentfulEmailSignUp':
          return (
            <EmailSignUp
              title={target.title}
              topCopy={renderRichText(target.topCopy, 'white', 'defaultPage')}
              successCopy={renderRichText(
                target.successCopy,
                'white',
                'defaultPage'
              )}
              privacyCopy={renderRichText(
                target.privacyCopy,
                'white',
                'defaultPage'
              )}
              backgroundColour={snakeCase(target.formColour)}
              buttonColour={snakeCase(target.buttonColour)}
              campaignCode={target.campaignCode}
              databaseTag={target.databaseTag}
            />
          );
        case 'ContentfulCountdown':
          return (
            <Countdown
              endDate={target.endDate}
              endMessage={renderRichText(
                target.endMessage,
                colour,
                'defaultPage'
              )}
              introMessage={renderRichText(
                target.introMessage,
                colour,
                'defaultPage'
              )}
              color={colour}
            />
          );
        case 'ContentfulAccordion':
          return (
            <>
              <Accordion
                title={(
                  <Text family="Anton" size="l" uppercase mobileColor={options.mobileTextColour}>
                    {target.title}
                  </Text>
                )}
              >
                {renderRichText(target.body, 'white', 'defaultPage')}
              </Accordion>
              <br />
            </>
          );
        case 'ContentfulEmbeddableVideo':
          return (
            <EmbeddableVideo data={target} />
          );
        default:
          return null;
      }
    }
  }
};
export default function renderBody(
  richText,
  color,
  type,
  mobileTextColour = null,
  buttonColour = null,
  mobileButtonColour = null
) {
  options.background = color;
  options.type = type;
  options.mobileTextColour = mobileTextColour;
  options.buttonColour = buttonColour;
  options.mobileButtonColour = mobileButtonColour;

  return renderRichText(richText, options);
}
