import styled, { css } from 'styled-components';
import { rotatePulse, rotatePulse2 } from './animations';

const HeadingFont = css`
  font-family: Anton;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const SwivelJiggle = css`
  &:hover, &:active, &:focus {
    animation: ${rotatePulse} 0.4s linear;
    transform: scale(1.05) rotate(1deg);
  }
`;

const SwivelJiggle2 = css`
  &:hover, &:active, &:focus {
    animation: ${rotatePulse2} 0.5s linear;
    transform: scale(1.16) rotate(5deg);
    > div > svg > path.background {
      fill: ${props => props.theme.color('grey_stone')};
    }
  }
`;

const InnerContainer = styled.div`
  z-index: 99;
  margin: 0 auto;
  width: 95%;
  max-width: 1300px;
  text-align: center;
`;

const MobileMessage = styled.h1`
  margin: 80px 0 150px;
  color: ${({ theme }) => theme.color('white')};
  text-transform: uppercase;
  @media (min-width: 850px) {
    display: none;
  }
`;

const App = styled.div`
  display: none;
  @media (min-width: 850px) {
    display: block;
  }
`;

const TornFooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: -6px;
`;

export {
  HeadingFont,
  SwivelJiggle,
  SwivelJiggle2,
  InnerContainer,
  MobileMessage,
  App,
  TornFooterWrapper
};
