import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SoundEffectsOnSvg from './images/SoundEffectsOnSvg';
import SoundEffectsOffSvg from './images/SoundEffectsOffSvg';
import HomeSvg from './images/HomeSvg';
import {
  StyledIconButton
} from './JokeGenShared.styles';

const StyledMiniNavbar = styled.div`
  width: 95%;
  margin: 1vh auto 0;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin: 2vh auto 0;
  }
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin: 3vh auto 0;
  }
  display: flex;
  justify-content: space-between;
`;

export default function MiniNavbar({
  step,
  setStep,
  soundOn,
  setSoundOn,
  iconColor,
  jokeIndex,
  setJokeIndex,
  numberOfJokes,
  stopAllSounds,
  playSounds,
  laughSound,
  setBackgroundSplash
}) {
  const handleClick = () => {
    if (soundOn === true) {
      stopAllSounds();
      setSoundOn(false);
    } else {
      setSoundOn(true);
      if (step === 2) {
        playSounds(true, 'step2');
      }
      if (step === 3) {
        playSounds(true, 'step3');
        laughSound();
      }
      if (step === 4) {
        playSounds(true, 'step4');
      }
    }
  };

  return (
    <StyledMiniNavbar>

      {/* Sound On / Off button */}
      <StyledIconButton
        onClick={handleClick}
        iconColor={iconColor}
        aria-label="Sound on off button"
        data-testid="sound-button"
      >
        {soundOn
          ? <SoundEffectsOnSvg />
          : <SoundEffectsOffSvg />}
      </StyledIconButton>

      {/* Home button */}
      <StyledIconButton
        onClick={() => {
          setStep(1);
          setJokeIndex(Math.ceil(jokeIndex / numberOfJokes) * numberOfJokes);
          setSoundOn(false);
          stopAllSounds();
          setBackgroundSplash(true);
        }}
        iconColor={iconColor}
        aria-label="Home button"
        data-testid="home-button"
      >
        <HomeSvg />
      </StyledIconButton>

    </StyledMiniNavbar>
  );
}

MiniNavbar.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  soundOn: PropTypes.bool.isRequired,
  setSoundOn: PropTypes.func.isRequired,
  iconColor: PropTypes.func.isRequired,
  jokeIndex: PropTypes.number.isRequired,
  setJokeIndex: PropTypes.func.isRequired,
  numberOfJokes: PropTypes.number.isRequired,
  stopAllSounds: PropTypes.func.isRequired,
  playSounds: PropTypes.func.isRequired,
  laughSound: PropTypes.func.isRequired,
  setBackgroundSplash: PropTypes.func.isRequired
};
