import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const StyledHeadingWrapper = styled.div`
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: Anton;
  font-weight: 500;
  text-transform: uppercase;
  margin: 3vh auto 0;
  --fontSize: 2.5rem;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    --fontSize: 3.5rem;
  }
  letter-spacing: calc(var(--fontSize) * 0.05);
  gap: calc(var(--fontSize) * 0.3);
  line-height: calc(var(--fontSize) * 0.8);
  color: ${props => props.headingColor};
`;

const StyledLetter1 = styled.span`
  font-size: calc(var(--fontSize) * 1.1);
  vertical-align: calc(var(--fontSize) * 0.09);
`;

const StyledLetter2 = styled.span`
  font-size: var(--fontSize);
`;

const StyledLetter3 = styled.span`
  font-size: var(--fontSize);
  vertical-align: calc(var(--fontSize) * 0.06);
`;

const StyledLetter4 = styled.span`
  font-size: var(--fontSize);
`;

/**
 * Creates fun looney tunes headings, random font-size / lineheight
 */
export default function MainHeading({
  headingText,
  headingColor,
  step
}) {
  const wordArray = headingText.split(' ');

  const finishedHeading = wordArray.map(word => (
    <div aria-hidden="true" key={uuidv4()}>
      {Array.from(word).map((letter, index) => {
        switch (index % 4) {
          case (0):
            return <StyledLetter1 key={uuidv4()} aria-hidden="true">{letter}</StyledLetter1>;
          case (1):
            return <StyledLetter2 key={uuidv4()} aria-hidden="true">{letter}</StyledLetter2>;
          case (2):
            return <StyledLetter3 key={uuidv4()} aria-hidden="true">{letter}</StyledLetter3>;
          default:
            return <StyledLetter4 key={uuidv4()} aria-hidden="true">{letter}</StyledLetter4>;
        }
      })}
    </div>
  ));

  return (
    <StyledHeadingWrapper
      aria-label={headingText}
      headingColor={headingColor}
      step={step}
    >
      {finishedHeading}
    </StyledHeadingWrapper>
  );
}

MainHeading.propTypes = {
  headingText: PropTypes.string.isRequired,
  headingColor: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired
};
