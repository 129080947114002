import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SquareLoader from 'react-spinners/SquareLoader';

import { Descriptor, Text, Chip } from '@comicrelief/component-library';

import renderRichText from '../RichText/RichText';
import {
  LabelGroup,
  Container,
  Header,
  Grid,
  Item,
  TotalText,
  ClearButton,
  SpinnerHolder
} from './DescriptorBlock.style';

const DescriptorBlock = ({ data }) => {
  const { edges: descriptors, group } = data;
  const newGroup = group.map((cats, index) => ({ id: index, ...cats, checked: false }));
  const [selected, setSelected] = useState([]);
  const [categories, setCategories] = useState(newGroup);
  const [loading, setLoading] = useState(false);

  const selectedCatList = categories.map((cat) => {
    if (cat.checked === true) {
      return cat.fieldValue;
    }
    return null;
  },
  setTimeout(() => {
    setLoading(false);
  }, 200)).filter(el => el != null);

  const handleToggle = (e) => {
    const result = categories.map((cat) => {
      const newCat = { ...cat };
      if (newCat.fieldValue === e.target.value) {
        newCat.checked = !newCat.checked;
      }
      return newCat;
    });

    setCategories(result);
    setLoading(true);
  };

  const resetSelection = () => {
    setCategories(newGroup);
    setLoading(true);
  };

  useEffect(() => {
    if (loading) setSelected(selectedCatList);
  }, [categories]);

  const filteredDescriptors = descriptors.filter((descriptor) => {
    const { category } = descriptor.node;
    if (selected.length === 0) {
      return descriptor;
    }
    return category.some(r => selected.includes(r));
  });

  return (
    <Container>
      <Header>
        <Text tag="h2" size="xl" family="Anton" uppercase>
          Theme
        </Text>
        <Text tag="p" size="m">
          Choose from the themes below to view the reports by category
        </Text>
        <LabelGroup>
          {
            categories.map(category => (
              <Chip
                key={category.id}
                category={category.fieldValue}
                handleToggle={handleToggle}
                checked={category.checked}
              />
            ))
          }
          {selected.length !== 0 && (
            <ClearButton onClick={resetSelection} type="button">
              <Text size="s">
                Clear selection
              </Text>
            </ClearButton>
          )}
        </LabelGroup>
      </Header>
      <TotalText>
        <Text tag="p" size="s" uppercase weight="bold">
          {`${filteredDescriptors.length} of ${descriptors.length} reports available`}
        </Text>
      </TotalText>
      {!loading ? (
        <Grid>
          {filteredDescriptors.map((descriptor) => {
            const {
              id, title, date, tags, category, description
            } = descriptor.node;

            return (
              <Item key={id}>
                <Descriptor
                  title={title}
                  date={date}
                  tags={tags}
                  categories={category}
                >
                  {renderRichText(description, 'white', 'descriptor')}
                </Descriptor>
              </Item>
            );
          })}
        </Grid>
      ) : (
        <SpinnerHolder>
          <SquareLoader color="#E1E2E3" />
        </SpinnerHolder>
      )}
    </Container>
  );
};

DescriptorBlock.propTypes = {
  data: PropTypes.shape({
    edges: PropTypes.shape([]),
    group: PropTypes.shape([])
  }).isRequired
};

export default DescriptorBlock;
