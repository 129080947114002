import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  zoomOutLogo
} from './animations';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${zoomOutLogo} 0.2s linear 1.8s;
`;

const StyledLogoWrapper = styled.div`
  margin-top: 40%;
  width: 60%;
`;

export default function SplashLogo() {
  return (
    <StyledWrapper>
      <StyledLogoWrapper>
        <StaticImage
          src="./images/rnd-logo.svg"
          alt="Red nose day logo"
          placeholder="none"
        />
      </StyledLogoWrapper>
    </StyledWrapper>
  );
}
