import * as yup from 'yup';

/**
*
* Implementation of RFC 5322 Email Regex
*
* https://www.regular-expressions.info/email.html
*/
// eslint-disable-next-line
const emailStandardRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

/**
 *
 * filterSpecialChars
 *
 * This is an extention of the email field validation that prevents certain special characters
 */
yup.addMethod(yup.string, 'filterSpecialChars', function filterSpecialChars(errorMessage) {
  return this.test(
    'email-regex-type',
    errorMessage,
    function testForSpecialChars(value) {
      const { path, createError } = this;

      if (typeof value !== 'string') {
        return true;
      }
      const invalidCharacters = /[$£%]/g;
      const result = value.match(invalidCharacters) === null;
      return result || createError({ path, message: errorMessage });
    }
  );
});

const emailValidation = yup
  .string()
  .required('Please enter your email address')
  .email('Please enter a valid email address')
  .max(100, 'Your email address must be between 1 and 100 characters long')
  .matches(emailStandardRegex, 'Please enter a valid email address')
  .filterSpecialChars('Please enter a valid email address'); // Not part of the RFC 5322 Standard, but still useful to filter these chars

export default emailValidation;
