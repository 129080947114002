import { useEffect } from 'react';

export default function useDisableBodyScroll(trigger) {
  useEffect(() => {
    if (trigger) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [trigger]);
}
