import React from 'react';
import styled from 'styled-components';
import {
  Text, Link, SingleMessage, spacing
} from '@comicrelief/component-library';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import image from '../../static/assets/rylan_crying.jpg';

const Wrapper = styled.article.attrs({
  'data-test': '404'
})`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    flex-direction: row;
  }
`;

const Heading = styled(Text)`
  line-height: 1.5;
`;

const Button = styled(Link)`
  padding: ${spacing('md')};
  width: 100%;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: 250px;
  }
`;

const default404 = () => (
  <Layout>
    <Wrapper>
      <SEO
        title="404 | Comic Relief"
        description="Well...This is awkward! We can&apos;t seem to find the page you&apos;re looking for."
        image={image}
        pathname="/404"
      />
      <SingleMessage
        backgroundColor="white"
        imageSet={image}
        image={image}
        imageAltText=""
        copyFirst={false}
      >
        <Heading tag="h1" size="xl" uppercase>
          Well...This is awkward!
        </Heading>
        <Text tag="p" size="m">
          We can&apos;t seem to find the page you&apos;re looking for.
        </Text>
        <Text tag="p" size="m">
          This might be because you&apos;ve accidentally typed the web address incorrectly or
          perhaps the page you&apos;re looking for has been removed, updated or deleted.
        </Text>
        <Button href="/" color="red" type="button" size="m">
          Back to homepage
        </Button>
      </SingleMessage>
    </Wrapper>
  </Layout>
);

export default default404;
