import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as BaseComponents from '@comicrelief/component-library';
import allowListed from '../../../node_modules/@comicrelief/component-library/src/utils/allowListed';

import LocalComponents from '..';

const Components = { ...BaseComponents, ...LocalComponents };

const {
  RichText
} = Components;

const ParagraphHTML = ({ data }) => {
  //  Rudimentary checks to ascertain how to process the script
  const isScript = data.snippetCode.snippetCode.includes('script');
  const scriptHasSrc = data.snippetCode.snippetCode.includes('src="');

  useEffect(() => {
    if (isScript) {
      // Create a new script we'll append to the DOM
      const script = document.createElement('script');
      script.type = 'application/javascript';
      let scriptApproved = true;
      if (scriptHasSrc) {
        // Grab the source from any 'embed' scripts, and ignore fussy
        // ESLint rule which would just make for messier code
        // eslint-disable-next-line prefer-destructuring
        const thisSrc = data.snippetCode.snippetCode.split('src="')[1].split('"')[0];
        script.src = thisSrc;
        //  Update our flag as to if the src domain is on the allowlist
        scriptApproved = allowListed(thisSrc);
      } else {
        // Else, it's a self-contained script, so
        // remove the tags to keep the raw code
        script.innerHTML = data.snippetCode.snippetCode.replace(/(<([^>]+)>)/ig, '');
        // Any additional codechecks *could* happen here,
        // updating the 'scriptApproved' flag accordingly
        // GREATCODE.js
      }
      // Append the script to the associated wrapper div, only if the conditions have been met
      if (scriptApproved) {
        document.getElementById(data.id).appendChild(script);
      }
    }
  }, []);

  return (
    <div id={data.id}>
      {/* Render any non-scripts embeds */}
      {!isScript
        && <RichText markup={data.snippetCode.snippetCode} />}
    </div>
  );
};

ParagraphHTML.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    snippetCode: PropTypes.shape({
      snippetCode: PropTypes.string
    })
  }).isRequired
};

export default ParagraphHTML;
