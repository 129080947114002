import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Wrapping a component with this HOC enables you to call the `useGoogleReCaptcha` hook inside the
//  component.
const withRecaptcha = Component => props => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
    <Component {...props} />
  </GoogleReCaptchaProvider>
);

export default withRecaptcha;
