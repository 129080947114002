import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
const SEO = ({
  title = null,
  description = null,
  image = null,
  pathname = null,
  article = false,
  children = () => {},
  excludeFromSearchEngineIndexing = false
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle, defaultDescription, siteUrl, defaultImage
        }
      }
    }) => {
      const currentPageTitle = title || defaultTitle;
      const url = `${siteUrl}/${pathname}`;

      const seo = {
        title: currentPageTitle,
        description: description || defaultDescription,
        image: image || defaultImage,
        url
      };

      /* Push current page to Datalayer */
      useEffect(() => {
        if (!window.dataLayer) {
          window.dataLayer = [];
        }
        window.dataLayer.push({
          currentPageTitle,
          url
        });
      }, [currentPageTitle, url]);

      return (
        <>
          <Helmet title={seo.title}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={`https:${seo.image}`} />}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@comicrelief" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={`https:${seo.image}`} />}
            { excludeFromSearchEngineIndexing && <meta name="robots" content="noindex, nofollow" /> }
            {children({ siteUrl, seo })}
          </Helmet>
        </>
      );
    }}
  />
);

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  children: PropTypes.func,
  excludeFromSearchEngineIndexing: PropTypes.bool
};
