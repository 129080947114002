import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  z-index: 1;
  background: green;
`;

const SvgCircles = styled.div`
  background: #F05149;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export default function CirclesBackground({ children }) {
  // the value 21 sets the size of the circles
  return (
    <Container>
      {children}
      <SvgCircles>
        <svg width="100%" height="100%">
          <defs>
            <pattern id="circles-bg" x="-6.3" y="-3.15" width="21" height="21" viewBox="0 0 21 21" patternUnits="userSpaceOnUse">
              <circle fill="#EE343D" cx="8.4" cy="8.4" r="8.4" />
            </pattern>
          </defs>
          <rect x="0" y="0" width="100%" height="100%" fill="url(#circles-bg)" />
        </svg>
      </SvgCircles>
    </Container>
  );
}

CirclesBackground.propTypes = {
  children: PropTypes.node.isRequired
};
