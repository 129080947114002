import React from 'react';
import PropTypes from 'prop-types';
import { ErrorText } from '@comicrelief/component-library';

// An Error Boundary that will also render an error message based on the showError prop.
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasErrorInChild: false };
  }

  static getDerivedStateFromError() {
    return { hasErrorInChild: true };
  }

  /* eslint-disable react/destructuring-assignment */
  render() {
    if (this.state.hasErrorInChild || this.props.showError) {
      return this.props.errorMessage || (
        <ErrorText weight="bold">
          Sorry, something went wrong. Please refresh the page and try again.
        </ErrorText>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  showError: PropTypes.bool,
  errorMessage: PropTypes.node,
  children: PropTypes.node.isRequired
};

// Leaving in place for now as we can't set the defaults in the constructor (as it results in a
// "When calling super() in `ErrorBoundary`, make sure to pass up the same props that your
// component's constructor was passed." warning).
// Plus there's no 'getDerivedStateFromError' equivalent we can use in a nice,
// way more flexible functional component:
//
// https://legacy.reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
ErrorBoundary.defaultProps = {
  showError: false,
  errorMessage: ''
};

export default ErrorBoundary;
