import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Donate as DonationComponent } from '@comicrelief/component-library';
import { useMediaQuery } from 'react-responsive';
import { snakeCase } from 'lodash';
import getDonateWidgetMobileTheme from '../../utils/getDonateWidgetMobileTheme';

const dynamicCartsURL = 'https://donation.comicrelief.com/s/carts.json';

const Donate = ({
  donate = {
    cartId: 'default-comicrelief-embedded',
    clientId: 'donate',
    title: 'Donate Now',
    formAlignRight: true,
    subtitle: 'Please help us fund life-changing projects in the UK and around the world',
    id: 'article',
    backgroundColour: 'grey_dark'
  },
  moneyBuys = []
}) => {
  const [dynamicCart, setDynamicCart] = useState(null);
  const [dynamicCartLoaded, setDynamicCartLoaded] = useState(false);
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
  const mobileTheme = getDonateWidgetMobileTheme(donate.mobileTheme);
  useEffect(() => {
    if (donate.shortCartURL) {
      // get data from GitHub api
      fetch(dynamicCartsURL)
        .then(response => response.json())
        .then((resultData) => {
          if (typeof resultData[donate.shortCartURL] !== 'undefined') {
            setDynamicCart(resultData[donate.shortCartURL]);
          }

          setDynamicCartLoaded(true);
        })
        .catch((error) => {
          Sentry.captureException(error);
          setDynamicCartLoaded(true);
        });
    }
  }, []);

  let moneyBuy = donate.cartId && moneyBuys.filter(
    (e) => {
      if (dynamicCart) {
        return e.node.contentful_id === dynamicCart;
      }

      return e.node.cartId === donate.cartId;
    }
  );

  if (moneyBuy.length === 0) {
    moneyBuy = donate.cartId && moneyBuys.filter(
      e => e.node.cartId === donate.cartId
    );
  }

  const hasMoneyBuy = moneyBuy.length > 0;

  let imageLow;
  let images;
  let mobileImageLow;
  let mobileImages;
  let mobileAlt;

  if (donate.image) {
    imageLow = donate.image?.placeHolder?.src;
    images = donate.image?.fluid?.srcSet;
  } else {
    imageLow = moneyBuy[0]?.node?.singleGiving?.mobileImage?.placeHolder?.src;
    images = moneyBuy[0]?.node?.singleGiving?.mobileImage?.fluid?.srcSet;
  }

  const alt = donate.image && donate.image.description;

  if (donate.mobileAndTabletImage) {
    mobileImageLow = donate.mobileAndTabletImage?.placeHolder?.src;
    mobileImages = donate.mobileAndTabletImage?.fluid?.srcSet;
    mobileAlt = donate.image && donate.image.description;
  }

  // Most cases the desktop text colour will be white,
  // but there is an option in the CMS to set it to black.
  // Most mobile themes also use white text, but "Grey Light" uses black.
  // Also defaults to white, if no colour has been set.
  const desktopTitleTextColour = donate.desktopTitleTextColour ? donate.desktopTitleTextColour : 'white';
  const textColour = snakeCase(isDesktop ? desktopTitleTextColour : mobileTheme.textColour);

  // Temporary logic to handle the case where backgroundColour is still used,
  // but desktopOverlayColour is not
  const desktopOverlayColour = (!donate.desktopOverlayColour && donate.backgroundColour)
    ? donate.backgroundColour : donate.desktopOverlayColour;

  const paddingOption = donate.paddingOptions ? snakeCase(donate.paddingOptions) : null;

  return (
    hasMoneyBuy && (!donate.shortCartURL || (donate.shortCartURL && dynamicCartLoaded)) && (
      <DonationComponent
        key={`${moneyBuy[0].node}-dynamicCart`}
        data={moneyBuy[0].node}
        cartID={moneyBuy[0].node.cartId}
        clientID={donate.clientId}
        desktopOverlayColor={snakeCase(desktopOverlayColour)}
        mobileBackgroundColor={mobileTheme.backgroundColour}
        submitButtonColor={mobileTheme.buttonColour}
        textColor={textColour}
        formAlignRight={donate.formAlignRight}
        imageLow={imageLow}
        images={images}
        mobileImageLow={mobileImageLow}
        mobileImages={mobileImages}
        mobileAlt={mobileAlt}
        noMoneyBuys={donate.noMoneyBuys}
        alt={alt}
        title={donate.title}
        subtitle={donate.subtitle}
        chooseAmountText={donate.chooseAmountText}
        PopUpText={donate.popUpText === null ? undefined : donate.popUpText}
        donateLink={donate.donationUrl}
        mbshipID={`mbship-${donate.id}`}
        isDesktopOverride={isDesktop}
        otherDescription="will fund organisations helping people through the toughest times of their lives"
        otherAmountText={donate.otherAmountText || undefined}
        additionalSingleCopy={donate.additionalSingleCopy}
        additionalMonthlyCopy={donate.additionalMonthlyCopy}
        monthlyTitle={donate.monthlyOnlyTitle}
        monthlySubtitle={donate.monthlyOnlySubtitle}
        monthlyChooseAmountText={donate.monthlyOnlyChooseAmountText}
        monthlyOtherAmountText={donate.monthlyOnlyOtherAmountText}
        paddingOption={paddingOption}
      />
    )
  );
};

Donate.propTypes = {
  donate: PropTypes.objectOf(PropTypes.shape),
  moneyBuys: PropTypes.arrayOf(PropTypes.shape)
};

export default Donate;
