import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import shuffle from '../../utils/shuffleArray';
import TornFooter from './TornFooter';
import CirclesBackground from './CirclesBackground';
import Step1 from './step1/Step1';
import Step2and3 from './step2and3/Step2and3';
import PopUpWindow from './PopUpWindow';
import {
  InnerContainer,
  MobileMessage,
  App,
  TornFooterWrapper
} from './sharedStyles';

export default function BigRedButtonChallenge({ data }) {
  const [step, setStep] = useState(1);
  const [showPopUp, setShowPopUp] = useState(false);
  const [challengesData, setChallengesData] = useState([]);
  // this object will be further filled with input data
  const [userDetails, setUserDetails] = useState({ schoolName: '' });

  /**
   * Shuffle challenges array as comes in
   */
  useEffect(() => {
    if (data) {
      setChallengesData(shuffle(data.challenges));
    }
  }, []);

  // Function to check if the browser is available
  if (typeof window !== 'undefined') {
    useEffect(() => {
      // Extracting parameters from the location object
      const urlParams = new URLSearchParams(window.location.search);

      // assigning parameters
      const schoolName = urlParams.get('schoolName');

      // Storing the values in state
      setUserDetails({
        schoolName
      });
    }, []);
  }

  return (
    <>
      <CirclesBackground>

        <InnerContainer>
          {/* message when viewed on mobile */}
          <MobileMessage>Please use this App on a desktop computer...</MobileMessage>

          {/* main App */}
          <App>
            {(step === 1)
              && (
                <Step1
                  setShowPopUp={setShowPopUp}
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                />
              )}

            {(step === 2 || step === 3)
              && (
                <Step2and3
                  step={step}
                  setStep={setStep}
                  setShowPopUp={setShowPopUp}
                  challengesData={challengesData}
                  finalMessage={data.finalMessage}
                />
              )}

            {showPopUp
              && (
              <PopUpWindow
                setShowPopUp={setShowPopUp}
                step={step}
                setStep={setStep}
              />
              )}

          </App>
        </InnerContainer>

        <TornFooterWrapper>
          <TornFooter />
        </TornFooterWrapper>

      </CirclesBackground>
    </>
  );
}

BigRedButtonChallenge.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    challenges: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        video: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired
          }).isRequired
        }).isRequired
      })
    ).isRequired,
    finalMessage: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        video: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired
          }).isRequired
        }).isRequired
      })
    ).isRequired
  }).isRequired
};
