function kebabify(str) {
  return str
    .split('')
    .map((letter, idx) => (letter.toUpperCase() === letter
      ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
      : letter))
    .join('');
}

export function selectLabelById(id) {
  return `label[for=${kebabify(id)}]`;
}

export function selectId(id) {
  return `#${kebabify(id)}`;
}
