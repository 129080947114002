import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Text, PartnerLink, Picture, spacing
} from '@comicrelief/component-library';

const Container = styled.div`
  text-align: center;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.color('grey_light')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('xl')} 0;
    width: 100%;
  }
`;

const Content = styled.div.attrs({
  'data-test': 'partner-category-list'
})`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
`;

const Category = styled.div.attrs({
  'data-test': 'partner-category'
})`
  padding: ${spacing('l')} 0;
`;

const List = styled.ul`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: calc(100% - 2 * ${spacing('l')});
  }
`;

const ListItem = styled.li`
  width: calc(50% - 2 * ${spacing('md')});
  margin: 0 auto;
  padding: ${spacing('md')} 0;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('md')} ${spacing('xsm')};
    width: calc(25% - 2 * ${spacing('md')});
    margin: 0 ${spacing('md')};
  }
`;

const Partners = ({ partnersList }) => {
  const uniqueCategoriesList = Array.from(new Set(partnersList
    .map(({ node: { group } }) => group && group.title)));

  return (
    <Container>
      <Content>
        {uniqueCategoriesList.map(groupTitle => (
          groupTitle && (
          <Category key={groupTitle}>
            <Text tag="h2" size="xxl" uppercase>
              {groupTitle}
            </Text>
            <List>
              {partnersList.map((partner) => {
                const {
                  id, path, name, logo, group
                } = partner.node;

                const fallback = logo?.fallback?.src;
                const images = logo?.fluid?.srcSet;
                const lowImage = logo?.placeHolder?.src;
                const isGroupEmpty = group && group.title;

                if (isGroupEmpty && groupTitle === group.title) {
                  return (
                    <ListItem key={id}>
                      <PartnerLink link={`/partners/${path}`}>
                        <Picture
                          alt={name}
                          image={fallback}
                          imageLow={lowImage}
                          images={images}
                          objectFit="cover"
                        />
                      </PartnerLink>
                    </ListItem>
                  );
                }
                return null;
              })}
            </List>
          </Category>
          )
        ))}
      </Content>
    </Container>
  );
};

Partners.propTypes = {
  partnersList: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        logo: PropTypes.shape({
          fluid: PropTypes.shape({
            srcSet: PropTypes.string.isRequired
          }).isRequired,
          placeHolder: PropTypes.shape({
            src: PropTypes.string.isRequired
          }).isRequired
        }).isRequired
      }).isRequired
    })
  ).isRequired
};

export default Partners;
