import styled from 'styled-components';
import {
  Text, hideVisually, spacing
} from '@comicrelief/component-library';

const OptionCardWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

const OptionCardLabel = styled.label`
  position: relative;
  display: block;
  padding-bottom: 100%;
  min-height: 190px;
`;

const OptionCardInput = styled.input`
  ${hideVisually};

  // Without this, the browser will jump the scroll position whenever one of these options is
  //  selected at a time when its top pixel is off the top of the screen, which is quite jarring.
  // Moving the hidden input halfway down means the browser will only jump upwards or downwards
  //  if more than half of the card is off the screen.
  top: 50%;
`;

const OptionCardContent = styled.div`${({ theme, withShadow, whiteBackground }) => `
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  background-color: ${theme.color(whiteBackground ? 'white' : 'grey_light')};
  color: ${theme.color('grey_label')};
  ${withShadow
    ? 'box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);'
    : `border: 1px solid ${theme.color('grey')};`}

  input:not(:focus):not(:checked) + &:hover {
    color: ${theme.color('grey_dark')};
    outline: 1px solid ${theme.color('grey_dark')};
  }

  input:focus + & {
    outline: 2px solid ${theme.color('blue_royal')};
  }

  input:checked + & {
    color: ${theme.color('blue')};
    outline: 2px solid ${theme.color('blue_royal')};
  }

  input:disabled + & {
    opacity: 0.3;
  }
`}`;

const OptionCardContentTop = styled.div`
  height: 73%;
  overflow: hidden;
`;

const OptionCardImage = styled.div`${({ image }) => `
  height: 100%;
  background: url(${image}) no-repeat top;
  background-size: cover;
`}`;

const OptionCardText = styled(Text)`${({ theme }) => `
  height: 27%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${spacing('sm')};
  font-size: ${theme.fontSize('xs')};

  @media ${theme.allBreakpoints('M')} {
    padding: ${spacing('m')} ${spacing('md')};
    font-size: .875rem;
  }

  @media ${theme.allBreakpoints('L')} {
    font-size: ${theme.fontSize('s')};
  }
`}`;

const OptionCardIcon = styled.div`${({ theme }) => `
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${spacing('sm')};
  right: ${spacing('sm')};
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  border: 1px solid ${theme.color('blue')};

  &::after {
    content: '';
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    background-color: ${theme.color('blue')};
  }

  input:checked + ${OptionCardContent} & {
    display: flex;
  }

  input[type=radio]:checked + ${OptionCardContent} &,
  input[type=radio]:checked + ${OptionCardContent} &::after {
    border-radius: 50%;
  }

  input[type=checkbox]:checked + ${OptionCardContent} & {
    border-radius: 0.25rem;

    &::after {
      content: "\\2714";
      color: ${theme.color('blue')};
      background-color: white;
      width: 100%;
      height: 1rem;
      font-size: ${theme.fontSize('s')};
      line-height: 1.125;
      text-align: center;
    }
  }
`}`;

export {
  OptionCardWrapper,
  OptionCardLabel,
  OptionCardInput,
  OptionCardContent,
  OptionCardContentTop,
  OptionCardImage,
  OptionCardText,
  OptionCardIcon
};
