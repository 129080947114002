import React from 'react';
import PropTypes from 'prop-types';
import Cross from './Cross';
import {
  BackgroundSplash,
  Container,
  ContainerBorder,
  Inner,
  CrossButton,
  Heading,
  Message,
  OnwardButton,
  ButtonBorder
} from './popUpWindowStyles';

export default function PopUpWindow({
  setShowPopUp,
  step,
  setStep
}) {
  const handleCrossClick = () => {
    setShowPopUp(false);
  };

  const handleOnwardClick = () => {
    if (step === 1) {
      window.dataLayer.push({
        event: 'brb_popup_start'
      });
    }
    if (step === 2) {
      window.dataLayer.push({
        event: 'brb_popup_end'
      });
    }
    setStep(step + 1);
    setShowPopUp(false);
  };

  return (
    <BackgroundSplash>
      <Container>
        <ContainerBorder>

          <CrossButton onClick={handleCrossClick}>
            <Cross />
          </CrossButton>

          <Inner>
            <Heading>
              {step === 1
                ? 'Welcome to the big red button of rebellion'
                : "Well done, You've watched all of the challenges!"}
            </Heading>
            <Message>
              {step === 1
                ? "When you have completed your challenges and are finished, click 'End' for a bonus surprise!"
                : 'End the Rebellion for a surprise bonus video, or close this message to continue from where you were.'}
            </Message>
            <OnwardButton onClick={handleOnwardClick}>
              <ButtonBorder>
                {step === 1
                  ? 'Start the Rebellion!'
                  : 'End'}
              </ButtonBorder>
            </OnwardButton>
          </Inner>

        </ContainerBorder>
      </Container>
    </BackgroundSplash>
  );
}

PopUpWindow.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  setShowPopUp: PropTypes.func.isRequired
};
