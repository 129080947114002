import React from 'react';
import { kebabCase } from 'lodash';
import { ThemeConsumer } from 'styled-components';
import { Text } from '@comicrelief/component-library';
import { changeTextColor } from './utils';

const heading = (node, children, options, headingTag, size, height) => {
  if (children[0] !== '') {
    const thisTextColour = changeTextColor(options.background);
    const thisMobileTextColour = options.mobileTextColour;

    return (
      <ThemeConsumer>
        {theme => (
          <Text
            tag={headingTag}
            id={`${kebabCase(node.content[0].value)}-anchor`}
            color={thisTextColour}
            size={size}
            height={height}
            uppercase
            family={theme.font.bold}
            weight="normal"
            mobileColor={thisMobileTextColour}
          >
            {children}
          </Text>
        )}
      </ThemeConsumer>
    );
  }
  return null;
};

export default heading;
