import React from 'react';
import styled from 'styled-components';
import { spacing, Text } from '@comicrelief/component-library';

import ButtonAsLink from '../../../components/ButtonAsLink/ButtonAsLink';
import chevronSvg from '../assets/CR_Chevron_L.svg';

const StyledButton = styled(ButtonAsLink)`
  margin-bottom: ${spacing('lg')};
  color: ${({ theme }) => theme.color('black')};
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const BackButton = props => (
  <StyledButton type="button" {...props}>
    <img
      src={chevronSvg}
      alt="Back"
      css={{
        marginRight: spacing('sm'),
        // height: '1.5rem'
        height: '2.5rem'
      }}
    />
    <Text css={{ textDecoration: 'underline', fontSize: '20px' }}>
      Back
    </Text>
  </StyledButton>
);

export default BackButton;
