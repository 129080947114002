export const formatItems = (data) => {
  const theseFormattedItems = [];

  theseFormattedItems.push({
    poundsPerItem: data.moneybuy1PriceDecimal,
    description: data.moneybuy1Description,
    imageURL: data.moneybuy1Image.file.url
  });

  theseFormattedItems.push({
    poundsPerItem: data.moneybuy2PriceDecimal,
    description: data.moneybuy2Description,
    imageURL: data.moneybuy2Image.file.url
  });

  theseFormattedItems.push({
    poundsPerItem: data.moneybuy3PriceDecimal,
    description: data.moneybuy3Description,
    imageURL: data.moneybuy3Image.file.url
  });

  theseFormattedItems.push({
    poundsPerItem: data.moneybuy4PriceDecimal,
    description: data.moneybuy4Description,
    imageURL: data.moneybuy4Image.file.url
  });

  return theseFormattedItems;
};

export const handleScroll = (urlParams) => {
  if (urlParams.size > 0) {
    const thisRowId = urlParams.get('rowID');
    // Timeout to make the scroll less jarring
    setTimeout(() => {
      const element = document.getElementById(thisRowId);
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, 500);
  }
};

export const handleUrlAmount = (urlParams) => {
  if (urlParams.size > 0) {
    const thisAmount = urlParams.get('amount');
    return parseInt(thisAmount, 10);
  }

  return null;
};

export default { formatItems, handleUrlAmount };
