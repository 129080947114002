import { keyframes } from 'styled-components';

/**
 * FACES *****************************
 */
const faceZoom = keyframes`
  0%   { transform: scale3d(1, 1, 1) }
  30%   { transform: scale3d(1.15, 1.15, 1.15) }
  60%   { transform: scale3d(1, 1, 1) }
  75%   { transform: scale3d(1.05, 1.05, 1.05) }
  100%   { transform: scale3d(1, 1, 1) }
`;

const faceJiggle = keyframes`
  0%   { transform: rotate(0deg) }
  10%   { transform: rotate(-20deg) }
  20%   { transform: rotate(20deg) }
  30%   { transform: rotate(-10deg) }
  50%   { transform: rotate(10deg) }
  60%   { transform: rotate(-5deg) }
  70%   { transform: rotate(5deg) }
  80%   { transform: rotate(-3deg) }
  90%   { transform: rotate(3deg) }
  100%   { transform: rotate(0deg) }
`;

const facePendulum = keyframes`
  0%   { transform: rotate(0deg) }
  40%   { transform: rotate(-60deg) }
  50%   { transform: rotate(35deg) }
  60%   { transform: rotate(-25deg) }
  70%   { transform: rotate(10deg) }
  80%   { transform: rotate(-5deg) }
  90%   { transform: rotate(3deg) }
  100%   { transform: rotate(0deg) }
`;

const faceTwirl = keyframes`
  0% {transform: rotate(0deg)}
  80% {transform: rotate(360deg)}
  100% {transform: rotate(0deg)}
`;

const faceLoopTheLoop = keyframes`
  0%   { transform: rotate(0deg) }
  50%   { transform: rotate(390deg) }
  100%   { transform: rotate(0deg) }
`;

const soundButtonSwell = keyframes`
  0%   { transform: scale(1, 1) }
  10%   { transform: scale(1.1, 1.1) }
  20%   { transform: scale(1, 1) }
  30%   { transform: scale(1.1, 1.1) }
  40%   { transform: scale(1, 1) }
  50%   { transform: scale(1.1, 1.1) }
  60%   { transform: scale(1, 1) }
`;

const faceBounceEntry = keyframes`
  0% { transform: translateY(-100%) }
  20% { transform: translateY(0%) }
  40% { transform: translateY(-20%) }
  60% { transform: translateY(-0%) }
  80% { transform: translateY(-5%) }
  100% { transform: translateY(0%) }
`;

/**
 * OTHER
 */
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

const bounceInRight = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  50% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0) scaleX(0.95);
  }

  90% {
    transform: translate3d(-5px, 0, 0) scaleX(0.9);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const zoomOutLogo = keyframes`
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale3d(7, 7, 7) translateY(-10%);
  }
`;

const backgroundUnfold = keyframes`
  0%   {
    background-position: left bottom;
  }
  100%   {
    background-position: right top;
  }
`;

export {
  faceZoom,
  faceJiggle,
  facePendulum,
  faceTwirl,
  faceLoopTheLoop,
  fadeIn,
  faceBounceEntry,
  bounceInRight,
  soundButtonSwell,
  zoomOutLogo,
  backgroundUnfold
};
