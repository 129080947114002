import styled from 'styled-components';

const OuterWrapper = styled.div`
  background-color: ${({ bgColour, theme }) => theme.color(bgColour)};
  position: relative;
  width: 100%;
  // Results, more or less, in a 4:3 aspect ratio
  height: calc(114vw + 2rem);
  overflow: hidden;
  padding: 1rem;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    max-height: 1000px;
    padding: 3rem 1.5rem;
    height: calc(6rem + 70vh);
  }
`;

const StatusContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border: 2px solid rgba(230, 230, 230);
  border-radius: 10px;
  max-width: calc(1200px - 3rem);
  background-color: white;

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    // width: 90vw;
  }
`;

const MapLogoWrapper = styled.div`
  width: 40px;
  height: 40px;
  z-index: 100;
  display: block;
  position: absolute;
  left: 5px;
  bottom: 105px;

  a {
    width: 40px;
    height: 40px;
    display: block;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const colourRange = [
  [255, 0, 0, 40],
  [255, 0, 0, 70],
  [255, 0, 0, 100],
  [255, 0, 0, 120],
  [255, 0, 0, 140],
  [255, 0, 0, 160],
  [255, 0, 0, 180],
  [255, 0, 0, 200],
  [255, 0, 0, 230],
  [255, 0, 0, 255]
];

export {
  OuterWrapper, MapWrapper, MapLogoWrapper, colourRange, StatusContainer
};
