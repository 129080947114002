import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  faceJiggle, facePendulum, faceTwirl, faceLoopTheLoop
} from './animations';
import {
  StyledFaceWrapper
} from './JokeGenShared.styles';

const StyledFaceWrapper1 = styled(StyledFaceWrapper)`
  animation: ${faceJiggle} 1.2s linear infinite;
`;

const StyledFaceWrapper2 = styled(StyledFaceWrapper)`
  animation: ${facePendulum} 2.5s ease-in-out infinite;
`;

const StyledFaceWrapper3 = styled(StyledFaceWrapper)`
  animation: ${faceTwirl} 2.4s ease-in-out infinite;
`;

const StyledFaceWrapper4 = styled(StyledFaceWrapper)`
  animation: ${faceLoopTheLoop} 3.4s ease-in-out infinite 1s;
`;

const renderNose = (noseChoice) => {
  switch (noseChoice) {
    case 1:
      return (
        <>
          <StyledFaceWrapper1>
            <StaticImage
              src="./images/nose-1.png"
              alt="Red nose 1"
              placeholder="none"
            />
          </StyledFaceWrapper1>
        </>
      );
    case 2:
      return (
        <StyledFaceWrapper2>
          <StaticImage
            src="./images/nose-2-blue.png"
            alt="Red nose 2"
            placeholder="none"
          />
        </StyledFaceWrapper2>
      );
    case 3:
      return (
        <StyledFaceWrapper3>
          <StaticImage
            src="./images/nose-3.png"
            alt="Red nose 3"
            placeholder="none"
          />
        </StyledFaceWrapper3>
      );
    default:
      return (
        <StyledFaceWrapper4>
          <StaticImage
            src="./images/nose-4.png"
            alt="Red nose 4"
            placeholder="none"
          />
        </StyledFaceWrapper4>
      );
  }
};

export default renderNose;
