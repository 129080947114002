import React from 'react';

import OptionCard from './OptionCard';

/**
 * This 'trigger' element is basically just an option card radio button with slightly different
 *  styling.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const OptionCardTrigger = React.forwardRef((props, ref) => (
  <OptionCard {...props} type="radio" withIcon={false} withShadow whiteBackground ref={ref} />
));

export default OptionCardTrigger;
