import styled, { css } from 'styled-components';
import handlePadding from '@comicrelief/component-library/src/utils/_utils';
import {
  Text,
  hideVisually,
  containers
} from '@comicrelief/component-library';

const Wrapper = styled.div`
  background: ${({ theme, bgColour }) => theme.color(bgColour)};
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: 3rem 1rem;
  }

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    // We only want to apply this padding tweak to ROW layouts, although it looks like someone got the
    // logic backwards when this work was done, so having to test for the opposite:
    ${({ paddingOption, columnsOnMd }) => columnsOnMd === true && handlePadding(paddingOption)};
  }

  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    // LG breakpoint is always rows, so we can add this without an additional check:
    ${({ paddingOption }) => handlePadding(paddingOption)};
  }
`;

const Grid = styled.div`
  display: flex;
  width: 100%;
  max-width: ${containers.medium};
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    flex-direction: row;
  }
  ${({ columnsOnMd }) => columnsOnMd
    && css`
      @media ${({ theme }) => theme.allBreakpoints('L')} {
        flex-direction: row;
      }
    `}
`;

const Items = styled.div`
  @media (min-width: 390px) {
    display: ${({ isCarousel }) => (isCarousel ? 'none' : 'block')};
  }
  @media (min-width: 1023px) {
    display: block;
  }

  margin: 0 0 4rem;
  width: 100%;
  text-align: left;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% / 2 - 3rem);

  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    margin: 0 1.5rem 5rem;
    flex-basis: ${({ layout }) => {
    switch (layout) {
      case '3 columns':
        return 'calc(100% / 3 - 3rem); flex-grow: 0';
      default:
        return null;
    }
  }};
  }

  /* styles responding to a radio button that chooses column layout on MD breakpoint */
  ${({ columnsOnMd }) => columnsOnMd && css`
    @media ${({ theme }) => theme.allBreakpoints('L')} {
      margin: 0 1.5rem 5rem;
      flex-basis: ${({ layout }) => {
    switch (layout) {
      case '3 columns':
        return 'calc(100% / 3 - 3rem); flex-grow: 0';
      default:
        return null;
    }
  }};
        > div {
          flex-direction: column;
        }
        > div > *:first-child {
          width: 100%;
          height: auto;
          margin: 0 -1.5rem 0 1.5rem;
        }
        > div > a:first-child {
          @media ${({ theme }) => theme.allBreakpoints('XL')} {
            margin: 0;
          }
        }
        > div > div:nth-child(2) {
          @media ${({ theme }) => theme.allBreakpoints('L')} {
            width: 100%;
            height: 100%;
            margin: calc(-2 * 1.5rem) 0 0 0;
          }
        }
        > div > div:nth-child(3) {
          @media ${({ theme }) => theme.allBreakpoints('L')} {
            bottom: -2.2rem;
          }
        }
      }
    `}

    /* fix for image layout bug when there is no link/CTA present */
    > div > div:first-child {
      @media ${({ theme }) => theme.allBreakpoints('XL')} {
        width: 100%;
      }
    }
`;

const HideText = styled(Text)`
  ${hideVisually};
`;

export {
  Wrapper,
  Grid,
  Items,
  HideText
};
