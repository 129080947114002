import styled, { css } from 'styled-components';
import handlePadding from '@comicrelief/component-library/src/utils/_utils';
import {
  Text, spacing, hideVisually, containers
} from '@comicrelief/component-library';

const Wrapper = styled.div`
  background: ${({ theme, cardsBackground }) => theme.color(cardsBackground)};
  padding: ${spacing('md')};
  text-align: center;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('xl')} ${spacing('l')};
  }

  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    ${({ paddingOption }) => handlePadding(paddingOption)};
  }
`;

const Copy = styled.span`
  ${({ centred }) => (centred ? 'text-align: center' : null)}
`;

const BlurbWrapper = styled.div`
  * {
    text-align: ${props => (props.cardsCentredText ? 'center' : 'left')};
  }
  max-width: ${containers.small};
  margin: 0 auto ${spacing('l')};
  width: 100%;
`;

const Title = styled.div`
  margin-bottom: ${spacing('m')};
`;

const BodyText = styled.div`
  margin-bottom: ${spacing('m')};
`;

const Grid = styled.div`
  display: flex;
  width: 100%;
  max-width: ${containers.medium};
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    flex-direction: row;
    gap: ${spacing('md')};
  }
`;

const Items = styled.div`
  margin: 0 0 ${spacing('md')};
  width: 100%;
  text-align: left;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% / 2 - ${spacing('l')});

  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    flex-basis: ${({ layout }) => {
    switch (layout) {
      case '3 columns':
        return `calc(100% / 3 - ${spacing('l')}); flex-grow: 0`;
      case '4 columns':
        return `calc(100% / 4 - ${spacing('l')}); flex-grow: 0`;
      default:
        return null;
    }
  }}
  }
`;

const HideText = styled.span`
  ${({ hideText }) => (hideText === 'Yes') && hideVisually};
`;

const HideTextInner = styled(Text)`
  &:only-child {
    ${({ hideText }) => !hideText && `margin-bottom: ${spacing('l')} text-transform: uppercase`};
  }
`;

const Line = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.color('grey_medium')};
  display: none;
  margin: ${spacing('m')} ${spacing('sm')} ${spacing('sm')};
  ${({ smallBreakpointRowLayout }) => (smallBreakpointRowLayout
    === true) && css`
    @media ${({ theme }) => theme.allBreakpoints('S')} {
      display: block;
    }
  `}
  ${({ mediumBreakpointRowLayout }) => (mediumBreakpointRowLayout
    === true) && css`
    @media ${({ theme }) => theme.allBreakpoints('M')} {
      display: block;
    }
  `}
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    display: none;
  }
`;

export {
  Wrapper,
  Copy,
  BlurbWrapper,
  Title,
  BodyText,
  Grid,
  Items,
  HideText,
  HideTextInner,
  Line
};
