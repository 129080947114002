import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { snakeCase } from 'lodash';
import {
  Text, ArticleTeaser, spacing, hideVisually, containers
} from '@comicrelief/component-library';
import renderRichText from '../RichText/RichText';

const Wrapper = styled.div`
  background: ${({ theme, bgColour }) => theme.color(bgColour)};
  padding: ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('xl')} ${spacing('l')};
  }
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  max-width: ${containers.small};
  margin: 0 auto ${spacing('l')};
  text-align: center;
  width: 100%;
`;

const Grid = styled.div`
  display: flex;
  width: 100%;
  max-width: ${containers.medium};
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    flex-direction: row;
  }
`;

const Items = styled.div`
  margin: 0 0 ${spacing('md')};
  width: 100%;
  text-align: left;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% / 2 - ${spacing('l')});
  img {
    height: 272px;
  }
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    img {
      height: 424px;
    }
    margin: 0 ${spacing('md')} ${spacing('l')};
    ${({ layout }) => layout === 3 && css`
      flex-basis: calc(100% / 3 - ${spacing('l')});
      flex-grow: 0;
      img {
        height: 272px;
      }
    `};
  }
`;

const HideText = styled.span`
  ${({ hideText }) => hideText && hideVisually};
`;

const HideTextInner = styled(Text)`
  &:only-child {
    ${({ hideText }) => !hideText && `margin-bottom: ${spacing('l')}`};
  }
`;

const Teasers = ({ data }) => {
  const {
    title, body, cards, hideTitle, backgroundColour: bgCards
  } = data;

  const teasersBackground = snakeCase(bgCards || 'white');
  const teasersBody = body && body.raw;
  const changeColor = background => (background && (background.includes('dark') || background.includes('red') || background.includes('deep_violet') || background.includes('black')) ? 'white' : 'black');

  return (
    <Wrapper bgColour={teasersBackground}>
      {title && (
        <HideText hideText={hideTitle}>
          <HideTextInner tag="h2" size="xxl" color={changeColor(teasersBackground)} family="Anton" weight="normal">
            {title}
          </HideTextInner>
        </HideText>
      )}
      { teasersBody ? (
        <Body>{teasersBody && renderRichText(body, teasersBackground, 'cards')}</Body>
      ) : null}
      <Grid>
        {cards
          && cards.map((card) => {
            const {
              title: titleCard, id, image, link
            } = card;
            const fallback = image?.fallback?.src;
            const imageLow = image?.placeHolder?.src;
            const images = image?.fluid?.srcSet;
            const description = image?.description;

            return (
              <Items key={id} layout={cards.length}>
                <ArticleTeaser
                  href={link}
                  imageLow={imageLow}
                  image={fallback}
                  images={images}
                  imageAltText={description}
                  title={titleCard}
                />
              </Items>
            );
          })}
      </Grid>
    </Wrapper>
  );
};

Teasers.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.shape({
      raw: PropTypes.shape({})
    }),
    cards: PropTypes.shape([]).isRequired,
    hideTitle: PropTypes.bool,
    backgroundColour: PropTypes.string
  }).isRequired
};

export default Teasers;
