import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeConsumer } from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import {
  Header as HeaderCL,
  Link
} from '@comicrelief/component-library';

import searchIcon from '@comicrelief/component-library/src/components/Organisms/Header/assets/icon--search--2023.svg';
import shopIcon from '@comicrelief/component-library/src/components/Organisms/Header/assets/icon--shop--2023.svg';
import payinIcon from '@comicrelief/component-library/src/components/Organisms/Header/assets/PayIn.svg';

import HeaderESU from '../HeaderESU/HeaderESU';

const FixedHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // To out-do any Shorthand story embeds
  z-index: 1000;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: all 200ms ${props => (props.show ? 'ease-in' : 'ease-out')};
  transform: ${props => (props.show ? 'none' : 'translate(0, -100%)')};
  font-family: ${({ theme }) => theme.fontFamilies(theme.font.regular)};
  font-family: ${({ theme }) => (theme.font.semiBold
    ? theme.fontFamilies(theme.font.semiBold)
    : theme.fontFamilies(theme.font.regular))};
`;

const NavIcon = styled.a`
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: 5px;
  margin-left: 0.5rem;
  img {
    width: 25px;
    height: 25px;
  }
`;

const metaIconLinks = (
  removeDonateLink,
  options,
  setBlockHide,
  landingPageCartId
) => (
  <>
    {!removeDonateLink && (
      <Link
        color="green"
        type="button"
        rel="noopener"
        href={landingPageCartId ? `https://donation.comicrelief.com?cartId=${landingPageCartId}` : 'https://donation.comicrelief.com/s/website'}
        target="blank"
        key="1"
        className="header-donate-button"
      >
        Donate
      </Link>
    )}

    {options.showEsu
     && (<HeaderESU setBlockHide={setBlockHide} />)}

    {options.showShop && (
      <NavIcon
        title="Shop"
        target="_blank"
        data-test="header-shop-icon"
        href={options.shopUrl || 'https://shop.comicrelief.com/'}
      >
        <img src={shopIcon} alt="Search" />
      </NavIcon>
    )}

    {options.showPayin && (
    <NavIcon
      title="Pay in your fundraising money"
      target="_blank"
      data-test="header-payin-icon"
      href={options.payinUrl || 'https://donation.comicrelief.com/?cartId=rnd23-fundraisingpayin'}
    >
      <img src={payinIcon} alt="Pay in your fundraising money" />
    </NavIcon>
    )}

    {options.showSearch && (
    <NavIcon
      title="Search"
      target="_self"
      data-test="header-esu-icon"
      href={options.searchUrl || '/search'}
    >
      <img src={searchIcon} alt="Search" />
    </NavIcon>
    )}

  </>
);

const HeaderNoData = ({
  removeDonateLink = false,
  campaign = 'comicrelief',
  menu,
  adminConfig = [
    {
      title: true,
      control: true,
      url: null
    }
  ],
  landingPageCartId = null
}) => {
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [blockHide, setBlockHide] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;

      if (isShow !== hideOnScroll && currPos.y < 0 && !blockHide) {
        setHideOnScroll(isShow);
      }
    },
    [hideOnScroll, blockHide],
    false,
    false,
    0
  );

  // Config defaults
  const options = {
    showEsu: false,
    showSearch: true,
    showShop: false,
    showPayin: false,
    payinUrl: null,
    searchUrl: null,
    shopUrl: null
  };

  const config = adminConfig.map(configEl => ({
    title: configEl.node.title,
    control: configEl.node.control,
    url: configEl.node.url
  }));

  for (let i = 0; i < config.length; i += 1) {
    const element = config[i];
    const title = element.title.toLowerCase();
    if (title.includes('esu')) {
      options.showEsu = element.control;
    } else if (title.includes('search')) {
      options.showSearch = element.control;
      options.searchUrl = element.url;
    } else if (title.includes('shop')) {
      options.showShop = element.control;
      options.shopUrl = element.url;
    } else if (title.includes('payin')) {
      options.showPayin = element.control;
      options.payinUrl = element.url;
    }
  }

  if (menu !== null) {
    return (
      <ThemeConsumer>
        {(theme) => {
          const campaignName = () => {
            if (campaign === 'sportrelief') {
              return 'Sport Relief Gameon';
            }
            if (campaign === 'bignightin') {
              return 'Big Night In';
            }
            return 'Comic Relief';
          };

          return (
            <FixedHeader font={theme.font.regular} show={hideOnScroll}>
              <HeaderCL
                campaign={campaignName()}
                navItems={menu}
                rotate="true"
                metaIcons={metaIconLinks(
                  removeDonateLink,
                  options,
                  setBlockHide,
                  landingPageCartId
                )}
              />
            </FixedHeader>
          );
        }}
      </ThemeConsumer>
    );
  }
  return null;
};

HeaderNoData.propTypes = {
  removeDonateLink: PropTypes.bool,
  campaign: PropTypes.string,
  menu: PropTypes.objectOf(PropTypes.shape).isRequired,
  adminConfig: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.bool,
      control: PropTypes.bool,
      url: PropTypes.string
    })
  ),
  landingPageCartId: PropTypes.string
};

export default HeaderNoData;
