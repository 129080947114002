import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@comicrelief/component-library';

const ServiceDown = ({ isServiceDown = null }) => {
  if (isServiceDown) {
    return (
      <div>
        <Text tag="h2" size="xl" uppercase>
          We’ll be back soon
        </Text>
        <Text tag="p" size="s">
          Whoops! This prize is currently experiencing
          <br />
          {' '}
          technical issues.
        </Text>
        <Text tag="p" size="s">
          We’re working on it, please check back soon.
        </Text>
        <Text tag="p" size="s">
          Thank you for your patience.
        </Text>
      </div>
    );
  }
  return (
    <Text tag="h2" size="xl" uppercase>
      Loading...
    </Text>
  );
};

ServiceDown.propTypes = {
  isServiceDown: PropTypes.bool
};

export default ServiceDown;
