import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import Paragraphs from '../components/Paragraphs/Paragraphs';
import seoDefaultImage from '../../static/assets/news-default.jpg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color('grey_light')};
`;

const Index = ({ data }) => {
  const {
    allContentfulPageLandingPage: { edges },
    allContentfulPageArticle: { edges: newsEdges },
    allContentfulCart: { edges: moneyBuys }
  } = data;

  const { path, paragraphs, removeDonateLink } = edges[0].node;

  return (
    <Layout theme="comicrelief" removeDonateLink={removeDonateLink}>
      <SEO
        title="A just world free from poverty | Comic Relief"
        image={seoDefaultImage}
        pathname={path}
      >
        {({ siteUrl }) => [
          <script type="application/ld+json" key="website">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              url: siteUrl,
              potentialAction: {
                '@type': 'SearchAction',
                target: `${siteUrl}/search?query={searchbox_target}`,
                'query-input': 'required name=searchbox_target'
              }
            })}
          </script>,
          <script type="application/ld+json" key="organization">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              url: siteUrl,
              logo: `${siteUrl}/assets/logo.jpg`
            })}
          </script>,
          <script type="application/ld+json" key="carousel">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'ItemList',
              itemListElement: newsEdges.map((article, position) => ({
                '@type': 'ListItem',
                position,
                url: `${siteUrl}/news/${article.node.path}`
              }))
            })}
          </script>
        ]}
      </SEO>
      <Wrapper data-test="homepage">
        {paragraphs && (
          <Paragraphs
            theme="comicrelief"
            paragraphs={paragraphs}
            moneyBuys={moneyBuys}
            articles={newsEdges}
          />
        )}
      </Wrapper>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    allContentfulPageLandingPage: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape)
    }).isRequired,
    allContentfulPageArticle: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape)
    }).isRequired,
    allContentfulCart: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape)
    }).isRequired
  }).isRequired
};

export default Index;

export const queryCards = graphql`
  fragment Picture on ContentfulAsset {
    description
    fluid(quality: 90, maxWidth: 1024) {
      srcSet
    }
    fallback: fixed {
      src
    }
    placeHolder: fixed(width: 50) {
      src
    }
    seoImage: resize {
      src
    }
  }

  fragment Accordion on ContentfulAccordion {
    __typename
    contentful_id
    title
    body {
      raw
    }
  }

  fragment MapParagraph on ContentfulParagraphMap {
    __typename
    contentful_id
    id
    name
    mapType
    backgroundColour
  }

  fragment WhatYourMoneyDoesCarousel on ContentfulWhatYourMoneyDoesCarousel {
    __typename
    contentful_id
    headerCopy
    peopleHelpedText
    mobileHeight
    tabletHeight
    desktopHeight
    autoPlay
    backgroundColour
    paddingOptions
    node1Copy
    node1Amount
    node1Image {
      file {
        url
      }
    }
    node2Copy
    node2Amount
    node2Image {
      file {
        url
      }
    }
    node3Copy
    node3Amount
    node3Image {
      file {
        url
      }
    }
    node4Copy
    node4Amount
    node4Image {
      file {
        url
      }
    }
    node5Copy
    node5Amount
    node5Image {
      file {
        url
      }
    }
    node6Copy
    node6Amount
    node6Image {
      file {
        url
      }
    }
    node7Copy
    node7Amount
    node7Image {
      file {
        url
      }
    }
    node8Copy
    node8Amount
    node8Image {
      file {
        url
      }
    }
    node9Copy
    node9Amount
    node9Image {
      file {
        url
      }
    }
  }

  fragment RichtextCarousel on ContentfulRichtextCarousel {
    __typename
    contentful_id
    name
    headingCopy {
      raw
    }
    nodes {
      name
      copy {
        raw
      }
    }
    mobileHeight
    tabletHeight
    desktopHeight
    autoPlay
    paddingOptions
    rowBackgroundColour
    carouselBackgroundColour
    nodeBackgroundColour
    nodeOutlineColour
  }

  fragment Asset on ContentfulAsset {
    __typename
    contentful_id
    title
    ...Picture
    file {
      url
    }
  }

  fragment HtmlCode on ContentfulHtmlCode {
    __typename
    contentful_id
    snippetCode {
      snippetCode
    }
  }

  fragment EmbeddableVideo on ContentfulEmbeddableVideo {
    __typename
    contentful_id
    videoFile {
      file {
        url
      }
    }
    videoThumbnail {
      file {
        url
      }
    }
  }

  fragment Html on ContentfulHtml {
    __typename
    contentful_id
    snippetCode {
      snippetCode
    }
  }

  fragment EmailSignUp on ContentfulEmailSignUp {
    __typename
    id
    name
    title
    contentful_id
    formColour
    databaseTag
    campaignCode
    buttonColour
    privacyCopy {
      raw
    }
    successCopy {
      raw
    }
    topCopy {
      raw
    }
  }

  fragment Link on ContentfulComponentLink {
    __typename
    contentful_id
    id
    title
    url
    sys {
      type
    }
  }

  fragment LinkPageDefault on ContentfulPageDefault {
    contentful_id
    id
    path
    title
  }
  fragment LinkPageArticle on ContentfulPageArticle {
    contentful_id
    id
    path
    title
    type
  }
  fragment LinkLandingPage on ContentfulPageLandingPage {
    contentful_id
    id
    path
    title
    cartId
  }

  fragment BoxesParagraph on ContentfulParagraphBoxes {
    __typename
    title
    layout
    backgroundColour
    cards {
      id
      title
      centredText
      squaredCorners
      body {
        raw
        references {
          ...HtmlCode
        }
      }
      image {
        ...Picture
      }
    }
  }

  fragment BannerParagraph on ContentfulBanner {
    __typename
    title
    backgroundColour
    body {
      raw
    }
  }

  fragment BitesizeParagraph on ContentfulBiteSize {
    __typename
    title
    body {
      raw
    }
    ctaLabel
    ctaLink
  }

  fragment TeasersParagraph on ContentfulParagraphTeasers {
    __typename
    title
    hideTitle
    backgroundColour
    body {
      raw
    }
    cards {
      id
      title
      link
      image {
        ...Picture
      }
    }
  }

  fragment CardsParagraph on ContentfulParagraphCards {
    __typename
    title
    hideTitle
    backgroundColour
    cardsCentredText
    paddingOptions
    smallBreakpointRowLayout
    mediumBreakpointRowLayout
    body {
      raw
    }
    layout
    cards {
      id
      title
      backgroundColour
      label
      centredText
      squaredCorners
      body {
        raw
      }
      image {
        ...Picture
      }
    }
  }

  fragment CardsDsParagraph on ContentfulParagraphCardsDs {
    __typename
    title
    backgroundColour
    layout
    columnsOnMd
    paddingOptions
    carouselOfCards
    cards {
      id
      title
      backgroundColour
      link
      linkLabel
      body {
        raw
      }
      image {
        ...Picture
      }
    }
  }

  fragment JokeGeneratorParagraph on ContentfulParagraphJokeGenerator {
    __typename
    title
    jokes {
      answer
      question
    }
    exitPages {
      donateButtonLink
      donateButtonText
      heading
      moreJokesButtonText
      tagLine
    }
  }

  fragment BigRedButtonChallengeParagraph on ContentfulParagraphBigRedButtonChallenge {
    __typename
    title
    challenges {
      title
      subtitle
      video {
        file {
          url
        }
      }
      priority
    }
    finalMessage {
      title
      subtitle
      video {
        file {
          url
        }
      }
    }
  }

  fragment FeaturedNewsParagraph on ContentfulParagraphFeaturedNews {
    __typename
    name
    title
    hideTitle
    backgroundColour
    featuredCategory {
      title
    }
  }

  # Partner fragment
  fragment PartnerListParagraph on ContentfulPartnersList {
    __typename
    ... on ContentfulPartnersList {
      title
      id
      partnerCategory {
        title
      }
    }
  }


  # HTML Paragraph fragment
  fragment HTMLParagraph on ContentfulParagraphHtml {
    __typename
    name
    id
    snippetCode {
      snippetCode
    }
  }

  fragment PartnerList on ContentfulPagePartner {
    __typename
    id
    name
    weight
    url
    path
    category
    paragraphs {
      ...PartnerListParagraph
      ...SingleMessageParagraph
      ...BoxesParagraph
      ...CardsParagraph
      ...IframeParagraph
      ...CardsDsParagraph
      ...SingleMessageDsParagraph
      ...Promo
      ...Heading
      ...BannerParagraph
      ...BitesizeParagraph
      ...DonateParagraph
    }
    summary {
      summary
    }
    body {
      raw
    }
    logo {
      ...Picture
    }
    group {
      title
    }
  }

  # Press releases
  fragment PressReleasesListParagraph on ContentfulPressReleasesList {
    __typename
    title
  }

  fragment ArticleList on ContentfulPageArticle {
    __typename
    id
    path
    title
    type
    displayDate(formatString: "Do MMMM YYYY")
    categories {
      title
    }
    body {
      raw
    }
    summary {
      summary
    }
    image {
      ...Picture
    }
  }
  # People fragment
  fragment PeopleListParagraph on ContentfulPeopleList {
    __typename
    ... on ContentfulPeopleList {
      title
    }
  }

  fragment IframeParagraph on ContentfulParagraphIframe {
    __typename
    id
    title
    backgroundColour
    embedLink
    nonProductionEmbedLink
    initialHeight
    iframeLoaded
    body {
      raw
    }
    placeholderImage {
      ...Picture
    }
    placeholderImageAltText
  }

  fragment VideoBannerParagraph on ContentfulParagraphVideoBanner {
    __typename
    id
    title
    poster {
      fixed {
        src
      }
    }
    backgroundVideo {
      file {
        url
      }
    }
    muteAudio
    showPosterImageAfterPlaying
    showUserControls
    autoplayOnPageLoad
    loop
  }

  fragment PeopleList on ContentfulPagePerson {
    __typename
    id
    title
    type
    role
    path
    image {
      ...Picture
      fixed(height: 360, width: 360) {
        src
      }
    }
  }

  # Donate fragment
  fragment DonateParagraph on ContentfulParagraphDonate {
    __typename
    ... on ContentfulParagraphDonate {
      id
      cartId
      shortCartURL
      title
      subtitle
      noMoneyBuys
      formAlignRight
      donationUrl
      clientId
      desktopTitleTextColour
      desktopOverlayColour
      backgroundColour
      mobileTheme
      image {
        ...Picture
      }
      popUpText
      chooseAmountText
      mobileAndTabletImage {
        ...Picture
      }
      otherAmountText
      additionalSingleCopy
      additionalMonthlyCopy
      monthlyOnlyTitle
      monthlyOnlySubtitle
      monthlyOnlyChooseAmountText
      monthlyOnlyOtherAmountText
      paddingOptions
    }
  }

  # Membership fragment
  fragment MembershipParagraph on ContentfulParagraphMembership {
    __typename
    ... on ContentfulParagraphMembership {
      id
      cartId
      title
      subtitle
      monthlyGiving
      formAlignRight
      donationUrl
      moneyBuyDescription4
      backgroundColour
      image {
        ...Picture
      }
    }
  }

  fragment SingleMessageParagraph on ContentfulSingleMessage {
    __typename
    id
    title
    copyFirst
    centredText
    fullImage
    youTubeVideoId
    landscapeVideo
    image {
      ...Picture
    }
    secondaryImage {
      ...Picture
    }
    verticalHeight
    label
    text {
      raw
      references {
        ...Asset
        ...HtmlCode
        ...EmailSignUp
        ...EmbeddableVideo
        ...Link
        ...LinkPageDefault
        ...LinkPageArticle
        ...LinkLandingPage
        ...Accordion
      }
    }
    backgroundColour
    paddingOptions
  }

  fragment SingleMessageDsParagraph on ContentfulSingleMessageDs {
    __typename
    label
    title
    ctaLink
    ctaLabel
    ctaBackgroundColour
    imageLeft
    image {
      ...Picture
    }
    body {
      raw
    }
    youTubeId
    backgroundColour
  }

  fragment Countdown on ContentfulCountdown {
    __typename
    id
    title
    endDate(formatString: "MMM Do, YYYY h:mm:ss")
    introMessage {
      raw
    }
    endMessage {
      raw
    }
  }

  fragment Promo on ContentfulPromo {
    __typename
    id
    title
    copyLeft
    addOverlay
    mobileTextColour
    type
    edgeColour
    backgroundColourMobile
    buttonColour
    mobileButtonColour
    backgroundImage {
      ...Picture
    }
    mobileBackgroundImage {
      ...Picture
    }
    tabletBackgroundImage {
      ...Picture
    }
    body {
      raw
      references {
        __typename
        contentful_id
        countdownFontColour
        endDate
        endMessage {
          raw
        }
        introMessage {
          raw
        }
      }
    }
    videoAutoplay
    videoLoop
    videoBehindTextGradient
    videoShowPosterImageAfterPlaying
    videoMobileVideoPosterImage {
      file {
        url
      }
    }
    videoDesktopVideoPosterImage {
      file {
        url
      }
    }
    videoDesktopVideoFile {
      file {
        url
      }
    }
    videoMobileVideoFile {
      file {
        url
      }
    }
  }

fragment ImpactSlider on ContentfulImpactSlider {
  __typename
  contentful_id
  id
  body {
    raw
  }
  sliderHeading
  defaultSliderValue
  increment
  maximum
  cartId
  opacityAnimation
  moneybuy1Description
  moneybuy1PriceDecimal
  moneybuy2Description
  moneybuy2PriceDecimal
  moneybuy3Description
  moneybuy3PriceDecimal
  moneybuy4Description
  moneybuy4PriceDecimal
  whiteBackground
  moneybuy4Image {
    file {
      url
    }
  }
  moneybuy3Image {
    file {
      url
    }
  }
  moneybuy2Image {
    file {
      url
    }
  }
  moneybuy1Image {
    file {
      url
    }
  }
  children {
    id
  }
}

  fragment DescriptorParagraph on ContentfulParagraphDescriptors {
    __typename
    ... on ContentfulParagraphDescriptors {
      title
    }
  }

  fragment Descriptor on ContentfulDescriptor {
    ... on ContentfulDescriptor {
      __typename
      id
      category
      title
      date(formatString: "Do MMMM YYYY")
      tags
      description {
        raw
        references {
          __typename
          contentful_id
          title
          file {
            url
          }
        }
      }
    }
  }

  fragment Heading on ContentfulHeading {
    __typename
    id
    title
    backgroundColour
    heading {
      raw
      references {
        ...Accordion
      }
    }
  }

  query getHomepage {
    allContentfulPageLandingPage(filter: { path: { eq: "homepage" } }) {
      edges {
        node {
          path
          title
          cartId
          removeDonateLink
          paragraphs {
            ...PartnerListParagraph
            ...PeopleListParagraph
            ...SingleMessageParagraph
            ...CardsParagraph
            ...DonateParagraph
            ...MembershipParagraph
            ...FeaturedNewsParagraph
            ...IframeParagraph
            ...TeasersParagraph
            ...BoxesParagraph
            ...CardsDsParagraph
            ...JokeGeneratorParagraph
            ...BigRedButtonChallengeParagraph
            ...DescriptorParagraph
            ...SingleMessageDsParagraph
            ...Promo
            ...Heading
            ...BannerParagraph
            ...BitesizeParagraph
            ...HTMLParagraph
            ...ImpactSlider
            ...MapParagraph
            ...WhatYourMoneyDoesCarousel
          }
        }
      }
    }

    allContentfulCart {
      edges {
        node {
          cartId
          contentful_id
          singleGiving {
            mobileImage {
              ...Picture
            }
            moneybuys {
              description
              value
            }
          }
          regularGiving {
            moneybuys {
              description
              value
            }
          }
        }
      }
    }

    allContentfulPageArticle(
      limit: 3
      sort: { fields: displayDate, order: DESC }
      filter: {
        type: { eq: "News Article" }
        image: { title: { glob: "*" } }
        excludeFromFeatureArticles: { ne: "Yes" }
      }
    ) {
      edges {
        node {
          id
          title
          path
          displayDate(formatString: "Do MMMM YYYY")
          body {
            raw
          }
          image {
            description
            fluid(
              cropFocus: CENTER
              maxHeight: 410
              resizingBehavior: FILL
              maxWidth: 740
            ) {
              srcSet
            }
            fallback: fixed {
              src
            }
            placeHolder: fixed(
              cropFocus: CENTER
              height: 410
              resizingBehavior: FILL
              width: 740
              quality: 1
            ) {
              src
            }
            seoImage: resize {
              src
            }
          }
        }
      }
    }
  }
`;
