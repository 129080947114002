import styled from 'styled-components';

const Container = styled.div`
  display: none;
  @media (min-width: 390px) {
    display: ${({ isCarousel }) => (isCarousel ? 'flex' : 'none')};
  }
  @media (min-width: 1023px) {
    display: none;
  }
  padding: 2rem 0 3.3rem;
  position: relative;
  justify-content: center;
  gap: 0.5rem;
  overflow: hidden;
  background: ${({ bgColour }) => bgColour};
  text-align: left;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: 0.8rem auto 1.5rem;
  }
  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    padding: 5rem 0 7.8rem;
    gap: 4rem;
  }
`;

const Slide = styled.div`
  width: 60%;
  flex-shrink: 0;
  > div > div {
    flex-grow: 1;
  }
  > div {
    flex-direction: column;
  }
`;

export {
  Container,
  Slide
};
