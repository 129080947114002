import React from 'react';
import PropTypes from 'prop-types';

import {
  OptionCardWrapper,
  OptionCardLabel,
  OptionCardInput,
  OptionCardContent,
  OptionCardContentTop,
  OptionCardImage,
  OptionCardText,
  OptionCardIcon
} from './OptionCard.style';

// A card-style input component intended to be used as a checkbox or radio button.
const OptionCard = React.forwardRef(({
  type,
  name,
  value,
  label,
  image = '',
  className = '',
  withIcon = true,
  withShadow = false,
  whiteBackground = false,
  onSelect = undefined,
  onChange = undefined,
  ...otherInputProps
}, ref) => (
  <OptionCardWrapper className={className}>
    <OptionCardLabel data-test={value}>
      <OptionCardInput
        type={type}
        name={name}
        value={value}
        ref={ref}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          if (onSelect && e.target.checked) {
            onSelect(e);
          }
        }}
        {...otherInputProps}
      />
      <OptionCardContent withShadow={withShadow} whiteBackground={whiteBackground}>
        <OptionCardContentTop>
          {withIcon && <OptionCardIcon />}
          <OptionCardImage image={image} />
        </OptionCardContentTop>
        <OptionCardText uppercase weight="bold" tag="div">
          {label}
        </OptionCardText>
      </OptionCardContent>
    </OptionCardLabel>
  </OptionCardWrapper>
));

OptionCard.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  withIcon: PropTypes.bool,
  withShadow: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func
};

export default OptionCard;
