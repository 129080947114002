import React from 'react';
import PropTypes from 'prop-types';
import { WYMDCarousel as WYMDCarouselCL } from '@comicrelief/component-library';
import { snakeCase } from 'lodash';
import OuterWrapper from './WYMDCarousel.style';

const WYMDCarousel = ({ data }) => {
  const paddingOption = data.paddingOptions ? snakeCase(data.paddingOptions) : null;
  const bgColour = data.backgroundColour ? snakeCase(data.backgroundColour) : null;

  return (
    <OuterWrapper paddingOption={paddingOption} bgColour={bgColour}>
      <WYMDCarouselCL data={data} />
    </OuterWrapper>
  );
};

WYMDCarousel.propTypes = {
  data: PropTypes.shape({
    contentful_id: PropTypes.string.isRequired,
    autoPlay: PropTypes.bool.isRequired,
    headerCopy: PropTypes.string.isRequired,
    peopleHelpedText: PropTypes.string.isRequired,
    mobileHeight: PropTypes.number.isRequired,
    tabletHeight: PropTypes.number.isRequired,
    desktopHeight: PropTypes.number.isRequired,
    backgroundColour: PropTypes.string.isRequired,
    paddingOptions: PropTypes.string.isRequired,
    // Required 'node' fields:
    node1Amount: PropTypes.string.isRequired,
    node1Copy: PropTypes.string.isRequired,
    node1Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    node2Amount: PropTypes.string.isRequired,
    node2Copy: PropTypes.string.isRequired,
    node2Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    node3Amount: PropTypes.string.isRequired,
    node3Copy: PropTypes.string.isRequired,
    node3Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    node4Amount: PropTypes.string.isRequired,
    node4Copy: PropTypes.string.isRequired,
    node4Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    // Non-required 'node' fields:
    node5Amount: PropTypes.string,
    node5Copy: PropTypes.string,
    node5Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    node6Amount: PropTypes.string,
    node6Copy: PropTypes.string,
    node6Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    node7Amount: PropTypes.string,
    node7Copy: PropTypes.string,
    node7Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    node8Amount: PropTypes.string,
    node8Copy: PropTypes.string,
    node8Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    node9Amount: PropTypes.string,
    node9Copy: PropTypes.string,
    node9Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    })
  }).isRequired
};

export default WYMDCarousel;
