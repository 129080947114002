import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { get, kebabCase } from 'lodash';
import moment from 'moment';
import {
  Text, ArticleTeaser
} from '@comicrelief/component-library';

import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import Promo from '../../components/Promo/Promo';

import prizeDefaultImage from '../../../static/assets/news-default.jpg';
import { prizeStatus } from '../../components/Prize/_utils';
import {
  Wrapper,
  Container,
  Grid,
  Items,
  Section,
  Title,
  Closed,
  Live
} from '../../components/Prize/Prizes.styles';

const Prizes = (props) => {
  const prizes = get(props, 'data.allContentfulPrizes.edges');
  const prizesPromo = get(props, 'data.allContentfulPromo.edges');
  const [todaysDate, setTodaysDate] = useState(null);

  const today = moment();

  const handleGtm = (title, id, index) => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      ecommerce: {
        click: {
          actionField: { list: 'prizes homepage listing' },
          products: [{
            id,
            name: title,
            price: 0.00,
            brand: 'cr-prizes',
            category: 'rednoseday',
            variant: 'none',
            position: index
          }]
        }
      },
      event: 'productClick'
    });
  };

  useEffect(() => {
    setTodaysDate(today);
  }, [prizes]);

  const currentPrizes = prizes.filter((prize) => {
    const prizeEndDate = moment(prize.node.endDate);
    return prizeEndDate.diff(todaysDate) > 0;
  });

  const closedPrizes = prizes.filter((prize) => {
    const prizeEndDate = moment(prize.node.endDate);
    return prizeEndDate.diff(todaysDate) < 0 && prize.node.winner !== true;
  });

  const winnerPrizes = prizes.filter(prize => prize.node.winner);

  return (
    <Layout>
      <SEO title="Prizes | Comic Relief" pathname="prizes" description="">
        {({ siteUrl }) => (
          <script type="application/ld+json" key="carousel">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'ItemList',
              itemListElement: prizes.map((article, position) => ({
                '@type': 'ListItem',
                position,
                url: `${siteUrl}prizes/${article.node.path}`
              }))
            })}
          </script>
        )}
      </SEO>
      <Wrapper>
        {prizesPromo && prizesPromo.length > 0 ? (
          <Promo data={prizesPromo[0].node} />
        ) : null}
        <Container>
          <Section>
            {currentPrizes.length > 0 ? (
              <>
                <Title>
                  <Text tag="h2" font="Anton" uppercase size="xl">Open Prize Draws</Text>
                </Title>
                <Grid>
                  {currentPrizes.map(({ node }, index) => {
                    const {
                      featuredImage, path, endDate, title, id, category
                    } = node;

                    const fallback = featuredImage?.fallback?.src;
                    const images = featuredImage?.fluid?.srcSet;
                    const imageLow = featuredImage
                      ? featuredImage.placeHolder.src : prizeDefaultImage;
                    const description = featuredImage?.description;
                    const URL = `/prizes/${kebabCase(category.name)}/${path}`;
                    const date = `Ends on ${moment(endDate).format('Do MMMM YYYY')}`;

                    return (
                      <Items key={id}>
                        <Live
                          onClick={
                            () => handleGtm(title, id, index)
}
                          ends={prizeStatus(endDate)}
                        >
                          <ArticleTeaser
                            href={URL}
                            image={fallback}
                            images={images}
                            imageLow={imageLow}
                            date={date}
                            title={title}
                            alt={description}
                            time=""
                          />
                        </Live>
                      </Items>
                    );
                  })}
                </Grid>
              </>
            ) : null}
            {winnerPrizes.length > 0 ? (
              <>
                <Title>
                  <Text tag="h2" font="Anton" uppercase size="xl">See the winners</Text>
                </Title>
                <Grid>
                  {winnerPrizes.map(({ node }) => {
                    const {
                      featuredImage, path, title, id, category
                    } = node;

                    const fallback = featuredImage ? featuredImage.fallback.src : prizeDefaultImage;
                    const images = featuredImage ? featuredImage.fluid.srcSet : prizeDefaultImage;
                    const imageLow = featuredImage
                      ? featuredImage.placeHolder.src : prizeDefaultImage;
                    const description = featuredImage ? featuredImage.description : '';
                    const URL = `/prizes/${kebabCase(category.name)}/${path}`;

                    return (
                      <Items key={id}>
                        <ArticleTeaser
                          href={URL}
                          image={fallback}
                          images={images}
                          imageLow={imageLow}
                          date={null}
                          title={title}
                          alt={description}
                          time=""
                        />
                      </Items>
                    );
                  })}
                </Grid>
              </>
            ) : null }
            {closedPrizes.length > 0 ? (
              <>
                <Title>
                  <Text tag="h2" font="Anton" uppercase size="xl">Closed Prize draws</Text>
                </Title>
                <Grid>
                  {closedPrizes.map(({ node }) => {
                    const {
                      featuredImage, path, endDate, title, id, category
                    } = node;

                    const fallback = featuredImage ? featuredImage.fallback.src : prizeDefaultImage;
                    const images = featuredImage ? featuredImage.fluid.srcSet : prizeDefaultImage;
                    const imageLow = featuredImage
                      ? featuredImage.placeHolder.src : prizeDefaultImage;
                    const description = featuredImage ? featuredImage.description : '';
                    const URL = `/prizes/${kebabCase(category.name)}/${path}`;
                    const date = `Ended on ${moment(endDate).format('Do MMMM YYYY')}`;

                    return (
                      <Items key={id}>
                        <Closed>
                          <ArticleTeaser
                            href={URL}
                            image={fallback}
                            images={images}
                            imageLow={imageLow}
                            date={date}
                            title={title}
                            alt={description}
                            time=""
                          />
                        </Closed>
                      </Items>
                    );
                  })}
                </Grid>
              </>
            ) : null }
          </Section>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default Prizes;

export const pageQuery = graphql`
  query getPrizes($skip: Int, $limit: Int) {
    allContentfulPrizes(
      skip: $skip
      limit: $limit
      sort: { fields: [endDate], order: DESC }
    ) {
      totalCount
      pageInfo {
        perPage
        currentPage
        hasPreviousPage
        hasNextPage
        itemCount
        pageCount
      }
      edges {
        node {
          title
          path
          id
          category {
            name
          }
          startDate(formatString: "Do MMMM YYYY")
          endDate
          winner
          featuredImage {
            description
            fluid(quality: 90){
              srcSet
            }
            fallback: fixed {
              src
            }
            placeHolder: fixed(quality: 1) {
              src
            }
            seoImage: resize {
              src
            }
          }
        }
      }
    }
    allContentfulPromo(filter: {title: {eq: "Prizes Header"}}) {
      edges {
        node {
          ...Promo
        }
      }
    }
  }
`;
