import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import readingTime from 'reading-time';
import {
  Text, ArticleTeaser, Pagination, spacing, containers
} from '@comicrelief/component-library';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import NewsFilter from '../../components/NewsFilter/NewsFilter';

import newsDefaultImage from '../../../static/assets/news-default.jpg';

const Wrapper = styled.article.attrs({
  'data-test': 'news'
})`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color('grey_light')};
`;

const Container = styled.div`
  width: 100%;
  max-width: ${containers.large};
  margin: 0 auto;
  padding-bottom: ${spacing('xxl')};
`;

const Header = styled.header`
  text-align: center;
  padding: ${spacing('l')};
  padding: calc(${spacing('xxl')} - ${spacing('l')}) ${spacing('l')};
  background: ${({ theme }) => theme.color('deep_violet')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: calc(${spacing('xxl')} - ${spacing('l')}) ${spacing('l')};
  }
`;

const Section = styled.section.attrs({
  'data-test': 'news-list'
})`
  width: 100%;
  display: flex;
  margin-bottom: ${spacing('m')};
  text-align: center;
  flex-wrap: wrap;
`;

const Grid = styled.div`
  display: flex;
  max-width: ${containers.medium};
  justify-content: space-between;
  margin: 0 auto ${spacing('l')};
  padding: ${spacing('l')};
  flex-direction: column;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Items = styled.div.attrs({
  'data-test': 'news-item'
})`
  margin: ${spacing('md')} 0;
  width: 100%;
  text-align: left;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin: ${spacing('md')};
    /* 0.5 to fix ie11 bug */
    width: calc(100% / 3 - 2.05rem);
    a > div:first-child {
      height: 190px;
    }
  }
`;

const handleSelect = () => {
  window.scrollTo({ top: 100, behavior: 'smooth' });
};

const handlePaginationUrl = (page) => {
  if (page === 1) {
    return '/news/';
  }
  return `/news/page-${page}`;
};

const Articles = (props) => {
  const articles = get(props, 'data.allContentfulPageArticle.edges');
  const pageInfo = get(props, 'data.allContentfulPageArticle.pageInfo');
  const { title: currentPageTitle, path: currentPagePath, summary } = get(
    props,
    'data.allContentfulPageLandingPage.edges[0].node'
  );

  return (
    <Layout>
      <SEO title={`${currentPageTitle} | Comic Relief`} pathname={currentPagePath} description={summary}>
        {({ siteUrl }) => (
          <script type="application/ld+json" key="carousel">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'ItemList',
              itemListElement: articles.map((article, position) => ({
                '@type': 'ListItem',
                position,
                url: `${siteUrl}news/${article.node.path}`
              }))
            })}
          </script>
        )}
      </SEO>
      <Wrapper>
        <Header>
          <Text tag="h1" size="super" color="white" weight="normal" family="Anton">
            NEWS
          </Text>
        </Header>
        <Container>
          <Section>
            <NewsFilter selectedCategory="All" />
            <Grid>
              {articles.map(({ node }) => {
                const {
                  image, path, displayDate, title, id, body
                } = node;

                const fallback = image ? image.fallback?.src : newsDefaultImage;
                const images = image ? image.fluid?.srcSet : newsDefaultImage;
                const imageLow = image ? image.placeHolder?.src : newsDefaultImage;
                const description = image ? image.description : '';
                const URL = `/news/${path}`;
                const content = body && documentToPlainTextString(body.raw);
                const time = content && readingTime(content);
                return (
                  <Items key={id}>
                    <ArticleTeaser
                      href={URL}
                      image={fallback}
                      images={images}
                      imageLow={imageLow}
                      date={displayDate}
                      title={title}
                      alt={description}
                      time={time && time.text}
                    />
                  </Items>
                );
              })}
            </Grid>
          </Section>
          <div data-test="news-pagination">
            <Pagination
              disabledBackgroundColor="grey_dark"
              disabledColor="grey_light"
              maxPages={10}
              totalPages={pageInfo.pageCount}
              currentPage={pageInfo.currentPage}
              createURL={value => handlePaginationUrl(value)}
              onSelect={handleSelect}
              showFirst
              showPrevious={false}
              showNext={false}
              showLast
            />
          </div>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default Articles;

export const pageQuery = graphql`
  query getArticles($skip: Int, $limit: Int) {
    allContentfulPageArticle(
      skip: $skip
      limit: $limit
      filter: { type: { eq: "News Article" } }
      sort: { fields: [displayDate], order: DESC }
    ) {
      totalCount
      pageInfo {
        perPage
        currentPage
        hasPreviousPage
        hasNextPage
        itemCount
        pageCount
      }
      edges {
        node {
          title
          path
          type
          id
          body {
            raw
          }
          displayDate(formatString: "Do MMMM YYYY")
          image {
            description
            fluid(cropFocus: FACE, maxHeight: 410, resizingBehavior: FILL, maxWidth: 740) {
              srcSet
            }
            fallback: fixed {
              src
            }
            placeHolder: fixed(cropFocus: FACE, height: 410, resizingBehavior: FILL, width: 740, quality: 1) {
              src
            }
            seoImage: resize {
              src
            }
          }
        }
      }
    }
    allContentfulPageLandingPage(filter: { path: { eq: "news-seo" } }) {
      edges {
        node {
          path
          title
          summary {
            summary
          }
        }
      }
    }
  }
`;
