import styled from 'styled-components';
import { spacing } from '@comicrelief/component-library';
import handlePadding from '@comicrelief/component-library/src/utils/_utils';

const OuterWrapper = styled.div`
  padding: ${spacing('md')};
  background-color: ${({ theme, rowBackgroundColour }) => theme.color(rowBackgroundColour)} !important;
  ${({ paddingOption }) => handlePadding(paddingOption)};

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('lg')}
    ${({ paddingOption }) => handlePadding(paddingOption)};
  }

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    padding: ${spacing('lg')}
    ${({ paddingOption }) => handlePadding(paddingOption)};
  }

  // Heading Copy wrapper:
  .CarouselWrapper {
    > div:first-child {
      * {
        margin-top: 0;
      }
        
      h1, h2, h3 {
        margin-bottom: 1.5rem;
      }
    }
  }

  // Downsize our standard font sizing:
  .carousel .carousel__inner-slide .slide-copy-wrapper {
    font-size: 0.9rem;
    * {
      font-size: inherit;
    }
  }
`;

export default OuterWrapper;
