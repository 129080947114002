import React from 'react';
import styled from 'styled-components';
import { Text, spacing } from '@comicrelief/component-library';

const CopyWrapper = styled.div`
  text-align: center;
  padding: ${spacing('xl')} 0;
`;

const NoResultsMessage = () => (
  <CopyWrapper>
    <Text tag="p" size="l">
      Sorry, there are no results for your search.
    </Text>
    <Text tag="p" size="l">
      Please try searching for a new keyword or phrase.
    </Text>
  </CopyWrapper>
);

export default NoResultsMessage;
