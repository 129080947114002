import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { breakpointValues } from '@comicrelief/component-library/src/theme/shared/allBreakpoints';
import { HexagonLayer } from '@deck.gl/aggregation-layers';
import { MapboxOverlay } from '@deck.gl/mapbox/typed';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Map, NavigationControl } from 'maplibre-gl';
import BeatLoader from 'react-spinners/BeatLoader';
import { snakeCase } from 'lodash';

import mapTilerLogo from './assets/maptiler-icon--small.png';

// Created with Maputnik; re-imported and edited here https://maputnik.github.io/editor
// Or by running a local instance https://github.com/maplibre/maputnik
import mapLayout from './assets/crmap1.json';

import {
  OuterWrapper, MapWrapper, MapLogoWrapper, colourRange, StatusContainer
} from './ParagraphMap.styles';

const ParagraphMap = ({ data }) => {
  const { mapType, contentful_id: id, backgroundColour } = data;
  const [storedData, setStoredData] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const bgColour = backgroundColour ? snakeCase(backgroundColour) : null;
  const thisID = `map-${mapType}--${id}`;
  // Although we don't need to show the logo on our now 'Unlimited' Maptiler account, I figured
  // it'd be a good shout to keep the 'free tier' requirement logic and asset handy still:
  const showMaptilerLogo = false;

  useEffect(() => {
    // Function called below, on initial mount/load,
    // to ensure data is available:
    async function awaitData(source) {
      const response = await fetch(source);
      if (response.status === 200) {
        const output = await response.json();
        if (output.data) {
          setStoredData(output.data);
          setHasLoaded(true);
        } else {
          // If we don't have *any* data, something's gone wrong:
          setHasErrored(true);
        }
      } else {
        // Non-200 response:
        setHasErrored(true);
      }
    }

    // On initial load, attempt to load JSON data from the env-set JSON
    if (process.env.GATSBY_SCHOOLS_FSU_MAP_DATA_SOURCE) {
      const thisDataSource = `${process.env.GATSBY_SCHOOLS_FSU_MAP_DATA_SOURCE}/fsu_sign_up_locations.json`;
      awaitData(thisDataSource);
    }
  }, []);

  useEffect(() => {
    if (storedData) {
      // Just to make the UK best fit in breakpoint's layout
      const thisZoom = window.matchMedia(`(max-width: ${breakpointValues.M}px)`).matches ? 4.1 : 4.4;

      const map = new Map({
        container: thisID,
        style: mapLayout,
        zoom: thisZoom,
        minZoom: 3.5,
        maxZoom: 7,
        antialias: true,
        pitch: 1,
        // UK-centric boundaries
        maxBounds: [[-30.0, 48.0], [21.0, 62.0]],
        // Using Glasgow-ish as a centerpoint, to ensure the Scottish islands,
        // still part of the UK, remain visible on initial load:
        center: [-4.500000, 55.300000],
        attributionControl: false
      });

      map.on('load', () => {
        map.addControl(new NavigationControl({ showZoom: true }), 'bottom-left');

        const hexLayer = new HexagonLayer({
          id: 'hex-layer',
          data: storedData,
          getPosition: d => [Number(d.longitude), Number(d.latitude)],
          colorRange: colourRange,
          colorAggregation: 'SUM',
          pickable: false,
          coverage: 0.8,
          radius: 20000,
          extruded: false,
          material: false
        });

        const overlay = new MapboxOverlay({ interleaved: true, layers: [hexLayer] });
        map.addControl(overlay);
      });
    }
  }, [storedData]);

  return (
    <OuterWrapper bgColour={bgColour}>

      {(hasErrored) && (
        <StatusContainer>
          <h1>
            Uh-oh, something went wrong!
            <br />
            Please try again later.
          </h1>
        </StatusContainer>
      )}

      {(!hasErrored && !hasLoaded) && (
        <StatusContainer>
          <BeatLoader color="#e52630" />
        </StatusContainer>
      )}

      <MapWrapper id={thisID}>
        {/* As per license requirements */}
        {(!hasErrored && hasLoaded && showMaptilerLogo) && (
        <MapLogoWrapper className="maplogo">
          <a href="https://maptiler.com/" title="Maptiler" target="_blank" rel="noreferrer noopener">
            <img src={mapTilerLogo} alt="Maptiler" />
          </a>
        </MapLogoWrapper>
        )}
      </MapWrapper>
    </OuterWrapper>
  );
};

ParagraphMap.propTypes = {
  data: PropTypes.shape({
    contentful_id: PropTypes.string.isRequired,
    mapType: PropTypes.string.isRequired,
    backgroundColour: PropTypes.string.isRequired
  }).isRequired
};

export default ParagraphMap;
