import styled from 'styled-components';
import { spacing } from '@comicrelief/component-library';

const Container = styled.div.attrs({
  'data-test': 'search'
})`
  padding: ${spacing('md')};
  background-color: ${({ theme }) => theme.color('grey_light')};
  > div {
    max-width: 1200px;
    margin: 0 auto;
    > ul {
      padding: 0;
    }
  }

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('md')} ${spacing('xl')};
  }
`;

export default Container;
