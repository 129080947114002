/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RippedBackground from './RippedBackground';
import {
  Container,
  TornBackground,
  StyledInputs,
  Field,
  InputContainer,
  RectangleBorder1,
  RectangleBorder2,
  Label,
  Input1,
  Input2,
  ErrorMessage
} from './inputsStyles';

export default function Inputs({
  register,
  userDetails = { schoolName: '' },
  schoolNameError = '',
  teacherSurnameError = ''
}) {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (schoolNameError || teacherSurnameError) {
      setIsError(true);
    }
  }, [schoolNameError, teacherSurnameError]);

  const schoolError = 'Field must be alphanumeric and less than 50 characters, or left blank.';

  const teacherError = 'Field must be alphanumeric and less than 30 characters, or left blank.';

  return (
    <Container isError={isError}>
      <TornBackground>
        <RippedBackground />
      </TornBackground>

      <StyledInputs>

        <Field>
          <Label htmlFor="schoolName">You&apos;re starting a rebellion from</Label>
          <InputContainer>
            <RectangleBorder1>
              <Input1
                type="text"
                id="schoolName"
                name="schoolName"
                {...register('schoolName')}
                defaultValue={userDetails.schoolName}
                placeholder="Enter a school name"
              />
            </RectangleBorder1>
          </InputContainer>
        </Field>
        {schoolNameError && <ErrorMessage>{schoolError}</ErrorMessage>}

        <Field>
          <Label htmlFor="teacherSurname">Your teacher&apos;s last name is</Label>
          <InputContainer>
            <RectangleBorder2>
              <Input2
                type="text"
                id="teacherSurname"
                name="teacherSurname"
                {...register('teacherSurname')}
                placeholder="Enter a surname"
              />
            </RectangleBorder2>
          </InputContainer>
        </Field>
        {teacherSurnameError && <ErrorMessage>{teacherError}</ErrorMessage>}

      </StyledInputs>
    </Container>
  );
}

Inputs.propTypes = {
  register: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    schoolName: PropTypes.string
  }),
  schoolNameError: PropTypes.string,
  teacherSurnameError: PropTypes.string
};
