import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from '@comicrelief/component-library';

const Failure = ({ paymentFailure, url }) => {
  if (paymentFailure) {
    return (
      <div>
        <Text tag="p" size="m">
          Oops, your payment didn&apos;t go through
        </Text>
        <Text tag="p" weight="bold">
          Your transaction failed and you&apos;ve not been charged
        </Text>
        <Link href={url} target="self">
          Try again
        </Link>
      </div>
    );
  }
  return (
    <Text tag="p" size="m">
      Oops!  We are having some technical difficulties, please check back later.
    </Text>
  );
};

Failure.propTypes = {
  paymentFailure: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired
};

export default Failure;
