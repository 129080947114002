import axios from 'axios';

export const onSearch = async ({ searchTerm, current, resultsPerPage }) => {
  try {
    const { data: { data: { hits } } } = await axios.get(`${process.env.GATSBY_CONTENT_SERVICE_BASE_URL}/elasticsearch/search`, {
      params: {
        query: searchTerm,
        page: current,
        perPage: resultsPerPage
      },
      headers: {
        'x-public-access-key': process.env.GATSBY_CONTENT_SERVICE_ACCESS_KEY
      }
    });
    const results = hits.hits.map(({ _source: { contentType, fields }, _id: id }) => ({
      id: { raw: id },
      contentType,
      fields
    }));
    const totalResults = hits.total.value;
    const totalPages = Math.ceil(totalResults / resultsPerPage);
    return {
      results,
      totalResults,
      totalPages,
      resultSearchTerm: searchTerm
    };
  } catch (error) {
    Sentry.configureScope(scope => scope.setExtra('searchQuery', JSON.stringify({ searchTerm, current, resultsPerPage })));
    Sentry.captureException(error);
    throw error;
  }
};

export default {
  onSearch
};
