import styled from 'styled-components';
import { spacing } from '@comicrelief/component-library';
import handlePadding from '@comicrelief/component-library/src/utils/_utils';

const OuterWrapper = styled.div`
  padding: ${spacing('md')};
  background: ${({ theme, bgColour }) => theme.color(bgColour)};
  ${({ paddingOption }) => handlePadding(paddingOption)};

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('lg')}
    ${({ paddingOption }) => handlePadding(paddingOption)};
  }

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    padding: ${spacing('lg')}
    ${({ paddingOption }) => handlePadding(paddingOption)};
  }
`;

export default OuterWrapper;
