/*
A full list of colours can be found here:
https://github.com/comicrelief/component-library/blob/master/src/theme/crTheme/colors.js
*/

const baseTheme = {
  backgroundColour: 'blue_dark',
  buttonColour: 'red',
  textColour: 'white'
};

const THEME_KEYS = {
  DEFAULT: 'Default',
  RED: 'Red',
  DARK_VIOLET: 'Dark Violet',
  GREY_LIGHT: 'Grey Light'
};

const WIDGET_THEMES = {
  [THEME_KEYS.DEFAULT]: {
    ...baseTheme
  },
  [THEME_KEYS.RED]: {
    ...baseTheme,
    backgroundColour: 'red'
  },
  [THEME_KEYS.DARK_VIOLET]: {
    ...baseTheme,
    backgroundColour: 'deep_violet_dark'
  },
  [THEME_KEYS.GREY_LIGHT]: {
    ...baseTheme,
    backgroundColour: 'grey_light',
    textColour: 'black'
  }
};

export default function getDonateWidgetMobileTheme(themeName) {
  if (themeName && Object.keys(WIDGET_THEMES).includes(themeName)) {
    return WIDGET_THEMES[themeName];
  }

  return baseTheme;
}
