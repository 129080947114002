import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@comicrelief/component-library';

import Grid from '../../../components/Grid/Grid';
import ErrorGroupWrapper from './ErrorGroupWrapper';
import OptionCard from './OptionCard';
import * as assets from '../assets';
import { FIELDS, TARGET_OTHER } from '../constants';

// todo: Fix input prefix in component lib (for the target_pounds field).
const Target = ({
  register,
  watch,
  setValue,
  getError,
  first = '£250',
  second = '£500',
  third = '£1000',
  images = {
    goal1Image: null,
    goal2Image: null,
    goal3Image: null,
    goalOtherImage: null
  }
}) => {
  const targetText = watch(FIELDS.TARGET_TEXT);
  return (
    <ErrorGroupWrapper errorMessage={getError(FIELDS.TARGET_TEXT)}>
      <Grid>
        <OptionCard
          {...register(FIELDS.TARGET_TEXT)}
          type="radio"
          name={FIELDS.TARGET_TEXT}
          label={first}
          value={first}
          image={images.goal1Image || assets.target1Img}
        />
        <OptionCard
          {...register(FIELDS.TARGET_TEXT)}
          type="radio"
          name={FIELDS.TARGET_TEXT}
          label={second}
          value={second}
          image={images.goal2Image || assets.target2Img}
        />
        <OptionCard
          {...register(FIELDS.TARGET_TEXT)}
          type="radio"
          name={FIELDS.TARGET_TEXT}
          label={third}
          value={third}
          image={images.goal3Image || assets.target3Img}
        />
        <OptionCard
          {...register(FIELDS.TARGET_TEXT)}
          type="radio"
          name={FIELDS.TARGET_TEXT}
          label={TARGET_OTHER}
          value={TARGET_OTHER}
          image={images.goalOtherImage || assets.otherImg}
        />
      </Grid>
      {targetText === TARGET_OTHER && (
        <Grid columns={2} mobileColumns={1}>
          <Input
            {...register(FIELDS.TARGET_POUNDS)}
            type="number"
            name={FIELDS.TARGET_POUNDS}
            id={FIELDS.TARGET_POUNDS}
            label="If you have a specific fundraising goal, enter it here"
            defaultValue={0}
            step={1}
            prefix="£"
            errorMsg={getError(FIELDS.TARGET_POUNDS)}
            onBlur={(e) => {
              const val = e.target.values;
              if (typeof Number(val) === 'number') {
                const rounded = Math.round(e.target.value);
                if (val !== rounded) {
                  setValue(FIELDS.TARGET_POUNDS, rounded);
                }
              }
            }}
          />
        </Grid>
      )}
    </ErrorGroupWrapper>
  );
};

Target.propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  first: PropTypes.string,
  second: PropTypes.string,
  third: PropTypes.string,
  images: PropTypes.shape({
    goal1Image: PropTypes.string,
    goal2Image: PropTypes.string,
    goal3Image: PropTypes.string,
    goalOtherImage: PropTypes.string
  })
};

export default Target;
