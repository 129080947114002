import React from 'react';
import { Text } from '@comicrelief/component-library';

const mpTopCopy = (thisCampaign) => {
  switch (thisCampaign) {
    case 'RND25':
      return (
        <div>
          <Text tag="h3" size="xl" family="Anton" uppercase weight="400" color="grey_dark">
            DON&apos;T MISS OUT!
          </Text>

          <Text tag="p" size="md" color="grey_dark">
            By signing up, we&apos;ll send you information
            and ideas about fundraising for Red Nose Day 2025.
          </Text>

          <Text tag="p" size="md" color="grey_dark">
            But you can hear more about the work we’re funding and how your support makes
            an incredible difference in the UK and around the world by choosing some of
            the options below.
          </Text>
        </div>
      );
    default:
      return (
        <div>
          <Text tag="h3" size="xl" family="Anton" uppercase weight="400" color="grey_dark">
            DON&apos;T MISS OUT!
          </Text>

          <Text tag="p" size="md" color="grey_dark">
            Make sure you stay up-to-date with information, ideas and tips
            (including some from our celebrity chums!).
          </Text>

          <Text tag="p" size="md" color="grey_dark">
            We’d also love to send you updates on the work we’re funding
            and how your support makes an incredible difference in the UK and around the world.
          </Text>

          <Text tag="p" size="md" color="grey_dark">
            Please tick the relevant boxes below to let us know if you&apos;d like to hear from us.
          </Text>
        </div>
      );
  }
};

export default mpTopCopy;
