import styled from 'styled-components';
import {
  containers,
  spacing
} from '@comicrelief/component-library';

const Wrapper = styled.article.attrs({
  'data-test': 'prizes'
})`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color('grey_light')};
`;

const Container = styled.div`
  width: 100%;
  max-width: ${containers.large};
  margin: ${spacing('xl')} auto 0;
  padding-bottom: ${spacing('xl')};
`;

const Section = styled.section.attrs({
  'data-test': 'prizes-list'
})`
  width: 100%;
  margin: 0 auto ${spacing('l')};
  padding: 0 ${spacing('m')};
  max-width: ${containers.large};
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    padding: 0 ${spacing('xxl')};
  }
`;

const Grid = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: ${spacing('xl')};
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Items = styled.div.attrs({
  'data-test': 'prizes-item'
})`
  margin: ${spacing('md')} 0;
  width: 100%;
  text-align: left;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin: ${spacing('md')};
    /* 0.5 to fix ie11 bug */
    width: calc(100% / 3 - 2.05rem);
    a > div:first-child {
      height: 190px;
    }
  }
`;

const Title = styled.div`
  margin: ${spacing('m')} auto;
  padding-left: ${spacing('md')};
  max-width: ${containers.medium};
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin: 0 auto ${spacing('m')};
    padding-left: ${spacing('xl')};
  }
`;

const Closed = styled.div`
  img {
    filter: grayscale(1);
  }
`;

const Live = styled.div`
  a > div div {
    position: relative;
    &:before {
      ${({ ends }) => `content: "${ends}"`};
      display: flex;
      font-weight: bold;
      background: ${({ theme }) => theme.color('yellow')};
      padding: ${spacing('sm')} ${spacing('m')} ${spacing('sm')} ${spacing('l')};
      position: absolute;
      top: 0;
      right: -1px;
      border-radius: 0 14px 0 0;
      clip-path: polygon(100% 0,100% 50%,100% 100%,0% 100%,10% 50%,0% 0%);
      @media ${({ theme }) => theme.allBreakpoints('M')} {
        border-radius: 0 0 0 0;
      }
      @media ${({ theme }) => theme.allBreakpoints('L')} {
        border-radius: 0 14px 0 0;
      }
    }
  }
`;

export {
  Wrapper,
  Container,
  Grid,
  Items,
  Section,
  Title,
  Closed,
  Live
};
