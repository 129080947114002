import React from 'react';
import {
  SearchProvider, ResultsPerPage, WithSearch
} from '@elastic/react-search-ui';

import SEO from '../../components/SEO/SEO';
import Layout from '../../components/Layout/Layout';
import Hits from '../../components/Search/Hits';
import SearchBox from '../../components/Search/SearchBox';
import Stats from '../../components/Search/Stats';
import Loading from '../../components/Search/Loading';
import Container from '../../components/Search/Container';
import Pagination from '../../components/Search/Pagination';
import NoResultsMessage from '../../components/Search/NoResultsMessage';
import ErrorMessage from '../../components/Search/ErrorMessage';
import { onSearch } from '../../components/Search/actions';

const RESULTS_PER_PAGE = 10;

const Search = () => (
  <Layout>
    <SEO title="Search | Comic Relief" description="Search" pathname="search" />
    <SearchProvider
      config={{
        debug: process.env.NODE_ENV === 'development',
        initialState: {
          resultsPerPage: RESULTS_PER_PAGE
        },
        onSearch,
        trackUrlState: true,
        urlPushDebounceLength: 500,
        hasA11yNotifications: true,
        a11yNotificationMessages: {
          searchResults: ({
            start, end, totalResults, searchTerm
          }) => `Searching for "${searchTerm}". Showing ${start} to ${end} results out of ${totalResults}.`
        }
      }}
    >
      <WithSearch mapContextToProps={(context, props) => ({ ...context, ...props })}>
        {
          (context) => {
            const {
              isLoading, totalResults, wasSearched, error
            } = context;
            let content = null;
            if (error) {
              content = <ErrorMessage />;
            } else if (isLoading) {
              content = <Loading />;
            } else if (wasSearched) {
              content = (
                <Container role="alert" aria-live="polite">
                  {totalResults === 0 ? <NoResultsMessage />
                    : (
                      <>
                        <ResultsPerPage options={[RESULTS_PER_PAGE]} view={() => (<></>)} />
                        <Stats />
                        <Hits />
                        <Pagination />
                      </>
                    )}
                </Container>
              );
            }
            return (
              <>
                <SearchBox totalResults={totalResults} />
                {content}
              </>
            );
          }
        }

      </WithSearch>
    </SearchProvider>
  </Layout>
);
export default Search;
