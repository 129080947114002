import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_MARKETING_PREFERENCES_SERVICE_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 10000
});

export default process.env.GATSBY_PRIZE_FORM_TEST_MODE === 'true'
  ? mpData => console.log('Faux MP submit:', mpData) // eslint-disable-line no-console
  : mpData => axiosInstance.post('/', mpData);
