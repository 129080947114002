import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { snakeCase } from 'lodash';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import readingTime from 'reading-time';

import {
  Text, ArticleTeaser, Link, spacing, hideVisually, containers
} from '@comicrelief/component-library';
import { Internal } from '@comicrelief/component-library/dist/components/Atoms/Icons/index';
import newsDefaultImage from '../../../static/assets/news-default.jpg';

const Wrapper = styled.div`
  background: ${props => ({ theme }) => theme.color(snakeCase(props.bgColor))};
  text-align: center;
  padding: ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('xl')} ${spacing('l')};
  }
  ${({ bgColor }) => bgColor && `padding-top: ${spacing('l')};`};
`;

const Grid = styled.div`
  display: flex;
  max-width: ${containers.medium};
  justify-content: center;
  margin: ${spacing('l')} auto ${spacing('md')};
  flex-direction: column;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    flex-direction: row;
    margin-bottom: ${spacing('l')};
  }
`;

const Items = styled.div`
  margin: ${spacing('md')} 0;
  width: 100%;
  text-align: left;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin: ${spacing('md')};
    width: calc(100% / 3 - ${spacing('md')});
  }
`;

const HideText = styled.span`
  ${({ hideText }) => hideText && hideVisually};
`;

const NewsBlock = ({
  data,
  relatedArticlesRow = false,
  isPressRelease = false,
  backgroundColor = 'grey light',
  paragraphTitle = "What's going on",
  hideParagraphTitle = null,
  category = null,
  isParagraphFeaturedNews = false
}) => {
  const changeColor = background => (background && (background.includes('dark') || background.includes('red') || background.includes('deep_violet')) ? 'white' : 'black');

  let thisBackgroundColor = backgroundColor;
  let thisParagraphTitle = paragraphTitle;

  if (relatedArticlesRow === true) {
    thisBackgroundColor = 'red';
    thisParagraphTitle = isPressRelease ? 'Related press releases' : 'Related news';
  }

  const isRelatedArticles = data.length !== 0;

  const showMoreNewsButton = !isRelatedArticles
  || (!relatedArticlesRow && category === null) || isParagraphFeaturedNews;

  return (
    <Wrapper bgColor={thisBackgroundColor}>
      {thisParagraphTitle && (
        <HideText hideText={hideParagraphTitle || !isRelatedArticles}>
          <Text tag="h2" size="xxl" family="Anton" weight="normal" uppercase color={changeColor(snakeCase(thisBackgroundColor))}>
            {thisParagraphTitle}
          </Text>
        </HideText>
      )}
      {isRelatedArticles
      && (
        <Grid>
          {data.map((article) => {
            const {
              title, path, image, displayDate, id, type, body
            } = article.node;

            const content = body && documentToPlainTextString(body.raw);
            const fullPath = type === 'Press Release' ? `/press-releases/${path}` : `/news/${path}`;
            const fallback = image ? image.fallback.src : newsDefaultImage;
            const images = image ? image.fluid.srcSet : newsDefaultImage;
            const imageLow = image ? image.placeHolder.src : newsDefaultImage;
            const description = image ? image.description : '';
            const time = content && readingTime(content);

            return (
              <Items key={id}>
                <ArticleTeaser
                  href={fullPath}
                  image={fallback}
                  images={images}
                  imageLow={imageLow}
                  date={displayDate}
                  title={title}
                  time={time && time.text}
                  alt={description}
                />
              </Items>
            );
          })}
        </Grid>
      )}
      {showMoreNewsButton && (
        <Link href="/news" type="button" color={isRelatedArticles ? 'red' : 'white'} icon={<Internal colour="white" />}>
          See more
        </Link>
      )}
    </Wrapper>
  );
};

export default NewsBlock;

NewsBlock.propTypes = {
  data: PropTypes.oneOfType([PropTypes.shape, PropTypes.array]).isRequired,
  relatedArticlesRow: PropTypes.bool,
  isPressRelease: PropTypes.bool,
  backgroundColor: PropTypes.string,
  paragraphTitle: PropTypes.string,
  hideParagraphTitle: PropTypes.string,
  category: PropTypes.string,
  isParagraphFeaturedNews: PropTypes.bool
};
