import React from 'react';
import PropTypes from 'prop-types';
import {
  Internal, External, AtSign, Download
} from '@comicrelief/component-library/dist/components/Atoms/Icons/index';

import isInternalUrl from '../../utils/isInternalUrl';

const showDownloadIcon = ({ url = '', label = '' }) => {
  if (url.toLowerCase().includes('cfassets.net')) {
    return true;
  }

  if (label.toLowerCase().trim().startsWith('download')) {
    return true;
  }

  const fileExtensions = [
    'jpg',
    'jpeg',
    'png',
    'pdf',
    'csv',
    'zip',
    'mov',
    'mp4',
    'xlsx',
    'docx',
    'doc'
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const extension of fileExtensions) {
    if (url.toLowerCase().trim().endsWith(`.${extension}`)) {
      return true;
    }
  }

  return false;
};

const CtaLinkIcon = ({ url, label, ...rest }) => {
  if (typeof url !== 'string' || typeof label !== 'string') {
    return null;
  }

  let IconComponent;

  if (isInternalUrl(url)) {
    IconComponent = Internal;
  } else if (url.toLowerCase().trim().startsWith('mailto:')) {
    IconComponent = AtSign;
  } else if (showDownloadIcon({ url, label })) {
    IconComponent = Download;
  } else {
    IconComponent = External;
  }

  return <IconComponent {...rest} />;
};

CtaLinkIcon.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string
};

export default CtaLinkIcon;
