import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import Star1 from './Star1';
import Star2 from './Star2';
import { SwivelJiggle2 } from '../sharedStyles';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12%;
  height: 100%;
`;

const ArrowWrapper = styled.button`
  width: 80px;
  transform: rotate(-1deg);
  ${SwivelJiggle2}
  border: none;
  background: transparent;
  padding: 0;
`;

const StarWrapper = styled.div`
  align-self: ${props => (props.positionLeft ? 'start' : 'end')};
`;

export default function Column({
  step,
  setStep,
  setShowPopUp,
  positionLeft,
  challengeNumber,
  setChallengeNumber,
  challenges
}) {
  const handleClickRight = () => {
    // Left
    if (positionLeft) {
      if (challengeNumber === 0) {
        setStep(1);
      } else {
        setChallengeNumber(challengeNumber - 1);
      }
    }

    // Right
    if (!positionLeft) {
      if (challengeNumber === challenges.length - 1) {
        setShowPopUp(true);
      } else {
        setChallengeNumber(challengeNumber + 1);
      }
    }
  };

  return (
    <Container>
      {step === 2
        ? (
          <ArrowWrapper type="button" onClick={handleClickRight}>
            {positionLeft ? <ArrowLeft /> : <ArrowRight />}
          </ArrowWrapper>
        )
        : (
          <StarWrapper positionLeft={positionLeft}>
            {positionLeft ? <Star1 /> : <Star2 />}
          </StarWrapper>
        )}
    </Container>
  );
}

Column.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  positionLeft: PropTypes.bool.isRequired,
  challengeNumber: PropTypes.number.isRequired,
  setChallengeNumber: PropTypes.func.isRequired,
  setShowPopUp: PropTypes.func.isRequired,
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      video: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    })
  ).isRequired
};
