import {
  SchoolJobTitleEnum as SchoolJobTitle,
  OrganizationSizeEnum as OrganizationSize
} from '@comicrelief/data-models/enums';

import {
  Professions
} from '@comicrelief/data-models/schemas/fundraiser-signup/models/professions';

export const INDUSTRIES = Object.values(Professions)
  .map(industry => ({
    value: industry,
    displayValue: industry
  }));

export const SCHOOL_JOB_TITLES = Object.values(SchoolJobTitle)
  .map(jobTitle => ({
    value: jobTitle,
    displayValue: jobTitle
  }));

export const ORG_SIZE = Object.values(OrganizationSize)
  .map(size => ({
    value: size,
    displayValue: size
  }));

export const NOT_SURE_YET = 'not_sure_yet';

export const FUNDRAISING_GROUPS = {
  SCHOOL: 'school',
  WORK: 'work',
  PUBLIC: 'public'
};

// Maps the 'default fundraiser group' value coming
// out of the CMS to the real groups above:
export const DEFAULT_FR_GROUPS = {
  SCHOOL_NURSERY_OR_YOUTH_GROUP: FUNDRAISING_GROUPS.SCHOOL,
  WORKPLACE: FUNDRAISING_GROUPS.WORK,
  MYSELF_OR_A_GROUP: FUNDRAISING_GROUPS.PUBLIC
};

export const SCHOOL_SEGMENTS = {
  NURSERY: {
    value: 'nursery',
    displayValue: 'Nursery / early years setting'
  },
  PRIMARY: {
    value: 'primary_school',
    displayValue: 'Primary school'
  },
  SECONDARY: {
    value: 'secondary_school',
    displayValue: 'Secondary school'
  },
  COLLEGE: {
    value: 'sixth_form_college',
    displayValue: 'Sixth form / college'
  },
  YOUTH: {
    value: 'youth_group',
    displayValue: 'Youth group'
  },
  OTHER: {
    value: 'school_other',
    displayValue: 'Other'
  }
};

export const SCHOOL_FSU_SCHOOL_SEGMENTS = {
  PRIMARY: {
    value: 'primary_school',
    displayValue: 'Primary School'
  },
  SECONDARY: {
    value: 'secondary_school',
    displayValue: 'Secondary School'
  },
  NURSERY: {
    value: 'nursery',
    displayValue: 'Early Years'
  },
  PARENT_OR_CARER: {
    value: 'school_parent_or_carer',
    displayValue: 'Parent / carer'
  },
  YOUTH: {
    value: 'youth_group',
    displayValue: 'Youth Group'
  },
  SPORTS_CLUB: {
    value: 'school_sports_club',
    displayValue: 'Sports Club'
  },
  OTHER: {
    value: 'school_other',
    displayValue: 'Other'
  }
};

export const PUBLIC_SEGMENTS = {
  INDIVIDUAL: {
    value: 'individual',
    displayValue: 'By myself'
  },
  FRIENDS_OR_FAMILY: {
    value: 'friends_or_family',
    displayValue: 'Friends and family'
  },
  UNIVERSITY: {
    value: 'university',
    displayValue: 'University'
  },
  COMMUNITY_GROUP: {
    value: 'community_group',
    displayValue: 'Community group'
  },
  RELIGIOUS_GROUP: {
    value: 'religious_group',
    displayValue: 'Religious group'
  },
  SPORTS_CLUB: {
    value: 'sports_club',
    displayValue: 'Sports club'
  },
  OTHER: {
    value: 'public_other',
    displayValue: 'Other'
  }
};

// Now added just added via submitHandler():
// export const TOOLS = {
//   JUSTGIVING: 'justgiving_page_setup',
//   PACK: 'fundraising_pack'
// };

export const PRINTED_FUNDRAISING_PACK = {
  YES: {
    value: 'yes',
    displayValue: 'Yes, please.'
  },
  NO: {
    value: 'no',
    displayValue: 'No, thank you. I’m happy using digital resources and printing anything I need.'
  }
};

export const TARGET_OTHER = 'Other';

export const FIELDS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  GROUP: 'fundraising_group',
  SEGMENT: 'fundraising_segment',
  ORG_NAME: 'org_name',
  INDUSTRY_TYPE: 'industry_type',
  ORG_SIZE: 'org_size',
  JOB: 'job_title',
  LINE1: 'address_line_1',
  LINE2: 'address_line_2',
  LINE3: 'address_line_3',
  TOWN: 'town',
  POSTCODE: 'postcode',
  FUNDRAISING_PACK: 'fundraising_pack',
  TARGET_TEXT: 'fundraising_target_text',
  TARGET_POUNDS: 'fundraising_target_pounds',
  OVER_EIGHTEEN: 'over_eighteen',
  CONFIRM_EMAIL: 'confirm_email',
  PHONE: 'phone',
  TSHIRT_SIZE: 'tshirt_size',
  PAGE_TITLE: 'jg_page_title',
  PAGE_STORY: 'jg_page_story',
  PRIZE_DRAW_OPT_IN: 'prize_draw_opt_in',
  // MP fields; matching the names supplied by the MP component itself
  MP_EMAIL: 'mp_email',
  MP_LINE1: 'mp_address1',
  MP_LINE2: 'mp_address2',
  MP_LINE3: 'mp_address3',
  MP_TOWN: 'mp_town',
  MP_POSTCODE: 'mp_postcode',
  MP_COUNTRY: 'mp_country',
  MP_MOBILE: 'mp_mobile',
  MP_PHONE: 'mp_phone',
  MP_PERM_EMAIL: 'mp_permissionEmail',
  MP_PERM_PHONE: 'mp_permissionPhone',
  MP_PERM_SMS: 'mp_permissionSMS',
  MP_PERM_POST: 'mp_permissionPost'
};

export const FIELDS_DEFAULT_VALUES = {
  [FIELDS.FUNDRAISING_PACK]: PRINTED_FUNDRAISING_PACK.NO.value,
  [FIELDS.PRIZE_DRAW_OPT_IN]: false
};

export const TSHIRT_SIZES = {
  S: {
    value: 'S',
    displayValue: 'Small'
  },
  M: {
    value: 'M',
    displayValue: 'Medium'
  },
  L: {
    value: 'L',
    displayValue: 'Large'
  },
  XL: {
    value: 'XL',
    displayValue: 'X-Large'
  },
  NONE: {
    value: 'none',
    displayValue: 'No T-shirt for me'
  }
};

// Given that we're no longer rendering anything JG-based for Schools journeys
// - due to required sign-off, option paralysis-based drop-offs -
// there are now a lot simpler, and can be set per campaign:
export const JUSTGIVING_STORIES = {
  RND24: `Red Nose Day is back on Friday 15 March and I'm joining people across the nation to do something funny for money.
    
However much you can spare, your donation will go to Comic Relief to help people facing poverty in our communities, here in the UK and around the world.
      
Don't forget to tick the Gift Aid option, if you can, so Comic Relief can claim an extra 25% from the government.
    
From us and everyone at Comic Relief, thank you. You're wonderful.`,

  // Not actually used (no JG integration as Schools-only, but including for completeness 🤷)
  SR24: 'unused',
  // No JG-integration in the 365 'CR'-coded FSU also, but supplying here for EVEN more completeness
  CR: 'unused',

  RND25: `Red Nose Day is back on Friday 21 March and we're joining people across the nation to do something funny for money. Over the past 40 years of Comic Relief more than 100 million people have been helped because when we all give a little, then big, beautiful change happens.

Your donation goes to help people facing poverty in our communities, here in the UK and around the world.

Don't forget to tick the Gift Aid option, if you can, so Comic Relief can claim an extra 25% from the government.

Thank you. You're wonderful.`
};

// Used by JG integration and fields:
export const CAMPAIGN_STRING = (campaignCode) => {
  switch (campaignCode) {
    case 'SR24':
      return 'Sport Relief 2024';
    case 'RND24':
      return 'Red Nose Day 2024';
    case 'RND25':
      return 'Red Nose Day 2025';
    default:
      return false;
  }
};

// Maps the user-understandable (and unique) 'Who to show the FR pack option to' CMS display
// values to our internal naming, so we can carry out the necessary comparison logic easily:
export const PACK_SEGMENTS = {
  // SCHOOL
  nursery_early_years: SCHOOL_SEGMENTS.NURSERY.value,
  primary_school: SCHOOL_SEGMENTS.PRIMARY.value,
  secondary_school: SCHOOL_SEGMENTS.SECONDARY.value,
  sixth_form_college: SCHOOL_SEGMENTS.COLLEGE.value,
  youth_group: SCHOOL_SEGMENTS.YOUTH.value,
  other_educational_setting: SCHOOL_SEGMENTS.OTHER.value,
  // WORK
  workplace: FUNDRAISING_GROUPS.WORK,
  // PUBLIC
  by_myself: PUBLIC_SEGMENTS.INDIVIDUAL.value,
  friends_and_family: PUBLIC_SEGMENTS.FRIENDS_OR_FAMILY.value,
  university: PUBLIC_SEGMENTS.UNIVERSITY.value,
  community_group: PUBLIC_SEGMENTS.COMMUNITY_GROUP.value,
  religious_group: PUBLIC_SEGMENTS.RELIGIOUS_GROUP.value,
  sports_club: PUBLIC_SEGMENTS.SPORTS_CLUB.value,
  other_group: PUBLIC_SEGMENTS.OTHER.value
};
