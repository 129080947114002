import {
  getsWhiteText, getsRedBtn, getsWhiteBtn, getsWhiteIcon
} from './colourRules';

export const changeTextColor = (background) => {
  const bgColourName = background ? background.toLowerCase() : null;
  return bgColourName && getsWhiteText.includes(bgColourName)
    ? 'white'
    : 'black';
};

// Check the button background colour to determine what colour icon it'll get:
export const changeIconColor = (buttonBackground) => {
  const btnColourName = buttonBackground ? buttonBackground.toLowerCase() : null;
  return buttonBackground && getsWhiteIcon.includes(btnColourName)
    ? 'white'
    : 'black';
};

export const changeButtonColor = (background) => {
  // If a bg colour is supplied, convert to lowercase, else make it null
  const backgroundColourName = background ? background.toLowerCase() : null;

  // If supplied bg colour's name includes 'dark', but isn't in our 'coloursToCheckAgainst' array:
  if (backgroundColourName && (getsWhiteBtn.includes(backgroundColourName))) {
    return 'white';
  }
  // If supplied bg colour's name includes 'white' or IS in our 'coloursToCheckAgainst' array:
  if (backgroundColourName && getsRedBtn.includes(backgroundColourName)) {
    return 'red';
  }
  // Our default:
  return 'black';
};

// To ensure the inline links match the surrounding copy, we're using the same
// array, and just setting the appropriate Link 'type' accordingly:
export const changeLinkColor = (background) => {
  // If a bg colour is supplied, convert to lowercase, else make it null
  const backgroundColourName = background ? background.toLowerCase() : null;
  return backgroundColourName && getsWhiteText.includes(backgroundColourName)
    ? 'standard_white'
    : 'standard';
};
