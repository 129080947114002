import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, navigate } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';
import { Select, spacing } from '@comicrelief/component-library';

const Wrapper = styled.div`
    margin: calc(${spacing('l')} + ${spacing('md')}) auto 0;
    padding: ${spacing('l')};
    width: calc(100% - ${spacing('xl')});
    background: ${({ theme }) => theme.color('white')};
    span:first-of-type {
      display: block;
      margin-bottom: ${spacing('md')};
    }
     @media ${({ theme }) => theme.allBreakpoints('M')} {
      span:first-of-type {
        margin: 0 ${spacing('md')} 0 0;
      }
      span:first-of-type, select  {
        display: inline-block;
        width: auto;
      }
     }
     @media ${({ theme }) => theme.allBreakpoints('L')} {
      width: 50%;
      label {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      }
     }
`;

export default props => (
  <StaticQuery
    query={graphql`
    {
      allContentfulPageArticle {
        edges {
          node {
            categories {
              title
            }
          }
        }
      }
     }
    `}
    render={data => <NewsFilter data={data} {...props} />}
  />
);

const updateCategory = (value) => {
  if (value === 'All') {
    navigate('/news');
  } else {
    navigate(`/news/${kebabCase(value)}`);
  }
};

const getCategoryList = (categories, selectedCategory) => {
  const category = selectedCategory !== undefined ? selectedCategory : 'All';
  const list = [{ value: 'All', displayValue: 'All' }];
  categories.map((item) => {
    const selected = item === category;
    return list.push({ value: item, displayValue: item, selected });
  });

  return list;
};

const NewsFilter = ({ data, selectedCategory }) => {
  // Get posts with non empty categories Array
  // Return flat array of those categories' titles
  const categorieswithPosts = [];
  data.allContentfulPageArticle.edges
    .filter(post => post.node.categories && post.node.categories.length > 0)
    .map(post => categorieswithPosts.push(...post.node.categories.map(category => category.title)));
  // Remove duplicates from categories array by converting it to Set then back to array
  const categories = getCategoryList(
    Array.from(new Set(categorieswithPosts)), selectedCategory
  );

  return (
    <Wrapper>
      <Select
        label="FILTER BY CATEGORY:"
        options={categories}
        value={selectedCategory}
        description="Please select a category"
        errorMsg={null}
        onChange={(event) => { updateCategory(event.target.value); }}
      />
    </Wrapper>
  );
};

NewsFilter.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  data: PropTypes.shape({
    allContentfulPageArticle: PropTypes.shape({
      edges: PropTypes.shape([])
    })
  }).isRequired
};
