import { keyframes } from 'styled-components';

const pendulum = keyframes`
  0%   { transform: rotate(0deg) }
  23%   { transform: rotate(9deg) }
  30%   { transform: rotate(9deg) }
  50%   { transform: rotate(0deg) }
  73%   { transform: rotate(-9deg) }
  80%   { transform: rotate(-9deg) }
  100%   { transform: rotate(0deg) }
`;

const jiggleSwell = keyframes`
  0%   {
    transform: translate(-50%, -50%)
    scale(1, 1)
    rotate(0deg)
  }
  15%   {
    transform: translate(-50%, -50%)
    scale(1.03, 1.03)
    rotate(-20deg)
  }
  30%   {
    transform: translate(-50%, -50%)
    scale(1, 1)
    rotate(0deg)
  }
  45%   {
    transform: translate(-50%, -50%)
    scale(1.03, 1.03)
    rotate(-10deg)
  }
  95%   {
    transform: translate(-50%, -50%)
    scale(1.03, 1.03)
    rotate(-10deg)
  }
`;

const rotatePulse = keyframes`
  0%   {
    transform: scale(1)
  }
  40%   {
    transform: scale(1.08)
    rotate(0.5deg)
  }
  80%   {
    transform: scale(1.04)
    rotate(1deg)
  }
  100%   {
    transform: scale(1.05)
    rotate(1deg)
  }
`;

const rotatePulse2 = keyframes`
  0%   {
    transform: scale(1)
  }
  30%   {
    transform: scale(1.18)
    rotate(2.5deg)
  }
  70%   {
    transform: scale(1.14)
    rotate(4deg)
  }
  100%   {
    transform: scale(1.16)
    rotate(5deg)
  }
`;

export {
  pendulum,
  jiggleSwell,
  rotatePulse,
  rotatePulse2
};
