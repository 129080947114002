import React from 'react';
import {
  CardDs, Text, spacing, Link
} from '@comicrelief/component-library';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Anton = props => <Text family="Anton" size="xl" weight="normal" uppercase {...props} />;
const P = props => <Text family="Montserrat" tag="p" size="s" {...props} />;

export const Notice = styled(CardDs)`
  width: 100%;
  max-width: calc(1200px - 2rem);
  border-radius: 1rem;
  margin: 0 auto ${spacing('m')};

  > div {
    width: 100%;
    box-shadow: none;

    ${({ showReducedCopy }) => (showReducedCopy) && css`
      padding: 0;
    `}
  }
`;

const DataProtectionNotice = (props) => {
  const { hasPhoneField = false, showReducedCopy } = props;
  return (
    <Notice showReducedCopy={showReducedCopy}>

      {!showReducedCopy ? (
        <>
          <Anton size="m">Your data</Anton>
          <Anton tag="h4" css={{ marginTop: 0 }}>
            Why do we collect your
            {' '}
            <Anton color="red">information</Anton>
            ?
          </Anton>
          <P weight="bold">Name, surname, email and address</P>
          <P>
            We need these to process your registration,
            to identify if you&apos;ve fundraised with us before
            and to make sure you have all the information you need.
          </P>
          <P weight="bold">Establishment information</P>
          <P>
            We use this information to better understand which
            institutions(schools, companies, etc.)
            raise money for us.
          </P>
          { hasPhoneField && (
          <>
            <P weight="bold">Phone number</P>
            <P>
              This is optional and we will only use this number to contact you
              regarding your fundraising to offer additional support.
            </P>
          </>
          )}
        </>
      ) : (
        <>
          <P>
            You can read about how we use your information
            {' '}
            <Link
              color="red"
              href="https://www.comicrelief.com/privacy-notice/"
              target="blank"
            >
              here
            </Link>
            .
          </P>
        </>
      )}

    </Notice>
  );
};

DataProtectionNotice.propTypes = {
  hasPhoneField: PropTypes.bool,
  showReducedCopy: PropTypes.bool
};

export default DataProtectionNotice;
