import React from 'react';

export default function Pointer() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 212 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_381_48295)">
        <path d="M123.318 9.09627C125.417 7.05767 142.796 19.3236 206.104 77.8107C174.304 77.2258 142.504 76.6452 110.699 76.0602C121.21 71.2519 133.795 63.3126 136.943 50.6294C141.859 30.8499 120.862 11.4789 123.313 9.09627L123.318 9.09627Z" fill="white" />
        <path d="M1.63397 26.6179C17.3707 23.3621 40.406 20.4849 67.8281 23.6976C102.106 27.7146 128.19 39.4774 143.759 48.036C136.512 32.5702 129.265 17.1001 122.019 1.63433C151.441 27.0523 180.863 52.4702 210.28 77.8881C174.91 77.2387 139.54 76.5893 104.17 75.9398C115.094 67.5016 126.018 59.0677 136.942 50.6294C124.93 47.4339 111.929 44.4405 98.0027 41.8686C82.4681 39 67.8023 37.0259 54.1944 35.7012" stroke="#191B1D" strokeWidth="3.3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_381_48295">
          <rect width="79.5224" height="211.915" fill="white" transform="translate(211.915) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  );
}
