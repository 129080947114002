import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';

import SEO from '../../components/SEO/SEO';
import Layout from '../../components/Layout/Layout';
import Paragraphs from '../../components/Paragraphs/Paragraphs';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color('grey_light')};
  display: flex;
  flex-direction: column;
`;

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.partner.edges[0].node');
    const partners = get(this.props, 'data.partners.edges');
    const { edges: moneyBuys } = get(this.props, 'data.allContentfulCart');
    const {
      name, path, summary, logo, paragraphs
    } = post;
    const seoImage = logo?.seoImage?.src;
    const seoSummary = summary !== null && typeof summary.summary !== 'undefined' ? summary.summary : '';

    return (
      <Layout>
        <SEO title={`${name} | Comic Relief`} description={seoSummary} pathname={path} image={seoImage} />
        <Wrapper data-test="partner">
          {paragraphs
            && (
            <Paragraphs
              paragraphs={paragraphs}
              partners={partners}
              moneyBuys={moneyBuys}
            />
            )}
        </Wrapper>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query getPartner($slug: String, $partnerCategory: [String]) {
    partner: allContentfulPagePartner(filter: { path: { eq: $slug } }) {
      edges {
        node {
          ...PartnerList
        }
      }
    }

    partners: allContentfulPagePartner(sort: { fields: weight, order: ASC }, filter: {group: {title: {in: $partnerCategory}}}) {
      edges {
        node {
          ...PartnerList
        }
      }
    }

    allContentfulCart {
      edges {
        node {
          cartId
          singleGiving {
            mobileImage {
              ...Picture
            }
            moneybuys {
              description
              value
            }
          }
          regularGiving {
            moneybuys {
              description
              value
            }
          }
        }
      }
    }
  }
`;
