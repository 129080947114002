import React from 'react';

export default function Star1() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 187 193" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_563_4051)">
        <path d="M81.8306 84.882L105.391 23.5131L101.471 74.8405L148.175 48.4279L108.894 88.3724H152.538L106.277 108.338L120.238 169.551L89.684 113.574L81.8306 174.344L75.7223 118.041L30.3338 139.27L62.6202 103.636L21.5947 93.1652L59.5726 88.3724L42.5503 27.3682L81.8306 84.882Z" fill="white" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M133.771 73.0952C141.924 72.3659 150.063 71.6365 158.216 70.9072C156.901 66.974 155.599 63.0537 154.283 59.1205C164.755 55.9166 175.239 52.7257 185.71 49.5219" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M79.6419 52.1397C79.0558 46.9041 78.4828 41.6684 77.8967 36.4328C72.2183 35.5602 66.5528 34.6876 60.8744 33.815C58.5431 23.0442 56.2248 12.2864 53.8936 1.51556" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M119.795 114.993C129.836 116.009 139.865 117.025 149.906 118.054C149.32 126.35 148.747 134.633 148.161 142.93C158.489 146.277 168.817 149.624 179.145 152.971" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M62.6218 140.742C58.8448 152.229 55.0549 163.729 51.2779 175.216C46.3288 174.2 41.3797 173.184 36.4436 172.155C34.2556 178.407 32.0806 184.672 29.8926 190.923" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M39.4906 81.3785C32.653 77.7448 25.8154 74.0981 18.9778 70.4644C21.5956 67.5601 24.2135 64.6427 26.8313 61.7384C18.3918 57.2321 9.95222 52.7128 1.5127 48.2065" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_563_4051">
          <rect width="186.777" height="192.025" fill="white" transform="translate(0.223633 0.213196)" />
        </clipPath>
      </defs>
    </svg>
  );
}
