import React from 'react';
import PropTypes from 'prop-types';
import { RichtextCarousel as RichtextCarouselCL } from '@comicrelief/component-library';
import { snakeCase } from 'lodash';
import OuterWrapper from './RichtextCarousel.style';
import renderBody from '../RichText/RichText';
import formatNodes from './_utils';

const RichtextCarousel = ({
  data, data: {
    headingCopy, nodes, mobileHeight, tabletHeight, desktopHeight,
    paddingOptions, autoPlay, rowBackgroundColour, carouselBackgroundColour, nodeBackgroundColour,
    nodeOutlineColour
  }
}) => {
  // Prepare the data for the component:
  const thisPaddingOption = paddingOptions ? snakeCase(paddingOptions) : null;
  const thisRowBgColour = rowBackgroundColour ? snakeCase(rowBackgroundColour) : null;
  const thisCarouselBg = carouselBackgroundColour ? snakeCase(carouselBackgroundColour) : null;
  const thiseNodeBgColour = nodeBackgroundColour ? snakeCase(nodeBackgroundColour) : null;
  const formattedNodes = formatNodes(nodes, thiseNodeBgColour);

  const formattedData = {
    autoPlay,
    carouselBackgroundColour: thisCarouselBg,
    contentful_id: data.contentful_id,
    headingCopy: renderBody(headingCopy, thisCarouselBg),
    nodeBackgroundColour: thiseNodeBgColour,
    nodeOutlineColour: rowBackgroundColour ? snakeCase(nodeOutlineColour) : null,
    // Only pass these optional fields when set:
    ...(mobileHeight && { mobileHeight }),
    ...(tabletHeight && { tabletHeight }),
    ...(desktopHeight && { desktopHeight }),
    // Our actual content:
    nodes: formattedNodes
  };

  return (
    <OuterWrapper paddingOption={thisPaddingOption} rowBackgroundColour={thisRowBgColour}>
      <RichtextCarouselCL data={formattedData} />
    </OuterWrapper>
  );
};
RichtextCarousel.propTypes = {
  data: PropTypes.shape({
    headingCopy: PropTypes.string.isRequired,
    contentful_id: PropTypes.string.isRequired,
    nodes: PropTypes.arrayOf(PropTypes.shape({
      copy: PropTypes.string.isRequired
    })).isRequired,
    autoPlay: PropTypes.bool.isRequired,
    mobileHeight: PropTypes.number.isRequired,
    tabletHeight: PropTypes.number.isRequired,
    desktopHeight: PropTypes.number.isRequired,
    backgroundColour: PropTypes.string.isRequired,
    // Handled in this parent component, not the CL component itself:
    paddingOptions: PropTypes.string.isRequired,
    rowBackgroundColour: PropTypes.string.isRequired,
    carouselBackgroundColour: PropTypes.string.isRequired,
    nodeBackgroundColour: PropTypes.string.isRequired,
    nodeOutlineColour: PropTypes.string.isRequired
  }).isRequired
};

export default RichtextCarousel;
