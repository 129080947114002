import React from 'react';
import { PagingInfo } from '@elastic/react-search-ui';
import styled from 'styled-components';
import { Text, spacing } from '@comicrelief/component-library';

const Wrapper = styled(Text)`
  padding: ${spacing('sm')} 0;
  width: 100%;
  p {
    font-size: ${({ theme }) => theme.fontSize('xs')};
  }
  span {
    display: flex;
    flex-direction: column;
  }
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    p {
      font-size: ${({ theme }) => theme.fontSize('m')};
    }
    span {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

const Stats = () => (
  <PagingInfo view={({
    end,
    start,
    totalResults
  }) => {
    if (totalResults === 1) {
      return <Wrapper tag="div"><Text color="deep_violet">{`We found ${totalResults} result!`}</Text></Wrapper>;
    }
    return (
      <Wrapper tag="div">
        <span>
          <Text tag="p" color="deep_violet">
            {`We found ${totalResults} results!`}
          </Text>
          <Text tag="p" color="deep_violet">
            {`Displaying ${start} - ${end}`}
          </Text>
        </span>
      </Wrapper>
    );
  }}
  />
);

export default Stats;
