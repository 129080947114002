import styled from 'styled-components';
import { HeadingFont, SwivelJiggle } from '../sharedStyles';

const Container = styled.div`
  margin: 70px 0;
  position: relative;
  font-size: 1.8rem;
  width: 100%;
  max-width: 950px;
  height: 200px;
  height: ${props => (props.isError ? '250px' : '200px')};
`;

const TornBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

const StyledInputs = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  z-index: 1;
`;

const Field = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const InputContainer = styled.div`
  height: 100%;
  background: white;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RectangleBorder1 = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  transition: 0.2s;
  transform: rotate(-1deg);
  ${SwivelJiggle}
`;

const RectangleBorder2 = styled(RectangleBorder1)`
  transform: rotate(1deg);
  ${SwivelJiggle}
`;

const Label = styled.div`
  ${HeadingFont}
`;

const Input1 = styled.input`
  height: 100%;
  padding: 9px;
  font-size: 1.2rem;
  text-align: center;
  ::placeholder {
    color: ${({ theme }) => theme.color('grey')};
  }
  border: none;
  background: transparent;
  transform: rotate(1deg);
  outline: none;
`;

const Input2 = styled(Input1)`
  transform: rotate(-1deg);
`;

const ErrorMessage = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.color('red')};
`;

export {
  Container,
  TornBackground,
  StyledInputs,
  Field,
  InputContainer,
  RectangleBorder1,
  RectangleBorder2,
  Label,
  Input1,
  Input2,
  ErrorMessage
};
