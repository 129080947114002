import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';
import {
  Text, ArticleTeaser, Pagination, spacing, containers
} from '@comicrelief/component-library';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import NewsFilter from '../../components/NewsFilter/NewsFilter';
import seoDefaultImage from '../../../static/assets/news-default.jpg';

const Wrapper = styled.article.attrs({
  'data-test': 'news'
})`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color('grey_light')};
`;

const Container = styled.div`
  width: 100%;
  max-width: ${containers.large};
  margin: 0 auto;
  padding-bottom: ${spacing('xl')};
`;

const Header = styled.header`
  text-align: center;
  padding: ${spacing('l')};
  padding: calc(${spacing('xxl')} - ${spacing('l')}) ${spacing('l')};
  background: ${({ theme }) => theme.color('teal_dark')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: calc(${spacing('xxl')} - ${spacing('l')}) ${spacing('l')};
  }
`;

const Section = styled.section.attrs({
  'data-test': 'news-list'
})`
  width: 100%;
  display: flex;
  margin-bottom: ${spacing('m')};
  text-align: center;
  flex-wrap: wrap;
`;

const Grid = styled.div`
  display: flex;
  max-width: ${containers.medium};
  width: 100%;
  justify-content: center;
  margin: 0 auto ${spacing('l')};
  padding: ${spacing('l')};
  flex-direction: column;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Items = styled.div.attrs({
  'data-test': 'news-item'
})`
  margin: ${spacing('md')} 0;
  width: 100%;
  text-align: left;
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin: ${spacing('md')};
    /* 0.5 to fix ie11 bug */
    width: calc(100% / 3 - 2.05rem);
    a > div:first-child {
      height: 190px;
    }
  }
`;

const handleSelect = () => {
  window.scrollTo({ top: 100, behavior: 'smooth' });
};

const handlePaginationUrl = (page, category) => {
  const categoryPath = kebabCase(category);
  if (page === 1) {
    return `/news/${categoryPath}`;
  }
  return `/news/${categoryPath}/page-${page}`;
};

const Categories = (props) => {
  const articles = get(props, 'data.allContentfulPageArticle.edges');
  const pageInfo = get(props, 'data.allContentfulPageArticle.pageInfo');
  const category = get(props, 'pageContext.category');

  const { title: currentPageTitle, path: currentPagePath, summary } = get(
    props,
    'data.allContentfulPageLandingPage.edges[0].node'
  );

  return (
    <Layout>
      <SEO title={`${currentPageTitle} | Comic Relief`} pathname={currentPagePath} description={summary}>
        {({ siteUrl }) => (
          <script type="application/ld+json" key="carousel">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'ItemList',
              itemListElement: articles.map((article, position) => ({
                '@type': 'ListItem',
                position,
                url: `${siteUrl}news/${article.node.path}`
              }))
            })}
          </script>
        )}
      </SEO>
      <Wrapper>
        <Header>
          <Text tag="h1" size="super" color="white" weight="normal" family="Anton">
            News
          </Text>
        </Header>
        <Container>
          <Section>
            <NewsFilter
              selectedCategory={category}
            />
            <Grid>
              {articles.map(({ node }) => {
                const {
                  image, path, displayDate, title, id
                } = node;
                const fallback = image ? image.fallback?.src : seoDefaultImage;
                const images = image ? image.fluid?.srcSet : seoDefaultImage;
                const imageLow = image ? image.placeHolder?.src : seoDefaultImage;
                const description = image ? image.description : '';
                const URL = `/news/${path}`;
                return (
                  <Items key={id}>
                    <ArticleTeaser
                      href={URL}
                      image={fallback}
                      images={images}
                      imageLow={imageLow}
                      date={displayDate}
                      title={title}
                      alt={description}
                    />
                  </Items>
                );
              })}
            </Grid>
          </Section>
          {pageInfo.pageCount > 1 ? (
            <div data-test="news-pagination">
              <Pagination
                disabledBackgroundColor="grey_dark"
                disabledColor="grey_light"
                maxPages={10}
                totalPages={pageInfo.pageCount}
                currentPage={pageInfo.currentPage}
                createURL={value => handlePaginationUrl(value, category)}
                onSelect={handleSelect}
                showFirst
                showPrevious={false}
                showNext={false}
                showLast
              />
            </div>
          ) : null}
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default Categories;

export const pageQuery = graphql`
  query getCategoryArticles($skip: Int, $limit: Int, $category: String) {
    allContentfulPageArticle(
      skip: $skip
      limit: $limit
      filter: {
        type: { eq: "News Article" },
        categories: {
          elemMatch: {
            title: {
              eq: $category
            }
          }
        }
      }
      sort: { fields: [displayDate], order: DESC }
    ) {
      totalCount
      pageInfo {
        perPage
        currentPage
        hasPreviousPage
        hasNextPage
        itemCount
        pageCount
      }
      edges {
        node {
          title
          path
          type
          id
          categories {
            title
          }
          displayDate(formatString: "Do MMMM YYYY")
          image {
            ...Picture
          }
        }
      }
    }
    allContentfulPageLandingPage(filter: { path: { eq: "news-seo" } }) {
      edges {
        node {
          path
          title
          summary {
            summary
          }
        }
      }
    }
  }
`;
