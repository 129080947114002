import React from 'react';

export default function DrawnCircle() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 412 406" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M175.65 13.5071C163.06 13.5071 16.2916 34.4348 1.99023 197.263C6.07634 352.944 120.623 412.116 221.108 402.967C362.911 390.055 446.253 218.547 376.891 102.833C341.804 44.3044 256.512 -4.32201 189.951 2.78803C67.4064 15.8731 -11.7043 141.476 22.6115 257.098C61.0126 386.462 232.687 434.265 337.562 357.028C439.715 273.827 411.623 152.855 381.488 119.167" fill="white" />
      <path d="M175.65 13.5071C163.06 13.5071 16.2916 34.4348 1.99023 197.263C6.07634 352.944 120.623 412.116 221.108 402.967C362.911 390.054 446.253 218.547 376.891 102.833C341.804 44.3044 256.512 -4.32201 189.951 2.78803C67.4065 15.8731 -11.7043 141.476 22.6115 257.098C61.0126 386.462 232.687 434.265 337.562 357.028C439.715 273.827 411.623 152.855 381.488 119.167" stroke="#191B1D" strokeWidth="3.45068" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
