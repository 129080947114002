import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Select
} from '@comicrelief/component-library';
import {
  FIELDS,
  TSHIRT_SIZES
} from './constants';
import Fieldset from '../../components/Fieldset/Fieldset';
import Grid from '../../components/Grid/Grid';
import ProgressBar from './components/ProgressBar';
import {
  Button,
  SoftHider,
  SubmitContainer
} from './FundraiserSignup.style';
import renderBody from '../../components/RichText/RichText';
import FundraiserSignupContext from './context/FundraiserSignupContext';

const FundraiserSignupStep1 = ({
  title,
  handler,
  description = 'Your details',
  options
}) => {
  const {
    step,
    register,
    isValidating,
    isSleeping,
    getError
  } = useContext(FundraiserSignupContext);
  const { showPhoneNumberField, showTShirtField } = options;

  return (
    <SoftHider show={step === 1} data-test="step-1">
      <ProgressBar step={step} />
      <Fieldset legend={title}>
        {description && (typeof description === 'string' ? <p>{description}</p> : renderBody(description))}
        <Grid columns={2} mobileColumns={1}>
          <Input
            {...register(FIELDS.FIRST_NAME)}
            type="text"
            name={FIELDS.FIRST_NAME}
            label="First name"
            id={FIELDS.FIRST_NAME}
            errorMsg={getError(FIELDS.FIRST_NAME)}
            autoComplete="off"
            data-test="first-name-input"
          />
          <Input
            {...register(FIELDS.LAST_NAME)}
            type="text"
            name={FIELDS.LAST_NAME}
            label="Last name"
            id={FIELDS.LAST_NAME}
            errorMsg={getError(FIELDS.LAST_NAME)}
            autoComplete="off"
            data-test="last-name-input"
          />
          <Input
            {...register(FIELDS.EMAIL)}
            type="email"
            name={FIELDS.EMAIL}
            label="Email address"
            id={FIELDS.EMAIL}
            errorMsg={getError(FIELDS.EMAIL)}
            autoComplete="off"
            data-test="email-input"
          />
          <Input
            {...register(FIELDS.CONFIRM_EMAIL)}
            type="email"
            name={FIELDS.CONFIRM_EMAIL}
            label="Confirm email address"
            id={FIELDS.CONFIRM_EMAIL}
            errorMsg={getError(FIELDS.CONFIRM_EMAIL)}
            autoComplete="off"
            data-test="confirm-email-input"
          />
          {showPhoneNumberField && (
          <Input
            {...register(FIELDS.PHONE)}
            type="text"
            name={FIELDS.PHONE}
            label="Phone number"
            id={FIELDS.PHONE}
            errorMsg={getError(FIELDS.PHONE)}
            autoComplete="off"
            optional
            data-test="phone-input"
          />
          )}

          {showTShirtField && (
            <Select
              {...register(FIELDS.TSHIRT_SIZE)}
              name={FIELDS.TSHIRT_SIZE}
              id={FIELDS.TSHIRT_SIZE}
              label="T-shirt size"
              options={Object.values(TSHIRT_SIZES)}
              errorMsg={getError(FIELDS.TSHIRT_SIZE)}
              data-test="t-shirt-select"
            />
          )}
        </Grid>
      </Fieldset>
      {step === 1 && (
        <SubmitContainer>
          <Button
            type="submit"
            loading={isValidating || isSleeping}
            disabled={isValidating || isSleeping}
            onClick={handler}
            data-test="submit-1"
          >
            Continue
          </Button>
        </SubmitContainer>
      )}
    </SoftHider>
  );
};

FundraiserSignupStep1.propTypes = {
  title: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  description: PropTypes.shape({ raw: PropTypes.string.isRequired }),
  options: PropTypes.shape({
    showPhoneNumberField: PropTypes.bool,
    showTShirtField: PropTypes.bool
  }).isRequired
};

export default FundraiserSignupStep1;
