import React from 'react';

export default function Star2() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 108 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.60352 45.3908C16.0648 44.1191 29.5369 42.8581 42.9982 41.5863L52.2023 2L70.9985 33.6001L105.196 23.5984L83.1988 60.1993L105.196 86.7985L63.605 73.9947L41.2091 108.192V64.8014C28.3406 58.3348 15.4828 51.8682 2.6143 45.4016L2.60352 45.3908Z" fill="white" />
      <path d="M2 45.3908C15.4613 44.1191 28.9333 42.8581 42.3946 41.5863L51.5988 2L70.395 33.6001L104.592 23.5984L82.5953 60.1993L104.592 86.7985L63.0015 73.9947L40.6056 108.192V64.8014C27.737 58.3348 14.8793 51.8682 2.01079 45.4016L2 45.3908Z" stroke="#1D1D1B" strokeWidth="2.15553" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M105.993 37.5016L94.9893 56.0715" stroke="#1D1D1B" strokeWidth="2.15553" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M63.001 73.9947C63.8955 73.1109 65.2966 71.99 67.215 71.5051C78.6393 68.5843 90.6564 92.349 101.111 89.1157C102.027 88.8355 103.288 88.232 104.592 86.7985" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M42.998 41.5971C44.5824 40.7025 46.5439 39.2691 47.9019 36.9519C53.6895 27.0904 41.5107 13.2088 47.4815 5.11474C48.2252 4.11242 49.4431 2.9161 51.5986 2" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 45.3908C15.4613 44.1191 28.9333 42.8581 42.3946 41.5863L51.5988 2L70.395 33.6001L104.592 23.5984L82.5953 60.1993L104.592 86.7985L63.0015 73.9947L40.6056 108.192V64.8014C27.737 58.3348 14.8793 51.8682 2.01079 45.4016L2 45.3908Z" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M105.993 37.5016L94.9893 56.0715" stroke="#1D1D1B" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
