import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  Text,
  Picture,
  ShareButton,
  containers,
  spacing
} from '@comicrelief/component-library';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import renderRichText from '../../components/RichText/RichText';
import NewsBlock from '../../components/NewsBlock/NewsBlock';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color('grey_light')};
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font.regular};
`;

const Container = styled.article.attrs({
  'data-test': 'news-article'
})`
  background: ${({ theme }) => theme.color('white')};
  width: 100%;
  max-width: ${containers.small};
  margin: ${spacing('l')} auto;
  padding: ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('l')} ${spacing('xl')};
  }
  img {
    margin-bottom: ${spacing('l')};
  }
  [data-test="social-share"] {
    margin-bottom: ${spacing('md')};
    @media ${({ theme }) => theme.allBreakpoints('L')} {
      margin-bottom: ${spacing('l')};
    }
    img {
      margin: 0;
    }
  }
`;

const Header = styled.header`
  padding-top: ${spacing('l')};
  text-align: center;
  width: 100%;
  max-width: ${containers.small};
  margin: 0 auto;
`;

const Image = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    width: 130%;
    margin-left: -15%;
  }
`;

const Article = ({ data }) => {
  const {
    article: { edges },
    related: { edges: relatedArticles }
  } = data;

  const post = edges[0].node;
  const {
    title, displayDate, image, summary, body, path
  } = post;

  const seoSummary = summary !== null && typeof summary.summary !== 'undefined' ? summary.summary : '';
  const fallback = image ? image.fallback?.src : null;
  const seoImage = image ? image.seoImage?.src : null;
  const fluidImage = image ? image.fluid?.srcSet : null;
  const lowImage = image ? image.placeHolder?.src : null;
  const description = image ? image.description : null;

  return (
    <Layout>
      <SEO title={`${title} | Comic Relief`} description={seoSummary} image={`https:${seoImage}`} pathname={`news/${path}`} article>
        {({ siteUrl, seo }) => (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Article',
              author: 'Comic Relief',
              headline: seo.title,
              description: seo.description,
              datePublished: displayDate,
              mainEntityOfPage: seo.url,
              image: {
                '@type': 'imageObject',
                url: seo.image
              },
              publisher: {
                '@type': 'Organization',
                name: 'Comic Relief',
                logo: {
                  '@type': 'imageObject',
                  url: `${siteUrl}assets/logo.jpg`
                }
              }
            })}
          </script>
        )}
      </SEO>
      <Wrapper>
        <Header>
          <Text tag="h1" family="Anton" size="xxl" uppercase>
            {title}
          </Text>
          <Text tag="p" size="m">
            {displayDate}
          </Text>
        </Header>
        <Container>
          <div data-test="social-share">
            <ShareButton />
          </div>
          {image ? (
            <Image>
              <Picture
                alt={description}
                image={fallback}
                images={fluidImage}
                imageLow={lowImage}
                objectFit={false}
              />
            </Image>
          ) : null}
          {body ? renderRichText(body, 'white', 'news') : null}
        </Container>
      </Wrapper>
      <section data-test="related-news">
        <NewsBlock data={relatedArticles} relatedArticlesRow />
      </section>
    </Layout>
  );
};

Article.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.shape({
      edges: PropTypes.shape([])
    }).isRequired,
    related: PropTypes.shape({
      edges: PropTypes.shape([])
    }).isRequired
  }).isRequired
};

export default Article;

export const pageQuery = graphql`
  query getArticle($slug: String, $categories: [String], $type: String) {
    article: allContentfulPageArticle(filter: { path: { eq: $slug } }) {
      edges {
        node {
          path
          title
          displayDate(formatString: "Do MMMM YYYY")
          categories {
            title
          }
          body {
            raw
            references {
              __typename
              ...HtmlCode
              ...Accordion
              ...Asset
            }
          }
          summary {
            summary
          }
          image {
            ...Picture
          }
          body {
            raw
          }
        }
      }
    }
    related: allContentfulPageArticle(
      filter: {
        type: {eq: $type},
        path: {ne: $slug},
        categories: {
          elemMatch: {
            title: {
              in: $categories
            }
          }
        }
      }
      limit: 3
      sort: { fields: displayDate, order: DESC }
    ) {
      edges {
        node {
          title
          categories {
            title
          }
          path
          type
          id
          body {
            raw
          }
          displayDate(formatString: "Do MMMM YYYY")
          image {
            fluid(cropFocus: FACE, maxHeight: 410, resizingBehavior: FILL, maxWidth: 740) {
              srcSet
            }
            fallback: fixed {
              src
            }
            placeHolder: fixed(cropFocus: FACE, height: 410, resizingBehavior: FILL, width: 740, quality: 1) {
              src
            }
            seoImage: resize {
              src
            }
          }
        }
      }
    }
  }
`;
