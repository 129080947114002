import React from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import styled from 'styled-components';

import { spacing, containers } from '@comicrelief/component-library';
import renderRichText from '../RichText/RichText';

const Wrapper = styled.div`
  background: ${({ background, theme }) => theme.color(background)};
`;

const Container = styled.div`
  width: 100%;
  margin: auto;
  max-width: ${containers.medium};

  padding: ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: 3rem ${spacing('m')};
  }
`;

const InnerWrapper = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    max-width: ${containers.small};
  }
`;

const Heading = ({ data }) => {
  const {
    heading, backgroundColour
  } = data;
  const bgColour = snakeCase(backgroundColour);

  return (
    <Wrapper background={bgColour}>
      <Container>
        <InnerWrapper>
          {renderRichText(heading, bgColour, 'Heading')}
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

Heading.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.shape(),
    backgroundColour: PropTypes.string
  }).isRequired
};

export default Heading;
