import React from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import { Footer } from '@comicrelief/component-library';

/* pre-CMS version */
const footerCopy = {
  copy:
    'Comic Relief is the operating name of Charity Projects, a registered charity in England and Wales (326568) and Scotland (SC039730), which is a company limited by guarantee registered in England and Wales (01806414). Registered address: 6th Floor, The White Chapel Building, 10 Whitechapel High Street, London, E1 8QS.'
};

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies(theme.font.regular)};
`;

export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        contentfulComponentMenu(title: { eq: "Footer menu" }) {
          title
          menuGroups {
            title
            id
            links {
              ... on Node {
                ... on ContentfulPageArticle {
                  title
                  path
                  internal {
                    type
                  }
                }
              }
              ... on ContentfulPageLandingPage {
                title
                path
                internal {
                  type
                }
              }
              ... on ContentfulComponentLink {
                id
                title
                url
                internal {
                  type
                }
                reference {
                  path
                }
              }
              ... on ContentfulPageDefault {
                title
                path
                internal {
                  type
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const menu = get(data, 'contentfulComponentMenu');

      return menu !== null && (
        <ThemeConsumer>
          {theme => (
            <Wrapper font={theme.font.regular}>
              <Footer
                navItems={menu}
                footerCopy={footerCopy.copy}
                campaign="Comic Relief"
              />
            </Wrapper>
          )}
        </ThemeConsumer>
      );
    }}
  />
);
