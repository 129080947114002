import React from 'react';

export default function EndIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="background" d="M2.35624 39.227C2.35624 41.3678 10.3195 66.6034 31.6431 66.6034C52.9666 66.6034 68.9707 48.8831 66.5287 30.7841C63.5106 8.41557 43.8559 -1.00351 26.4132 2.83387C8.97049 6.67125 0.899519 26.0599 2.10949 37.378C4.33628 58.2159 24.1767 71.1145 43.853 65.2793C65.8679 58.7495 73.7421 33.2309 60.5981 15.3976C46.4392 -1.97271 26.1694 1.31026 20.4363 6.43452" fill="white" />
      <path d="M2.35624 39.227C2.35624 41.3678 10.3195 66.6034 31.6431 66.6034C52.9666 66.6034 68.9707 48.8831 66.5287 30.7841C63.5106 8.41557 43.8559 -1.00351 26.4132 2.83387C8.97049 6.67125 0.899519 26.0599 2.10949 37.378C4.33628 58.2159 24.1767 71.1145 43.853 65.2793C65.8679 58.7495 73.7421 33.2309 60.5981 15.3976C46.4392 -1.97271 26.1694 1.31027 20.4363 6.43452" stroke="#1D1E1C" strokeWidth="2.09314" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.1359 46.6757V22.3914H24.8609V27.0843H20.0832V31.8054H24.663V36.3852H20.0832V41.9545H25.1719V46.6757H15.1359Z" fill="#1D1E1C" />
      <path d="M27.2668 45.9315V21.6472H32.2707L34.504 33.2664V21.6472H39.1969V45.9315H34.4475L32.0163 33.7752V45.9315H27.2668Z" fill="#1D1E1C" />
      <path d="M41.6708 22.3912H48.3709C50.1048 22.3912 51.4052 22.8718 52.2722 23.833C53.1392 24.7942 53.5821 26.2172 53.6009 28.1019L53.6575 39.4666C53.6763 41.8601 53.2617 43.66 52.4136 44.8662C51.5654 46.0724 50.1614 46.6755 48.2013 46.6755H41.6708V22.3912ZM47.4097 42.3784C48.2955 42.3784 48.7384 41.945 48.7384 41.078V28.5542C48.7384 28.0076 48.6913 27.6024 48.5971 27.3386C48.5217 27.0559 48.3709 26.8674 48.1447 26.7732C47.9186 26.6789 47.5699 26.6318 47.0987 26.6318H46.5616V42.3784H47.4097Z" fill="#1D1E1C" />
    </svg>
  );
}
