import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from '@comicrelief/component-library';

const Success = ({
  name, transaction, url, handleRefresh
}) => (
  <>
    <Text tag="h2" size="xl" uppercase>
      {`${name}, Thank you for entering.`}
    </Text>
    <Text tag="p">
      Your transaction number is:
    </Text>
    <Text tag="p" weight="bold">{transaction}</Text>
    <Text tag="p">
      Check your inbox for an email with your entry details.
    </Text>
    <Text tag="h2" size="xl" color="red" uppercase>
      Good luck!
    </Text>
    <Text tag="p">
      <Link
        href={`/prizes/${url}`}
        target="self"
        type="standard"
        onClick={() => { handleRefresh(); }}
      >
        Enter again
      </Link>
      <Text tag="span"> or </Text>
      <Link href="/prizes" target="self" type="standard">
        Browse more prizes.
      </Link>
    </Text>
  </>
);

Success.propTypes = {
  name: PropTypes.string.isRequired,
  transaction: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  handleRefresh: PropTypes.func.isRequired
};

export default Success;
