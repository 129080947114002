import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ErrorText, spacing } from '@comicrelief/component-library';

const ErrorBorder = styled.div`
  ${({ hasError, theme }) => hasError && css`
    border: 1px solid ${theme.color('red')};
    margin: calc(-1px - ${spacing('sm')});
    border-radius: ${spacing('sm')};
    padding: ${spacing('sm')};
  `}
`;

const ErrorGroupWrapper = ({ errorMessage = '', children }) => (
  <ErrorBorder hasError={!!errorMessage}>
    {children}
    {errorMessage && <ErrorText weight="bold">{errorMessage}</ErrorText>}
  </ErrorBorder>
);

ErrorGroupWrapper.propTypes = {
  errorMessage: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default ErrorGroupWrapper;
