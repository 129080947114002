import React from 'react';
import PropTypes from 'prop-types';
import { SearchBox as SearchBoxBase } from '@elastic/react-search-ui';
import { SearchInput } from '@comicrelief/component-library';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: 50%;
  }
`;

const SearchBox = ({ totalResults }) => (
  <Wrapper>
    <SearchBoxBase
      searchAsYouType
      debounceLength={500}
      view={({ onChange, value }) => (
        <SearchInput
          type="search"
          value={value}
          onChange={e => onChange(e.target.value)}
          placeholder="Search"
          role="combobox"
          aria-haspopup="false"
          aria-autocomplete="list"
          aria-owns="hits-list"
          aria-expanded={totalResults > 0}
          autoFocus
        />
      )}
    />
  </Wrapper>
);

SearchBox.propTypes = {
  totalResults: PropTypes.number.isRequired
};

export default SearchBox;
