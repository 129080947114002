import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { HeadingFont } from '../sharedStyles';
import Squiggle from './Squiggle';

const Container = styled.div`
  margin: 45px auto 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.color('white')};
  width: 76%;
`;

const Heading = styled.h1`
  ${HeadingFont};
  font-size: 2.4rem;
  letter-spacing: 0;
`;

const Subtitle = styled.p`
  margin-top: 8px;
  font-size: 1.2rem;
  font-weight: 700;
`;

const SquiggleWrapper = styled.div`
  margin: 28px auto 50px;
  width: 400px;
`;

export default function ChallengeDetails({ title, subtitle }) {
  return (
    <Container>
      <Heading>{title}</Heading>
      <Subtitle>{subtitle}</Subtitle>
      <SquiggleWrapper>
        <Squiggle />
      </SquiggleWrapper>
    </Container>
  );
}

ChallengeDetails.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};
