import React from 'react';

export default function HomeIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      {' '}
      <path className="background" d="M2.366 39.227C2.366 41.3678 10.3292 66.6034 31.6528 66.6034C52.9764 66.6034 68.9804 48.8831 66.5384 30.7841C63.5204 8.41557 43.8657 -1.00351 26.423 2.83387C8.98025 6.67125 0.909285 26.0599 2.11926 37.378C4.34605 58.2159 24.1865 71.1145 43.8628 65.2793C65.8777 58.7495 73.7519 33.2309 60.6079 15.3976C46.449 -1.97271 26.1791 1.31026 20.4461 6.43452" fill="white" />
      <path d="M2.366 39.227C2.366 41.3678 10.3292 66.6034 31.6528 66.6034C52.9764 66.6034 68.9804 48.8831 66.5384 30.7841C63.5204 8.41557 43.8657 -1.00351 26.423 2.83387C8.98025 6.67125 0.909285 26.0599 2.11926 37.378C4.34605 58.2159 24.1865 71.1145 43.8628 65.2793C65.8777 58.7495 73.7519 33.2309 60.6079 15.3976C46.449 -1.97271 26.1791 1.31027 20.4461 6.43452" stroke="#1D1E1C" strokeWidth="2.09314" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.6425 32.3374C21.382 26.8033 27.1166 21.2631 32.8561 15.729C39.4322 20.5462 46.0083 25.3634 52.5844 30.1807C52.3655 30.3969 52.1508 30.6247 51.9288 30.8681C51.3186 31.5445 50.8081 32.2163 50.3796 32.8483C44.5588 28.1217 38.744 23.3902 32.9231 18.6635C27.7509 24.5687 22.5787 30.474 17.4065 36.3792C16.8181 35.0356 16.2303 33.6865 15.6419 32.3428L15.6425 32.3374Z" stroke="#1D1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.1838 29.1825C21.3312 36.3648 21.4786 43.5472 21.626 50.7296C31.0407 50.3042 40.4554 49.8789 49.8647 49.4528C49.035 43.0429 48.2113 36.6282 47.3816 30.2183" stroke="#1D1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M36.5856 51.8342C36.4423 47.0994 36.2997 42.3591 36.1565 37.6242C38.1365 37.3822 40.1172 37.1347 42.0972 36.8926C42.5265 41.816 42.9497 46.7443 43.3789 51.6677" stroke="#1D1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
