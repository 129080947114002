import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing } from '@comicrelief/component-library';

const EmbeddedVideo = styled.video`
 width: 100%;
 height: auto;
 margin-bottom: ${spacing('m')};
`;

const EmbeddableVideo = ({ data }) => {
  const { videoFile, videoThumbnail } = data;
  const videoUrl = videoFile?.file?.url;
  const isSupportedVideoType = videoUrl.includes('.mp4') || videoUrl.includes('.MP4');
  const thumbnailUrl = videoThumbnail?.file?.url;

  return (
    <>
      {(isSupportedVideoType && videoUrl)
        && (
          <EmbeddedVideo controls poster={thumbnailUrl}>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support this video format.
          </EmbeddedVideo>
        )}
    </>
  );
};

EmbeddableVideo.propTypes = {
  data: PropTypes.shape({
    videoFile: PropTypes.objectOf(PropTypes.shape).isRequired,
    videoThumbnail: PropTypes.objectOf(PropTypes.shape).isRequired
  }).isRequired
};

export default EmbeddableVideo;
