import styled from 'styled-components';
import { SwivelJiggle } from './sharedStyles';

const BackgroundSplash = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 998;
`;

const Container = styled.div`
  width: 75%;
  max-width: 550px;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color('white')};
  border-radius: 8px;
  z-index: 999;
  padding: 2px;
  border: 1px solid green;
`;

const ContainerBorder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 3px solid ${({ theme }) => theme.color('black')};
  border-radius: 8px;
  transform: rotate(-0.4deg);
`;

const Inner = styled.div`
  padding: 50px;
  text-align: center;
`;

const CrossButton = styled.button`
  width: 36px;
  align-self: end;
  margin: 5px 0 0;
  border: none;
  background: transparent;
  ${SwivelJiggle}
`;

const Heading = styled.h1`
  font-size: 2.6rem;
  line-height: 3.3rem;
`;

const Message = styled.p`
  margin-top: 20px;
  font-size: 1.3rem;
`;

const OnwardButton = styled.button`
  margin-top: 20px;
  background: ${({ theme }) => theme.color('grey_stone')};
  border: none;
  padding: 1px;
  font-size: 1rem;
  font-weight: 700;
  font-size: 1.3rem;
`;

const ButtonBorder = styled.div`
  border: 3px solid black;
  padding: 10px 13px;
  min-width: 180px;
  ${SwivelJiggle}
`;

export {
  BackgroundSplash,
  Container,
  ContainerBorder,
  Inner,
  CrossButton,
  Heading,
  Message,
  OnwardButton,
  ButtonBorder
};
