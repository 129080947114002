import React from 'react';

export default function Squiggle() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 250 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_643_127)">
        <path d="M233.886 30.8825C233.643 30.9933 233.405 31.038 233.141 31.0136C194.689 27.5973 154.949 24.062 115.46 20.6832C104.952 19.7799 93.616 18.8333 82.498 18.4065L211.804 33.7733C212.696 33.8557 213.319 34.6792 213.207 35.5348C213.124 36.4265 212.301 37.0499 211.445 36.9376C183.913 33.9297 156.502 32.1321 129.909 31.5757C129.011 31.5594 128.312 30.8288 128.361 29.9341C128.378 29.0364 129.108 28.3378 130.003 28.3871C134.092 28.4648 138.212 28.5786 142.361 28.7285L57.9575 18.7056C57.1319 18.6294 56.5324 17.908 56.5426 17.0763C56.5527 16.2446 57.2142 15.5729 58.019 15.514C76.3007 14.1042 97.2502 15.9048 115.756 17.4799C123.192 18.0998 130.659 18.7557 138.092 19.4086C97.6866 15.1458 57.4357 11.7299 17.7331 9.23062C16.8384 9.18133 16.1428 8.41771 16.2221 7.55908C16.3044 6.66743 17.065 6.00491 17.9266 6.05115C88.7574 10.4914 161.287 17.8194 233.47 27.8133C234.325 27.9256 234.952 28.7162 234.84 29.5717C234.785 30.1662 234.373 30.661 233.853 30.8795" fill="#191B1D" />
      </g>
      <defs>
        <clipPath id="clip0_643_127">
          <rect width="247.75" height="18.4363" fill="white" transform="translate(2.62207 0.394104) rotate(5.2741)" />
        </clipPath>
      </defs>
    </svg>
  );
}
