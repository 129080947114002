import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PageLinkLoader from './components/PageLinkLoader';
import SingleMessageDs from '../../components/SingleMessageDs/SingleMessageDs';
import renderBody from '../../components/RichText/RichText';

import { SoftHider } from './FundraiserSignup.style';
import FundraiserSignupContext from './context/FundraiserSignupContext';

const FundraiserSignupStep4 = ({
  successMessage,
  campaignCode,
  successParagraphs = [],
  userUUID = null,
  showJGPageLinkLoader = true
}) => {
  const { step } = useContext(FundraiserSignupContext);

  return (
    <SoftHider show={step === 4} data-test="step-4">
      {renderBody(successMessage)}
      {successParagraphs
        && successParagraphs.map(({ id, ...rest }) => (
          <SingleMessageDs data={rest} key={id} />
        ))}

      {(step === 4 && showJGPageLinkLoader)
      && <PageLinkLoader UUID={userUUID} campaignCode={campaignCode} />}
    </SoftHider>
  );
};

FundraiserSignupStep4.propTypes = {
  successMessage: PropTypes.shape({ raw: PropTypes.string.isRequired })
    .isRequired,
  // eslint-disable-next-line
  successParagraphs: PropTypes.arrayOf(PropTypes.object),
  userUUID: PropTypes.string,
  showJGPageLinkLoader: PropTypes.bool,
  campaignCode: PropTypes.string.isRequired
};

export default FundraiserSignupStep4;
