import React from 'react';
import { Results as ResultsBase } from '@elastic/react-search-ui-views';
import { Results } from '@elastic/react-search-ui';
import styled from 'styled-components';
import { spacing } from '@comicrelief/component-library';
import Hit from './Hit';

const Wrapper = styled.div`
  ul {
    list-style: none;
  }
  li {
    margin-bottom: ${spacing('md')};
  }
`;

const Hits = () => (
  <Wrapper>
    <Results
      view={props => ResultsBase({
        ...props,
        role: 'listbox',
        'aria-label': 'Search results',
        id: 'hits-list'
      })}
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      resultView={({ result }) => <li role="option" aria-selected={false}><Hit hit={result} /></li>}
    />
  </Wrapper>
);

export default Hits;
