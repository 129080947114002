import { useEffect, useState } from 'react';
import { Howl } from 'howler';

const useSoundEffects = () => {
  const [soundsHowlArray, setSoundsHowlArray] = useState([]);

  useEffect(() => {
    // Create sounds with Howl package
    const loadSounds = async () => {
      const sounds = [
        await import('./sound-files/children-cheering.mp3'),
        await import('./sound-files/boing-bounce.mp3'),
        await import('./sound-files/drum-roll.mp3'),
        await import('./sound-files/drum-crash.wav'),
        await import('./sound-files/gremlin-laugh.mp3'),
        await import('./sound-files/female-laugh.mp3'),
        await import('./sound-files/goofy-laugh.mp3'),
        await import('./sound-files/deep-laugh.mp3'),
        await import('./sound-files/applause.mp3')
      ];

      const newSoundsHowlArray = sounds.map(sound => new Howl({ src: [sound.default] }));
      setSoundsHowlArray(newSoundsHowlArray);
    };

    loadSounds();

    // Clean-up function to stop all sounds when component unmounts
    return () => {
      soundsHowlArray.forEach(sound => sound.stop());
    };
  }, []);

  /**
   * Groups of sounds
   */
  const step2SoundsArray = [
    soundsHowlArray[1],
    soundsHowlArray[2]
  ];

  const laughSounds = [
    soundsHowlArray[5],
    soundsHowlArray[6],
    soundsHowlArray[7],
    soundsHowlArray[8]
  ];

  const playSounds = (isPlaying, stepSounds) => {
    if (stepSounds === 'step1') {
      if (isPlaying === true) {
        soundsHowlArray[0].play();
      } else {
        soundsHowlArray[0].stop();
      }
    }

    if (stepSounds === 'step2') {
      step2SoundsArray.map(sound => (
        (isPlaying === true) ? sound.play() : sound.stop()
      ));
    }

    if (stepSounds === 'step3') {
      if (isPlaying === true) {
        soundsHowlArray[3].play();
      } else {
        soundsHowlArray[3].stop();
      }
    }

    if (stepSounds === 'step4') {
      if (isPlaying === true) {
        soundsHowlArray[8].play();
      } else {
        soundsHowlArray[8].stop();
      }
    }
  };

  const stopAllSounds = () => {
    soundsHowlArray.map(sound => sound.stop());
  };

  return { laughSounds, stopAllSounds, playSounds };
};

export default useSoundEffects;
