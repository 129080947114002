import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { snakeCase } from 'lodash';
import { Text, Card } from '@comicrelief/component-library';
import { breakpointValues } from '@comicrelief/component-library/src/theme/shared/allBreakpoints';

import renderRichText from '../RichText/RichText';
import {
  Wrapper,
  Copy,
  BlurbWrapper,
  Title,
  BodyText,
  Grid,
  Items,
  HideText,
  HideTextInner,
  Line
} from './Cards.style';

const Cards = ({ data }) => {
  const {
    backgroundColour: bgCards,
    layout,
    paddingOptions,
    body,
    cards,
    cardsCentredText,
    hideTitle,
    smallBreakpointRowLayout,
    mediumBreakpointRowLayout,
    title
  } = data;

  const cardsBackground = snakeCase(bgCards || 'white');

  const changeColor = background => (background && (background.includes('dark') || background.includes('red') || background.includes('deep_violet') || background.includes('black')) ? 'white' : 'black');

  const paddingOption = paddingOptions ? snakeCase(paddingOptions) : null;

  // Define media queries
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpointValues.M - 1}px)` });
  const isTablet = useMediaQuery({ query: `(min-width: ${breakpointValues.M}px) and (max-width: ${breakpointValues.L - 1}px)` });

  return (
    <Wrapper cardsBackground={cardsBackground} paddingOption={paddingOption}>
      <BlurbWrapper cardsCentredText={cardsCentredText}>
        {title && (
          <HideText hideText={hideTitle}>
            <HideTextInner tag="h2" size="xxl" color={changeColor(cardsBackground)} family="Anton" weight="normal">
              <Title>{title}</Title>
            </HideTextInner>
          </HideText>
        )}
        { body ? (
          <BodyText>{renderRichText(body, cardsBackground, 'cards')}</BodyText>
        ) : null}
      </BlurbWrapper>
      <Grid>
        {cards
          && cards.map((card, index) => {
            const {
              body: cardBody,
              id,
              image,
              backgroundColour,
              label,
              centredText,
              squaredCorners
            } = card;
            const fallback = image?.fallback?.src;
            const imageLow = image?.placeHolder?.src;
            const images = image?.fluid?.srcSet;

            const cardBgColour = snakeCase(backgroundColour || 'white');

            /**
              * Set background colour (that in turn sets text color) to
              * the paragraph Cards background when in row layout.
              * This is because a Card in row layout currently has a transparent background.
              * So the the paragraph Cards background needs to be referenced.
            */
            const isRowLayoutMobile = (isMobile && smallBreakpointRowLayout);
            const isRowLayoutTablet = (isTablet && mediumBreakpointRowLayout);
            const correctBgColor = isRowLayoutMobile || isRowLayoutTablet
              ? cardsBackground : cardBgColour;

            const description = image?.description;
            return (
              <Items key={id} layout={layout}>
                <Card
                  imageLow={imageLow}
                  image={fallback}
                  images={images}
                  backgroundColor={cardBgColour}
                  imageAltText={description}
                  squaredCorners={squaredCorners}
                  smallBreakpointRowLayout={smallBreakpointRowLayout}
                  mediumBreakpointRowLayout={mediumBreakpointRowLayout}
                >

                  {cardBody ? (
                    <Copy centred={centredText}>
                      {label ? (
                        <Text
                          tag="span"
                          size="s"
                          color={changeColor(correctBgColor)}
                          uppercase
                        >
                          {label}
                        </Text>
                      ) : null}
                      {renderRichText(cardBody, correctBgColor, 'card')}
                    </Copy>
                  ) : null}

                </Card>
                {/* Create dividing line between each card, except on last card */}
                {index !== cards.length - 1
                  && (
                  <Line
                    smallBreakpointRowLayout={smallBreakpointRowLayout}
                    mediumBreakpointRowLayout={mediumBreakpointRowLayout}
                  />
                  )}
              </Items>
            );
          })}
      </Grid>
    </Wrapper>
  );
};

Cards.propTypes = {
  data: PropTypes.shape({
    backgroundColour: PropTypes.string,
    body: PropTypes.shape({
      raw: PropTypes.string
    }),
    cards: PropTypes.arrayOf(PropTypes.shape({
      backgroundColour: PropTypes.string.isRequired,
      body: PropTypes.shape({
        raw: PropTypes.string
      }).isRequired,
      centredText: PropTypes.bool,
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        description: PropTypes.string,
        fluid: PropTypes.shape({}),
        fallback: PropTypes.shape({}),
        placeHolder: PropTypes.shape({}),
        seoImage: PropTypes.shape({})
      }).isRequired,
      label: PropTypes.string,
      squaredCorners: PropTypes.bool,
      title: PropTypes.string.isRequired
    })),
    cardsCentredText: PropTypes.bool,
    hideTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    layout: PropTypes.string,
    mediumBreakpointRowLayout: PropTypes.bool,
    paddingOptions: PropTypes.string,
    smallBreakpointRowLayout: PropTypes.bool,
    title: PropTypes.string,
    squaredCorners: PropTypes.bool
  }).isRequired
};

export default Cards;
