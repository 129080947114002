import React from 'react';

export default function HomeSvg() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="23" fill="white" />
      <path d="M33.707 21.793L32 20.086V16.5C32 15.948 31.552 15.5 31 15.5C30.448 15.5 30 15.948 30 16.5V18.086L23.707 11.793C23.3165 11.4025 22.6835 11.4025 22.293 11.793L12.293 21.793C12.007 22.079 11.9215 22.509 12.076 22.8825C12.231 23.2565 12.5955 23.5 13 23.5H14V30.5C14 31.052 14.448 31.5 15 31.5H19C19.552 31.5 20 31.052 20 30.5V26.0085C20.0075 25.5895 20.1865 23.5 23 23.5C25.8135 23.5 25.9925 25.5895 26 26V30.5C26 31.052 26.448 31.5 27 31.5H31C31.552 31.5 32 31.052 32 30.5V23.5H33C33.4045 23.5 33.769 23.2565 33.924 22.8825C34.0785 22.509 33.993 22.079 33.707 21.793ZM30 29.5H28V26C28 25.955 27.943 21.5 23 21.5C18.057 21.5 18 25.955 18 26V29.5H16V22.5C16 22.404 15.982 22.3135 15.957 22.2255C15.951 22.2055 15.9455 22.186 15.9385 22.166C15.9085 22.0825 15.871 22.003 15.821 21.931C15.815 21.922 15.807 21.915 15.8005 21.9065C15.753 21.8425 15.6975 21.786 15.6365 21.735C15.621 21.7225 15.607 21.7095 15.591 21.6975C15.5215 21.646 15.446 21.603 15.3645 21.571C15.3595 21.569 15.3555 21.565 15.3505 21.563L23 13.9145L30.649 21.5635C30.644 21.5655 30.64 21.5695 30.635 21.5715C30.5535 21.6035 30.478 21.6465 30.4085 21.698C30.3925 21.7095 30.3785 21.7225 30.3635 21.735C30.3025 21.786 30.247 21.8425 30.1995 21.9065C30.193 21.915 30.1855 21.922 30.179 21.931C30.129 22.003 30.0915 22.0825 30.0615 22.166C30.0545 22.1855 30.049 22.205 30.043 22.225C30.018 22.313 30 22.404 30 22.5V29.5Z" fill="black" />
    </svg>
  );
}
