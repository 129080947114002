import React from 'react';
import { Confetti } from '@comicrelief/component-library';
import PropTypes from 'prop-types';
import renderNose from './renderNose';
import CircleButton from './CircleButton';
import {
  StyledNoseCircle,
  StyledFaceWrapper,
  StyledSpeechArrowWrapper,
  StyledSpeechBubble,
  StyledSpeechWrapper,
  StyledSpeechArrowLeft,
  StyledSpeechArrowRight
} from './JokeGenShared.styles';

export default function Step2and3({
  step,
  setStep,
  soundOn,
  jokes,
  jokeIndex,
  noseChoice,
  numberOfJokes,
  startNewJoke,
  laughSound,
  playSounds,
  setBackgroundSplash
}) {
  React.useEffect(() => {
    if (step === 2) {
      // track joke number
      window.dataLayer.push({
        jokeNumber: (jokeIndex + 1)
      });
    }
  }, [step]);

  const handleButtonClick = () => {
    if (step === 2) {
      playSounds(false, 'step2');
      if (soundOn === true) {
        playSounds(true, 'step3');
        laughSound();
      }
      setBackgroundSplash(false);
      setStep(3);
    }

    if (step === 3) {
      if (((jokeIndex + 1) % numberOfJokes) === 0) {
        playSounds(false, 'step3');
        if (soundOn === true) {
          playSounds(true, 'step4');
        }
        setStep(4);
      } else {
        startNewJoke();
      }
    }
  };

  return (
    <>

      {step === 3 && <Confetti trigger height="100" />}

      <StyledNoseCircle step={step} noseChoice={noseChoice}>
        <StyledFaceWrapper>
          {renderNose(noseChoice)}
        </StyledFaceWrapper>
      </StyledNoseCircle>

      <StyledSpeechWrapper step={step}>

        <StyledSpeechArrowWrapper>
          {step === 2
            ? <StyledSpeechArrowLeft />
            : <StyledSpeechArrowRight />}
        </StyledSpeechArrowWrapper>

        {jokes
          && (
          <StyledSpeechBubble step={step}>
            {step === 2
              ? jokes[jokeIndex].question
              : jokes[jokeIndex].answer}
          </StyledSpeechBubble>
          )}

      </StyledSpeechWrapper>

      <CircleButton
        buttonText="Next"
        step={step}
        buttonClick={handleButtonClick}
        shadowColor={({ theme }) => theme.color('rnd_24_teal_dark')}
      />

    </>
  );
}

Step2and3.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  soundOn: PropTypes.bool.isRequired,
  numberOfJokes: PropTypes.number.isRequired,
  startNewJoke: PropTypes.func.isRequired,
  jokeIndex: PropTypes.number.isRequired,
  jokes: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  laughSound: PropTypes.func.isRequired,
  setBackgroundSplash: PropTypes.func.isRequired,
  playSounds: PropTypes.func.isRequired,
  noseChoice: PropTypes.number.isRequired
};
