import React from 'react';
import styled from 'styled-components';
import { Text, spacing } from '@comicrelief/component-library';

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: 50%;
  }
`;

const Searching = styled(Text)`
  display: flex;
  justify-content: center;
  padding: ${spacing('md')};
`;

const Loading = () => (
  <Wrapper><Searching color="deep_violet" size="l">Searching...</Searching></Wrapper>
);

export default Loading;
