import React from 'react';

export default function SoundOffSvg() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="22" fill="white" stroke="white" strokeWidth="2" />
      <path d="M29.5695 13.7341C29.1122 13.2973 28.5132 13.0568 27.8826 13.0568C27.4262 13.0568 26.9792 13.1858 26.5897 13.4299L19.8848 17.6325H17.4372C16.0934 17.6325 15 18.7258 15 20.0696V25.9218C15 27.2656 16.0933 28.359 17.4372 28.359H19.871L26.5896 32.5702C26.9791 32.8143 27.4262 32.9433 27.8826 32.9433C28.5132 32.9433 29.1122 32.7028 29.5696 32.266C29.8031 32.043 29.987 31.7805 30.1164 31.4857C30.2523 31.1759 30.3211 30.846 30.3211 30.5052V23.0044V22.9957V15.495C30.3211 15.154 30.2523 14.8241 30.1164 14.5143C29.987 14.2196 29.803 13.9571 29.5695 13.7341ZM28.5165 22.9957V23.0044V30.5051C28.5165 30.8759 28.2121 31.1386 27.8826 31.1386C27.7703 31.1386 27.6552 31.1081 27.5481 31.041L20.7596 26.786C20.518 26.6346 20.2386 26.5542 19.9534 26.5542H17.4372C17.0879 26.5542 16.8047 26.2711 16.8047 25.9218V20.0696C16.8047 19.7204 17.0879 19.4372 17.4372 19.4372H19.9673C20.2525 19.4372 20.5318 19.3569 20.7735 19.2054L27.5481 14.9591C27.6551 14.892 27.7702 14.8615 27.8825 14.8615C28.212 14.8615 28.5165 15.1242 28.5165 15.495V22.9957H28.5165Z" fill="black" />
      <rect x="11.3915" y="15.4133" width="5.68759" height="25.55" transform="rotate(-45 11.3915 15.4133)" fill="white" />
      <rect x="12.5815" y="14.0142" width="1.97182" height="27.3931" rx="0.985908" transform="rotate(-45 12.5815 14.0142)" fill="black" />
    </svg>
  );
}
