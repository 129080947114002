import styled from 'styled-components';
import { pendulum, jiggleSwell } from '../animations';
import { HeadingFont } from '../sharedStyles';

const Container = styled.div`
  position: relative;
  --size: 390px;
  width: var(--size);
  height: var(--size);
  ${HeadingFont};
`;

const HandDrawnCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: 0.2s;

  /* rotation */
  --rotate: ${jiggleSwell} 1.2s linear infinite;
  animation: ${props => (props.isHovered ? 'var(--rotate)' : 'none')};

  svg {
    path {
      transition: 0.1s;
      fill: ${props => (props.isHovered
    ? props.theme.color('grey_stone')
    : 'white'
  )};
    }
  }
`;

const Button = styled.button`
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${pendulum} 2.4s linear infinite;
  animation-delay: 0.5s;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonEdge = styled.div`
  z-index: 200;
  height: 90%;
  width: 90%;
  background: linear-gradient(
    135deg,
    #FF6666 0%,
    #A7000D 100%
  );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonInner = styled.div`
  background: ${({ theme }) => theme.color('red')};
  height: 92%;
  width: 92%;
  border-radius: 50%;
`;

const TextWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ButtonHeading = styled.div`
  color: ${({ theme }) => theme.color('white')};
  font-size: 2.8rem;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 3.5rem;
`;

const WobblyTextWrapper = styled.div`
  margin-top: 15px;
`;

const SquiggleWrapper = styled.div`
  transform: rotate(-8deg);
  margin-top: -12px;
  width: 78%;
`;

export {
  Container,
  HandDrawnCircle,
  Button,
  ButtonEdge,
  ButtonInner,
  TextWrapper,
  ButtonHeading,
  WobblyTextWrapper,
  SquiggleWrapper
};
