import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  ShareButton
} from '@comicrelief/component-library';

import {
  Wrapper,
  Container,
  Header,
  ShareContainer
} from './Prize.styles';

const ThankYou = ({ title, body = null }) => (
  <Wrapper>
    <Container>
      <Header>
        <Text tag="h1" family="Anton" size="big" uppercase>
          {title}
        </Text>
        <ShareContainer data-test="social-share">
          <ShareButton />
        </ShareContainer>
      </Header>
      {body && body[0] ? body : (
        <>
          <Text tag="h2" family="Anton" size="xl" uppercase>
            Thanks to everyone who supported &quot;
            {title}
            &quot;
          </Text>
          <Text tag="p" size="l">
            The winner will be contacted shortly.
          </Text>
        </>
      )}
    </Container>
  </Wrapper>
);

ThankYou.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node
};

export default ThankYou;
