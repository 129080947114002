import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { snakeCase } from 'lodash';

import { Membership } from '@comicrelief/component-library';

const MembershipSignup = ({ membershipInfo }) => (
  <StaticQuery
    query={graphql`
      query moneyBuy {
        allContentfulCart {
          edges {
            node {
              cartId
              regularGiving {
                moneybuys {
                  description
                  value
                }
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulCart: { edges } }) => {
      // Get money buy data (copy , description)
      const moneyBuy = edges.filter(
        e => e.node.cartId === membershipInfo.cartId
      );
      const hasMoneyBuy = moneyBuy.length > 0;
      const imageLow = membershipInfo.image && membershipInfo.image.placeHolder.src;
      const alt = membershipInfo.image && membershipInfo.image.description;
      const images = membershipInfo.image && membershipInfo.image.fluid.srcSet;
      const bgColor = snakeCase(
        membershipInfo.backgroundColour
          ? membershipInfo.backgroundColour
          : 'white'
      );
      return (
        hasMoneyBuy && (
          <Membership
            data={moneyBuy[0].node}
            title={membershipInfo.title}
            backgroundColor={bgColor}
            formAligntRight={membershipInfo.formAlignRight}
            imageLow={imageLow}
            images={images}
            alt={alt}
            subtitle={membershipInfo.subtitle}
            otherDescription={membershipInfo.moneyBuyDescription4}
            donateLink={membershipInfo.donationUrl}
            mbshipID={`mbship-${membershipInfo.id}`}
          />
        )
      );
    }}
  />
);

MembershipSignup.propTypes = {
  membershipInfo: PropTypes.objectOf(PropTypes.shape).isRequired
};
export default MembershipSignup;
