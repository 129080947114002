import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { snakeCase } from 'lodash';
import {
  Promo as PromoCL
} from '@comicrelief/component-library';
import { breakpointValues } from '@comicrelief/component-library/src/theme/shared/allBreakpoints';

import renderRichText from '../RichText/RichText';

const PromoHeaderWrapper = styled.div`
  display: flex;
  margin: 0;
  background: ${({ edgeColour, theme }) => theme.color(edgeColour)};
  p a {
    width: auto;
  }
`;

const Promo = ({ data }) => {
  const {
    backgroundImage,
    copyLeft,
    body,
    // Let's give this a sensible name, eh?
    addOverlay: blackText,
    backgroundColourMobile,
    mobileBackgroundImage,
    tabletBackgroundImage,
    type,
    edgeColour,
    videoShowPosterImageAfterPlaying,
    videoAutoplay,
    videoBehindTextGradient,
    videoLoop,
    videoDesktopVideoFile,
    videoDesktopVideoPosterImage,
    videoMobileVideoFile,
    videoMobileVideoPosterImage,
    mobileTextColour,
    buttonColour,
    mobileButtonColour
  } = data;
  // setup mobile image variables
  const imageLowMobile = mobileBackgroundImage?.placeHolder?.src;
  const mobileImages = mobileBackgroundImage?.fluid?.srcSet;

  // setup tablet image variables
  const imageLowTablet = tabletBackgroundImage?.placeHolder?.src;
  const tabletImages = tabletBackgroundImage?.fluid?.srcSet;

  // setup desktop image variables
  const imageLow = backgroundImage?.placeHolder?.src;
  const images = backgroundImage?.fluid?.srcSet;

  // shared by all images
  const image = backgroundImage?.fallback?.src;
  const imagesDesc = backgroundImage?.description;

  const desktopVideo = videoDesktopVideoFile?.file?.url;
  const desktopPoster = videoDesktopVideoPosterImage?.file?.url;
  const mobileVideo = videoMobileVideoFile?.file?.url;
  const mobilePoster = videoMobileVideoPosterImage?.file?.url;
  const mobileBgColour = snakeCase(backgroundColourMobile);

  // Although the bgColour isn't really SHOWN for Promos on 'desktop'
  // (as it's a fullwidth bg image), we still need require it for
  // the 'THIS bg colour gets THIS text colour' logic, so let's use
  // CMS-defined text colour to give us a suitable bg colour:
  const thisDesktopBgColour = blackText ? 'white' : 'black';

  const thisButtonColour = snakeCase(buttonColour);
  const thisMobileButtonColour = snakeCase(mobileButtonColour);

  const hasVideo = desktopVideo || mobileVideo;
  let behindTextGradient = 'none';
  let thisMobileTextColor = null;

  if (videoBehindTextGradient) {
    behindTextGradient = blackText ? 'white' : 'black';
  }

  // Cast the CMS 'mobileTextColour' boolean value ('black'
  // as true in the CMS) to one of our colour values:
  thisMobileTextColor = mobileTextColour ? 'black' : 'white';

  const thisPosition = type && type.toLowerCase();

  // Non-video Promos:
  if (!hasVideo) {
    return (
      <PromoHeaderWrapper
        edgeColour={snakeCase(edgeColour)}
        position={thisPosition}
      >
        {/* 'Mobile & Tablet' view */}
        <MediaQuery maxWidth={breakpointValues.M - 1}>
          <PromoCL
            backgroundColor={mobileBgColour}
            imageLow={imageLowMobile}
            imageSet={mobileImages}
            image={image}
            imageAltText={imagesDesc}
            copyLeft={copyLeft}
            position={thisPosition}
          >
            {renderRichText(body, mobileBgColour, 'singleMessage', thisMobileTextColor, thisButtonColour, thisMobileButtonColour)}
          </PromoCL>
        </MediaQuery>

        {/* 'Tablet' img if provided */}
        {tabletBackgroundImage
          && (
            <MediaQuery
              minWidth={breakpointValues.M}
              maxWidth={breakpointValues.L - 1}
            >
              <PromoCL
                backgroundColor={mobileBgColour}
                imageLow={imageLowTablet}
                imageSet={tabletImages}
                image={image}
                imageAltText={imagesDesc}
                copyLeft={copyLeft}
                position={thisPosition}
              >
                {renderRichText(body, mobileBgColour, 'singleMessage', thisMobileTextColor, thisButtonColour, thisMobileButtonColour)}
              </PromoCL>
            </MediaQuery>
          )}

        {/* 'Desktop' promo, also covering Tablet when appropriate */}
        <MediaQuery minWidth={tabletBackgroundImage ? breakpointValues.L : breakpointValues.M}>
          <PromoCL
            style={{ border: '3px solid green' }}
            backgroundColor={snakeCase(backgroundColourMobile)}
            imageLow={imageLow}
            imageSet={images}
            image={image}
            imageAltText={imagesDesc}
            copyLeft={copyLeft}
            position={thisPosition}
          >
            {renderRichText(body, thisDesktopBgColour, 'singleMessage', thisMobileTextColor, thisButtonColour, thisMobileButtonColour)}
          </PromoCL>
        </MediaQuery>
      </PromoHeaderWrapper>
    );
  }
  // As the (non-required) video elements will override any (non-required)
  // *image* elements in both function and renderer in the component,
  // we can remove the image prop assignment entirely for a video-y Promo:
  return (
    <PromoHeaderWrapper edgeColour={snakeCase(edgeColour)} position={type}>
      <PromoCL
        backgroundColor={snakeCase(backgroundColourMobile)}
        copyLeft={copyLeft}
        position={thisPosition}
        autoPlay={videoAutoplay}
        loop={videoLoop}
        showPosterAfterPlaying={videoShowPosterImageAfterPlaying}
        videoSrc={desktopVideo}
        poster={desktopPoster}
        mobileVideoSrc={mobileVideo}
        mobilePoster={mobilePoster}
        behindTextGradient={behindTextGradient}
        blackPlayButton={blackText}
      >
        {renderRichText(body, thisDesktopBgColour, 'singleMessage', thisMobileTextColor, thisButtonColour, thisMobileButtonColour)}
      </PromoCL>
    </PromoHeaderWrapper>

  );
};

Promo.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.shape({
      raw: PropTypes.string
    }),
    mobileTextColour: PropTypes.bool,
    backgroundImage: PropTypes.shape({
      placeHolder: PropTypes.shape({
        src: PropTypes.string
      }),
      fallback: PropTypes.shape({
        src: PropTypes.string
      }),
      fluid: PropTypes.shape({
        srcSet: PropTypes.string
      }),
      description: PropTypes.string
    }),
    mobileBackgroundImage: PropTypes.shape({
      placeHolder: PropTypes.shape({
        src: PropTypes.string
      }),
      fallback: PropTypes.shape({
        src: PropTypes.string
      }),
      fluid: PropTypes.shape({
        srcSet: PropTypes.string
      })
    }),
    tabletBackgroundImage: PropTypes.shape({
      placeHolder: PropTypes.shape({
        src: PropTypes.string
      }),
      fallback: PropTypes.shape({
        src: PropTypes.string
      }),
      fluid: PropTypes.shape({
        srcSet: PropTypes.string
      })
    }),
    edgeColour: PropTypes.string,
    backgroundColourMobile: PropTypes.string,
    type: PropTypes.string,
    copyLeft: PropTypes.bool,
    addOverlay: PropTypes.bool,
    buttonColour: PropTypes.string,
    mobileButtonColour: PropTypes.string,
    // Video-specific props:
    videoBehindTextGradient: PropTypes.bool,
    videoShowPosterImageAfterPlaying: PropTypes.bool,
    videoAutoplay: PropTypes.bool,
    videoLoop: PropTypes.bool,
    videoDesktopVideoFile: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    videoDesktopVideoPosterImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    videoMobileVideoFile: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    }),
    videoMobileVideoPosterImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    })
  }).isRequired
};

export default Promo;
