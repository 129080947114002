import React from 'react';
import PropTypes from 'prop-types';
import Step1 from './Step1';
import Step2and3 from './Step2and3';
import Step4 from './Step4';
import MiniNavbar from './MiniNavbar';
import BottomProgressBar from './BottomProgressBar';
import shuffle from '../../utils/shuffleArray';
import useSoundEffects from './SoundEffects';
import {
  StyledOuterContainer,
  StyledInnerContainer
} from './JokeGenShared.styles';

/**
   * Number Of Jokes before Exit Page / Step 4 will appear
 */
const numberOfJokes = 4;

export default function JokeGenerator({ data }) {
  const [step, setStep] = React.useState(1);
  const [jokeIndex, setJokeIndex] = React.useState(0);
  const [noseChoice, setNoseChoice] = React.useState(1);
  const [soundOn, setSoundOn] = React.useState(false);
  const [exitMessageNumber, setExitMessageNumber] = React.useState(0);
  const [backgroundSplash, setBackgroundSplash] = React.useState(true);

  const { laughSounds, stopAllSounds, playSounds } = useSoundEffects();

  /**
   * Shuffle jokes as they come in
   */
  React.useEffect(() => {
    if (data) {
      shuffle(data.jokes);
      shuffle(data.exitPages);
    }
  }, []);

  const startNewJoke = () => {
    setStep(2);

    if (jokeIndex === (data.jokes.length - 1)) {
      setJokeIndex(0);
    } else {
      setJokeIndex(jokeIndex + 1);
    }

    if (noseChoice === 4) {
      setNoseChoice(1);
    } else {
      setNoseChoice(noseChoice + 1);
    }

    stopAllSounds();

    if (soundOn === true) {
      playSounds(true, 'step2');
    }
  };

  const laughSound = () => {
    if (noseChoice === 1) {
      laughSounds[0].play();
    }
    if (noseChoice === 2) {
      laughSounds[1].play();
    }
    if (noseChoice === 3) {
      laughSounds[2].play();
    }
    if (noseChoice === 4) {
      laughSounds[3].play();
    }
  };

  /**
   * Setting background / icon colour based on step
   */
  let iconColor;
  switch (step) {
    case 1:
      iconColor = ({ theme }) => theme.color('rnd_24_red_dark');
      break;
    case 4:
      iconColor = ({ theme }) => theme.color('rnd_24_pink_dark');
      break;
    default:
      iconColor = ({ theme }) => theme.color('rnd_24_teal_dark');
  }

  return (
    <StyledOuterContainer
      step={step}
      backgroundSplash={backgroundSplash}
      iconColor={iconColor}
    >
      <StyledInnerContainer>

        {(step !== 1)
          && (
          <MiniNavbar
            step={step}
            setStep={setStep}
            soundOn={soundOn}
            setSoundOn={setSoundOn}
            iconColor={iconColor}
            jokeIndex={jokeIndex}
            setJokeIndex={setJokeIndex}
            numberOfJokes={numberOfJokes}
            stopAllSounds={stopAllSounds}
            playSounds={playSounds}
            laughSound={laughSound}
            setBackgroundSplash={setBackgroundSplash}
          />
          )}

        {(step === 1)
          && (
            <Step1
              step={step}
              setStep={setStep}
              soundOn={soundOn}
              setSoundOn={setSoundOn}
              iconColor={iconColor}
              noseChoice={noseChoice}
              playSounds={playSounds}
            />
          )}

        {(step === 2 || step === 3)
          && (
          <Step2and3
            step={step}
            setStep={setStep}
            soundOn={soundOn}
            jokes={data.jokes}
            jokeIndex={jokeIndex}
            numberOfJokes={numberOfJokes}
            startNewJoke={startNewJoke}
            laughSound={laughSound}
            setBackgroundSplash={setBackgroundSplash}
            noseChoice={noseChoice}
            setNoseChoice={setNoseChoice}
            playSounds={playSounds}
          />
          )}

        {(step === 4)
          && (
          <Step4
            startNewJoke={startNewJoke}
            exitMessageNumber={exitMessageNumber}
            setExitMessageNumber={setExitMessageNumber}
            exitPageData={data.exitPages}
            noseChoice={noseChoice}
            setNoseChoice={setNoseChoice}
            jokeIndex={jokeIndex}
            step={step}
          />
          )}

        {(step === 2 || step === 3)
          && (
            <BottomProgressBar step={step} />
          )}

      </StyledInnerContainer>
    </StyledOuterContainer>
  );
}

JokeGenerator.propTypes = {
  data: PropTypes.shape({
    jokes: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired
    })).isRequired,
    exitPages: PropTypes.arrayOf(PropTypes.shape({
      heading: PropTypes.string.isRequired,
      tagLine: PropTypes.string.isRequired,
      donateButtonText: PropTypes.string.isRequired,
      moreJokesButtonText: PropTypes.string.isRequired,
      donateButtonLink: PropTypes.string.isRequired
    })).isRequired
  }).isRequired
};
