import React from 'react';

export default function Cross() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.6059 18.4153C12.3632 11.3637 7.36324 8.18185 2.5752 1.58484" stroke="#1D1D1B" strokeWidth="2.72727" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.67543 17.5155C7.78753 13.2608 10.9996 9.09096 18.5059 2.48474" stroke="#1D1D1B" strokeWidth="2.72727" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
