import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledProgressBar = styled.div`
  margin: 40px auto 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.div`
  ${({ active }) => active && css`
    background-color: ${({ theme }) => theme.color('red')};
    color: ${({ theme }) => theme.color('grey_light')};
  `};

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
  border: 2px solid black;
  --circle-size: calc(38px + 4vw);
  height: var(--circle-size);
  width: var(--circle-size);
  font-size: calc(8px + 1vw);

  /* 740px */
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    border-width: 3px;
    --circle-size: 90px;
    font-size: 20px;
  };

  /* 1024px */
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    border-width: 4px;
    --circle-size: 120px;
    font-size: 24px;
  };
`;

const Line = styled.div`
  background: ${({ theme }) => theme.color('grey_dark')};
  width: calc(20px + 6vw);
  height: 2px;
  margin: calc(4px + 0.4vw);

  /* 740px */
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: 80px;
    height: 3px;
    margin: 8px;
  };

  /* 1024px */
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    width: 100px;
    height: 4px;
    margin: 12px;
  };
`;

const ProgressBar = ({ step }) => (
  <StyledProgressBar data-test="progress-bar">

    <Circle active data-test="circle-step-1">
      <div>Step</div>
      <div>1</div>
    </Circle>

    <Line />

    <Circle active={step !== 1 && true} data-test="circle-step-2">
      <div>Step</div>
      <div>2</div>
    </Circle>

    <Line />

    <Circle active={step === 3 && true} data-test="circle-step-3">
      <div>Step</div>
      <div>3</div>
    </Circle>

  </StyledProgressBar>
);

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired
};

export default ProgressBar;
