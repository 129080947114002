import styled, { keyframes } from 'styled-components';
import { containers, spacing } from '@comicrelief/component-library';

import clear from './assets/clear.svg';

const animate = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LabelGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    @media ${({ theme }) => theme.allBreakpoints('M')} {
      margin: ${spacing('md')} ${spacing('md')} 0px 0px;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: ${containers.medium};
  margin: 0 auto;
  padding: ${spacing('md')};
  flex-direction: column;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin: ${spacing('l')} auto;
  }
`;

const Header = styled.div`
  margin: ${spacing('l')} 0;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin: ${spacing('md')} ${spacing('md')} ${spacing('xl')};
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    flex-direction: row;
  }
`;

const Item = styled.div`
  margin: 0 0 ${spacing('l')};
  width: 100%;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: calc(50% - ${spacing('l')});
    margin: ${spacing('md')};
  }
  animation: ${animate} 0.5s linear;
`;

const TotalText = styled.div`
  padding: ${spacing('md')};
  margin-bottom: ${spacing('md')};
`;

const ClearButton = styled.button`
  border-radius: 2rem;
  background: none;
  padding: ${spacing('sm')} ${spacing('md')};
  margin: ${spacing('md')} 0 0 0;
  border: 1px solid ${({ theme }) => theme.color('grey_medium')};
  outline: none;
  padding-left: 3rem;
  position: relative;
  height: 48px;
  :focus {
    border-color: ${({ theme }) => theme.color('grey_for_forms')};
  }
  :before {
    content: '';
    background: url(${clear}) no-repeat center;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: 1rem;
  }
`;

const SpinnerHolder = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export {
  LabelGroup,
  Container,
  Header,
  Grid,
  Item,
  ClearButton,
  TotalText,
  SpinnerHolder
};
