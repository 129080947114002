import React from 'react';
import { Text, Link } from '@comicrelief/component-library';

const marketingPrefencesCopyTop = (
  <div>
    <Text tag="h3" size="xl" family="Anton" uppercase weight="400" color="grey_dark">
      Before you go…
    </Text>
    <Text tag="p" size="md" color="grey_dark">
      We’d love to stay in touch so we can provide you with fundraising tips, news updates and more.
      Please select your preferred contact options below.
    </Text>
  </div>
);

const marketingPrefencesCopyBottom = (
  <Text tag="p" color="grey_dark">
    You can update your communication preferences at any time at
    {' '}
    <Link
      type="standard"
      href="https://www.comicrelief.com/update-your-preferences"
      target="blank"
    >
      update your preferences
    </Link>
    .
  </Text>
);

export { marketingPrefencesCopyTop, marketingPrefencesCopyBottom };
