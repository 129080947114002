import styled from 'styled-components';

const Form = styled.form`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CenterSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Column = styled.div`
  width: 25vw;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StickerDarkWrapper = styled.div`
  width: 158px;
  align-self: start;
`;

const StickerLightWrapper = styled(StickerDarkWrapper)`
  align-self: end;
`;

const PointerWrapperLeft = styled.div`
  width: 20vw;
  max-width: 200px;
  align-self: end;
`;

const PointerWrapperRight = styled(PointerWrapperLeft)`
  transform: scaleY(-1) scaleX(-1);
  align-self: start;
`;

export {
  CenterSection,
  Column,
  StickerDarkWrapper,
  StickerLightWrapper,
  Form,
  PointerWrapperLeft,
  PointerWrapperRight
};
