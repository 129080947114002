import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import MainHeading from './MainHeading';

const StyledInnerContainer4 = styled.div`
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const StyledPara = styled.div`
  width: 85%;
  text-align: center;
  font-size: 1rem;
  margin-top: 10px;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    font-size: 1.2rem;
    margin-top: 15px;
  }
`;

const buttonStyles = css`
  height: 70px;
  font-size: 1.6em;
  margin-top: 18px;
  box-shadow: 8px 10px rgb(0 0 0 / 0.4);
  width: 85%;
  height: 80px;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    font-size: 1.8rem;
    margin-top: 4vh;
    box-shadow: 12px 14px rgb(0 0 0 / 0.4);
    width: 75%;
  }
  font-weight: 900;
  border: 3px solid ${({ theme }) => theme.color('white')};
  border-radius: 50px;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover, &:active, &:focus {
    box-shadow: 8px 6px rgb(0 0 0 / 0.4);
    transform: translate(4px, 3px);
  }
`;

const StyledDonateLink = styled.a`
  ${buttonStyles}
  background: ${({ theme }) => theme.color('red')};
  color: ${({ theme }) => theme.color('white')};
  text-decoration: none;
`;

const StyledMoreJokesButton = styled.button`
  ${buttonStyles}
  background: transparent;
  color: ${({ theme }) => theme.color('rnd_23_black')};
`;

const StyledTandCs = styled.div`
  margin-top: 3vh;
  width: 90%;
  text-align: center;
`;

export default function Step4({
  startNewJoke,
  exitMessageNumber,
  setExitMessageNumber,
  exitPageData,
  jokeIndex,
  noseChoice,
  setNoseChoice,
  step
}) {
  React.useEffect(() => {
    window.dataLayer.push({
      step: '4',
      exitPageHeading: exitPageData[exitMessageNumber].heading
    });
  }, []);

  const handleRestart = () => {
    if (exitMessageNumber === exitPageData.length - 1) {
      setExitMessageNumber(0);
    } else {
      setExitMessageNumber(exitMessageNumber + 1);
    }
    setNoseChoice(noseChoice + 1);
    startNewJoke();
  };

  // track which exit page is shown when clicking link to donate
  const trackExitToDonate = () => {
    window.dataLayer.push({
      event: 'clickCTA',
      currentJoke: jokeIndex,
      exitPageHeading: exitPageData[exitMessageNumber].heading,
      buttonCTAtext: exitPageData[exitMessageNumber].donateButtonText
    });
  };

  return (
    <StyledInnerContainer4>

      <MainHeading
        headingText={exitPageData[exitMessageNumber].heading}
        headingColor={({ theme }) => theme.color('rnd_23_black')}
        step={step}
      />

      <StyledPara>{exitPageData[exitMessageNumber].tagLine}</StyledPara>

      <StyledDonateLink
        onClick={trackExitToDonate}
        href={exitPageData[exitMessageNumber].donateButtonLink}
      >
        {exitPageData[exitMessageNumber].donateButtonText}
      </StyledDonateLink>

      <StyledMoreJokesButton onClick={() => handleRestart()}>
        {exitPageData[exitMessageNumber].moreJokesButtonText}
      </StyledMoreJokesButton>

      <StyledTandCs>
        <span>You must be 16 or over and have the bill payer&apos;s permission</span>
        <span> to make any purchase or donation.</span>
      </StyledTandCs>

    </StyledInnerContainer4>
  );
}

Step4.propTypes = {
  exitPageData: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string.isRequired,
    tagLine: PropTypes.string.isRequired,
    donateButtonText: PropTypes.string.isRequired,
    moreJokesButtonText: PropTypes.string.isRequired,
    donateButtonLink: PropTypes.string.isRequired
  })).isRequired,
  startNewJoke: PropTypes.func.isRequired,
  exitMessageNumber: PropTypes.number.isRequired,
  setExitMessageNumber: PropTypes.func.isRequired,
  noseChoice: PropTypes.number.isRequired,
  setNoseChoice: PropTypes.func.isRequired,
  jokeIndex: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired
};
