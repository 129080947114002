/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ChallengeDetails from './ChallengeDetails';
import Navbar from './Navbar';
import Column from './Column';
import {
  ContentSection,
  CenterSection,
  RectangleWhiteBackground,
  RectangleHandDrawn,
  VideoWrapper
} from './step2Styles';

export default function Step2and3({
  challengesData,
  finalMessage,
  step,
  setStep,
  setShowPopUp
}) {
  const [challengeNumber, setChallengeNumber] = useState(0);
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [challengeNumber, step]);

  // Initialize array to temp hold priority vids
  const priorityVideos = [];

  // Find the priority videos
  challengesData.forEach((item, index) => {
    if (item.priority === true) {
      // Push the object to the array
      priorityVideos.push(item);
      // Remove the object from its current position
      challengesData.splice(index, 1);
    }
  });

  // Move priority videos to the beginning of the array
  priorityVideos.forEach((object) => {
    challengesData.unshift(object);
  });

  return (
    <>

      <Navbar step={step} setStep={setStep} />

      <ContentSection>

        <Column
          step={step}
          setStep={setStep}
          setShowPopUp={setShowPopUp}
          positionLeft
          challengeNumber={challengeNumber}
          setChallengeNumber={setChallengeNumber}
          challenges={challengesData}
        />

        <CenterSection>
          <RectangleWhiteBackground>
            <RectangleHandDrawn>
              <VideoWrapper>
                {challengesData && finalMessage && (
                  <video ref={videoRef} controls autoPlay>
                    <source
                      src={
                        step === 2
                          ? challengesData[challengeNumber].video.file.url
                          : finalMessage[0].video.file.url
                      }
                      type="video/mp4"
                    />
                  </video>
                )}
              </VideoWrapper>
            </RectangleHandDrawn>
          </RectangleWhiteBackground>
        </CenterSection>

        <Column
          step={step}
          setStep={setStep}
          challengeNumber={challengeNumber}
          setChallengeNumber={setChallengeNumber}
          positionLeft={false}
          setShowPopUp={setShowPopUp}
          challenges={challengesData}
        />

      </ContentSection>

      {challengesData && finalMessage && (
        <ChallengeDetails
          title={
            step === 2
              ? challengesData[challengeNumber].title
              : finalMessage[0].title
          }
          subtitle={
            step === 2
              ? challengesData[challengeNumber].subtitle
              : finalMessage[0].subtitle
          }
        />
      )}
    </>
  );
}

Step2and3.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  setShowPopUp: PropTypes.func.isRequired,
  challengesData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      video: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    })
  ).isRequired,
  finalMessage: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      video: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    })
  ).isRequired
};
