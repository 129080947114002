import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  zIndex,
  ThemeProvider,
  crTheme,
  srTheme,
  hideVisually,
  Link
} from '@comicrelief/component-library';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import 'lazysizes';

import '@comicrelief/component-library/dist/theme/shared/global.css';

const Wrapper = styled.div`
  margin-top: 75px;
  @media ${({ theme }) => theme.allBreakpoints('Nav')} {
    margin-top: 90px;
  }
  font-family: ${({ theme }) => theme.fontFamilies(theme.font.regular)};
  h1 {
    word-break: normal;
  }
  h1, h2, h3 {
    font-family: ${({ theme }) => theme.fontFamilies(theme.font.bold)};
    font-weight: normal;
  }
  background: ${props => props.background};
`;

const SkipNav = styled(Link)`
  ${hideVisually};
  text-decoration: none;
  top: 0;
  :focus {
    margin: 0;
    left: 50%;
    ${zIndex('higher')};
   line-height: 2rem;
    clip: auto;
    clip-path: none;
    overflow: visible;
  }
`;

const Main = styled.main`
  display: block;
`;

const Layout = ({
  children,
  removeDonateLink = false,
  theme = 'comicrelief',
  landingPageCartId = null,
  background = ''
}) => {
  let thisDate = new Date();
  thisDate = thisDate.getMonth();
  // The Date object represents the months as 0-11, so '5' is equal to June
  const isPride = thisDate === 5;
  const themeIsCR = theme === 'comicrelief';
  return (
    <ThemeProvider theme={themeIsCR || theme === 'bignightin' ? crTheme : srTheme}>
      <SkipNav href="#main-content">
        Skip to main content
      </SkipNav>
      <Wrapper background={background}>
        <Header
          removeDonateLink={removeDonateLink}
          campaign={isPride && themeIsCR ? 'pride' : theme}
          landingPageCartId={landingPageCartId}
        />
        <Main role="main" id="main-content">
          {children}
        </Main>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  removeDonateLink: PropTypes.bool,
  theme: PropTypes.string,
  landingPageCartId: PropTypes.string,
  background: PropTypes.string
};

export default Layout;
