import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { snakeCase } from 'lodash';

import { Picture, spacing } from '@comicrelief/component-library';
import renderRichText from '../RichText/RichText';

const Wrapper = styled.div`
  background: ${({ theme, bgColor }) => theme.color(snakeCase(bgColor))};
  padding: ${spacing('l')} ${spacing('md')};
  text-align: center;
`;

const ItemWrapper = styled.div`
  margin: 0 auto;
  padding: ${spacing('l')} 0;
  max-width: 900px;
  height: auto;
  text-align: center;
`;

class IframeBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageClicked: false,
      iframeHeight: null
    };
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.getIframeSize);
  }

  getIframeSize = (e) => {
    const allowedOrigin = new RegExp('https:\\/\\/[A-Za-z-.]{1,}.comicrelief.com$');
    if (typeof e.data === 'string' && e.origin.match(allowedOrigin)) {
      const json = JSON.parse(e.data);
      if (typeof json.iframe_height !== 'undefined') {
        const iframeHeight = json.iframe_height;
        this.setState((prevState) => {
          let newState;
          if (prevState.iframeHeight !== iframeHeight) {
            newState = {
              iframeHeight
            };
          }
          return newState;
        });
      }
    }
  };

  render() {
    const { data } = this.props;
    const {
      title,
      backgroundColour,
      body,
      iframeLoaded,
      initialHeight,
      embedLink,
      nonProductionEmbedLink,
      placeholderImage,
      placeholderImageAltText
    } = data;

    // Check against specific GATSBY_ENVIRONMENT value set within Concourse for the production build
    const thisURL = nonProductionEmbedLink && process.env.GATSBY_ENVIRONMENT !== 'production'
      ? nonProductionEmbedLink : embedLink;

    const { isImageClicked, iframeHeight } = this.state;

    return (
      <Wrapper bgColor={backgroundColour}>
        {body && <ItemWrapper>{renderRichText(body, 'inherit', 'iframe')}</ItemWrapper>}
        {iframeLoaded === true || isImageClicked === true ? (
          <iframe
            title={title}
            width="100%"
            frameBorder="0"
            height={iframeHeight === null ? initialHeight : iframeHeight}
            src={thisURL}
            allowFullScreen
            onLoad={() => {
              window.addEventListener('message', this.getIframeSize, false);
            }}
          />
        ) : (
          <ItemWrapper>
            <a
              href={embedLink}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  isImageClicked: true
                });
              }}
            >
              <Picture
                alt={placeholderImageAltText}
                imageLow={placeholderImage.placeHolder.src}
                images={placeholderImage.fluid.srcSet}
                objectFit="cover"
              />
            </a>
          </ItemWrapper>
        )}
      </Wrapper>
    );
  }
}

IframeBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    backgroundColour: PropTypes.string,
    body: PropTypes.shape({
      json: PropTypes.shape({})
    }),
    iframeLoaded: PropTypes.bool,
    initialHeight: PropTypes.number,
    embedLink: PropTypes.string,
    nonProductionEmbedLink: PropTypes.string,
    placeholderImage: PropTypes.shape({
      fluid: PropTypes.shape({
        srcSet: PropTypes.string
      }),
      placeHolder: PropTypes.shape({
        src: PropTypes.string
      }),
      seoImage: PropTypes.shape({
        src: PropTypes.string
      })
    }),
    placeholderImageAltText: PropTypes.string
  }).isRequired
};

export default IframeBlock;
