import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { snakeCase } from 'lodash';
import {
  Text, SingleMessage as SingleMessageBase, spacing
} from '@comicrelief/component-library';

import { changeTextColor } from '../RichText/utils';
import renderRichText from '../RichText/RichText';

const InnerCopy = styled.span`
  ${({ centredText }) => (centredText ? 'text-align: center;' : 'text-align: left;')}
  ul,
  ol {
    color: ${({ bgColor }) => changeTextColor(bgColor)};
    font-family: 'Montserrat',Helvetica,Arial,sans-serif;

    li {
      word-break: break-word;
      :not(:last-child) {
        padding-bottom: ${spacing('md')};
      }
    }
  }
  hr {
    color: ${({ bgColor }) => changeTextColor(bgColor)};
    border: 1px solid ${({ bgColor }) => changeTextColor(bgColor)};
    margin: ${spacing('md')} 0;
  }
  img {
    margin-bottom: ${spacing('l')};
  }
`;

const Label = styled(Text)`
  display: block;
  margin-bottom: ${spacing('sm')};
`;

const SingleMessage = ({
  id,
  title,
  image,
  backgroundColour = 'white',
  text = null,
  label = null,
  copyFirst = false,
  fullImage = false,
  centredText = false,
  secondaryImage = null,
  verticalHeight = false,
  youTubeVideoId = null,
  landscapeVideo = false,
  paddingOptions = null
}) => {
  const imageLow = image ? image.placeHolder?.src : null;
  const fallbackImage = image ? image.fallback?.src : null;
  const images = image ? image.fluid?.srcSet : null;
  const imagesDesc = image ? image.description : null;
  const image2 = secondaryImage ? secondaryImage.placeHolder.src : null;
  const images2 = secondaryImage ? secondaryImage.fluid.srcSet : null;
  const images2Desc = secondaryImage ? secondaryImage.description : null;
  const thisBgColour = backgroundColour ? snakeCase(backgroundColour) : null;
  const paddingOption = paddingOptions ? snakeCase(paddingOptions) : null;

  return (
    <SingleMessageBase
      key={id}
      title={title}
      backgroundColor={thisBgColour}
      imageLow={imageLow}
      imageSet={images}
      image={fallbackImage}
      imageAltText={imagesDesc}
      imageSet2={images2}
      image2={image2}
      imageAltText2={images2Desc}
      copyFirst={copyFirst}
      fullImage={fullImage}
      vhFull={verticalHeight}
      videoID={youTubeVideoId}
      landscapeVideo={landscapeVideo}
      paddingOption={paddingOption}
    >
      {label || text
        ? (
          <InnerCopy centredText={centredText} bgColor={thisBgColour}>
            {label ? (
              <Label
                tag="span"
                size="s"
                color={changeTextColor(thisBgColour)}
                uppercase
              >
                {label}
              </Label>
            ) : null}
            {text && renderRichText(text, thisBgColour, 'singleMessage')}
          </InnerCopy>
        ) : null}
    </SingleMessageBase>
  );
};

SingleMessage.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  text: PropTypes.shape({
    raw: PropTypes.string
  }),
  image: PropTypes.shape({
    placeHolder: PropTypes.shape({
      src: PropTypes.string
    }),
    fallback: PropTypes.shape({
      src: PropTypes.string
    }),
    fluid: PropTypes.shape({
      srcSet: PropTypes.string
    }),
    description: PropTypes.string
  }).isRequired,
  secondaryImage: PropTypes.shape({
    placeHolder: PropTypes.shape({
      src: PropTypes.string
    }),
    fallback: PropTypes.shape({
      src: PropTypes.string
    }),
    fluid: PropTypes.shape({
      srcSet: PropTypes.string
    }),
    description: PropTypes.string
  }),
  backgroundColour: PropTypes.string,
  centredText: PropTypes.bool,
  copyFirst: PropTypes.bool,
  fullImage: PropTypes.bool,
  verticalHeight: PropTypes.bool,
  youTubeVideoId: PropTypes.string,
  landscapeVideo: PropTypes.bool,
  paddingOptions: PropTypes.string
};

export default SingleMessage;
