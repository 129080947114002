import React from 'react';
import qs from 'qs';
import { Paging } from '@elastic/react-search-ui';
import { Pagination } from '@comicrelief/component-library';

const CustomPagination = () => (
  <Paging
    view={
      ({ totalPages, current, onChange }) => (
        <Pagination
          disabledBackgroundColor="grey_dark"
          disabledColor="grey_light"
          totalPages={totalPages}
          currentPage={current}
          createURL={(value) => {
            const { origin, pathname, search } = window.location;
            const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
            queryParams.current = `n_${value}_n`;
            const updatedSearch = qs.stringify(queryParams, { addQueryPrefix: true });
            return `${origin}${pathname}${updatedSearch}`;
          }}
          onSelect={(event, value) => {
            event.preventDefault();
            onChange(value);
          }}
          showFirst
          showPrevious
          showNext
          showLast
          showMore
        />
      )
    }

  />

);

export default CustomPagination;
