// function for returning a shuffled array
export default function shuffle(array) {
  // reassignment to get round eslint
  const shuffledArray = array;
  let m = shuffledArray.length;
  let t;
  let i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * (m -= 1));

    // And swap it with the current element.
    t = shuffledArray[m];
    shuffledArray[m] = shuffledArray[i];
    shuffledArray[i] = t;
  }

  return shuffledArray;
}
