import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  --button-size: 130px;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    --button-size: 140px;
  }
  height: var(--button-size);
  width: var(--button-size);
  background: ${props => (props.step === 1
    ? ({ theme }) => theme.color('white')
    : ({ theme }) => theme.color('red'))
};
  border: 3px solid;
  border-color: ${props => (props.step === 1
    ? ({ theme }) => theme.color('rnd_24_red_dark')
    : ({ theme }) => theme.color('white'))
};
  top: 83%;
  border-radius: 100%;
  box-shadow: 12px 8px ${props => (props.step === 1
    ? ({ theme }) => theme.color('rnd_24_red_dark')
    : ({ theme }) => theme.color('rnd_24_teal_dark'))
};
  transition: 0.1s;
  > button > div > span {
    transition: 0.1s;
  }
  &:hover, &:active {
    background: ${props => (props.step === 1
    ? '#E9E7E7'
    : ({ theme }) => theme.color('rnd_24_red_dark'))};
    box-shadow: 8px 6px rgb(0, 0, 0, 0.4);
    transform: translate(4px, 3px);
    > button > div > span:nth-child(1) {
      margin-bottom: 0.9rem;
    }
    > button > div > span:nth-child(3) {
      margin-bottom: 1rem;
    }
    > button > div > span:nth-child(5) {
      margin-bottom: -0.8rem;
    }
    > button > div > span {
      transition: 0.2s;
    }
  }
`;

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  font-family: Anton;
  text-transform: uppercase;
  background: transparent;
  border: none;
  color: ${props => (props.step === 1
    ? ({ theme }) => theme.color('red')
    : ({ theme }) => theme.color('white'))}
`;

const StyledButtonText = styled.div`
  filter: brightness(120%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
`;

const StyledLetter1 = styled.span`
  font-size: 2.3rem;
`;

const StyledLetter2 = styled.span`
  font-size: 1.9rem;
`;

const StyledLetter3 = styled.span`
  font-size: 2.1rem;
`;

const styledWord = (text) => {
  const textArray = Array.from(text);
  return (
    textArray.map((letter, index) => {
      switch (index % 3) {
        case (0):
          return <StyledLetter1 key={String(letter + index)} aria-hidden="true">{letter}</StyledLetter1>;
        case (1):
          return <StyledLetter2 key={String(letter + index)} aria-hidden="true">{letter}</StyledLetter2>;
        default:
          return <StyledLetter3 key={String(letter + index)} aria-hidden="true">{letter}</StyledLetter3>;
      }
    })
  );
};

export default function CircleButton({
  step,
  buttonText,
  buttonClick
}) {
  return (
    <StyledRow>
      <StyledButtonWrapper
        step={step}
      >
        <StyledButton
          onClick={buttonClick}
          step={step}
        >
          <StyledButtonText aria-label={buttonText}>
            {styledWord(buttonText)}
          </StyledButtonText>
        </StyledButton>
      </StyledButtonWrapper>
    </StyledRow>
  );
}

CircleButton.propTypes = {
  step: PropTypes.number.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClick: PropTypes.func.isRequired
};
