import React from 'react';
import styled from 'styled-components';
import { Text, spacing } from '@comicrelief/component-library';

const CopyWrapper = styled.div`
  text-align: center;
  padding: ${spacing('xl')} 0;
`;

const ErrorMessage = () => (
  <CopyWrapper>
    <Text tag="p" size="l">
      Sorry, search failed.
    </Text>
    <Text tag="p" size="l">
      Please try searching again later.
    </Text>
  </CopyWrapper>
);

export default ErrorMessage;
