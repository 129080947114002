import moment from 'moment';
import axios from 'axios';
import * as yup from 'yup';

import { getCookie } from '../../templates/FundraiserSignup/utils';
import emailValidation from '../../utils/emailValidation';

/**
 * Returns the Prize /tickets/create schema
 *
 * @returns {yup.Schema}
 */
export const getPrizeTicketsCreateSchema = () => {
  const prizeSchema = yup.object({
    // Related to the user
    supporter: yup.object({
      firstName: yup.string().required('Please enter your first name')
        .matches(/^[A-Za-z][A-Za-z' -]*$/, 'This field only accepts letters and \' - and must start with a letter')
        .max(25, 'Your first name must be between 1 and 25 characters long'),
      lastName: yup.string().required('Please enter your last name')
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9'.,/()& -]*$/, 'This field only accepts alphanumeric characters and , . ( ) / & \' - and must start with an alphanumeric character')
        .max(50, 'Your last name must be between 1 and 50 characters long'),
      email: emailValidation,
      mobile: yup.string().required('Please enter your mobile number')
        .matches(/^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/, 'Please enter a valid mobile number'),
      address1: yup.string().required('Please enter the first line of your address')
        .max(50, 'Please enter a maximum of 50 characters'),
      postcode: yup.string().required('Please enter your postcode')
        .matches(/^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s*\d[a-zA-Z]{2}$/, 'Please enter a valid postcode'),
      town: yup.string().required('Please enter your town'),
      country: yup.string().required()
    }),
    consent: yup.bool()
      .oneOf([true], 'Please accept terms & conditions to enter'),
    // Related to the tickets
    prize_id: yup.string().required(),
    amount: yup.number().integer().min(1).required()
  });

  return prizeSchema;
};

/**
 * Posts to the service platform
 * creating a new prize tickets.
 *
 * @param {object} payload
 * @throws {yup.ValidationError} If payload fails validation
 * @returns {axios.AxiosResponse}
 */
export const queryPrizeTicketsCreate = async (payload) => {
  const thisPayload = payload;
  // Create pointer so we can update the async param, adding in an 'empty'
  // string as Stripe now requires a non-null value for this field,
  // and the Prize form was specifically designed to omit this
  // field for some reason...
  thisPayload.supporter.address2 = ' ';

  const baseUrl = process.env.GATSBY_PRIZE_PLATFORM_URL;

  if (!baseUrl) {
    throw new Error('GATSBY_PRIZE_PLATFORM_URL is undefined.');
  }

  const url = `${baseUrl}/tickets/create`;

  const headers = {
    'Content-Type': 'application/json'
  };

  await getPrizeTicketsCreateSchema().validate(payload);

  return axios.post(url, payload, { headers });
};

export const prizePlatformStatus = () => {
  const baseUrl = process.env.GATSBY_PRIZE_PLATFORM_URL;
  const url = `${baseUrl}/status`;

  if (!baseUrl) {
    throw new Error('GATSBY_PRIZE_PLATFORM_URL is undefined.');
  }

  return axios.get(url);
};

export const prizeStatus = (date) => {
  const todaysdate = moment();
  const prizeDate = moment(date);
  const diffDate = prizeDate.diff(todaysdate, 'days');

  if (diffDate > 1) {
    return `Ends in ${diffDate} days`;
  }

  if (diffDate === 1) {
    return 'Ends tomorrow!';
  }

  if (diffDate === 0) {
    return 'Ends today!';
  }

  return 'closed';
};

/* Used to map Marketing Preferences fields to the
Supporter equivalents stored in localStorage */
export const fieldMappings = {
  mp_address1: 'address1',
  mp_town: 'town',
  mp_postcode: 'postcode',
  mp_country: 'country',
  mp_email: 'email',
  mp_mobile: 'mobile',
  mp_phone: 'mobile'
};

export const handlePrizeCapi = (numberOfEntries, transactionID, prizeID, entryBundles) => {
  const isProd = process.env.GATSBY_ENVIRONMENT === 'production';
  const submit = process.env.GATSBY_ANALYTICS_SUBMIT === 'true';

  // As the 'total' state is reset when Prize remounts after payment,
  // let's determine it based on the supporter detail available.
  let totalCost;

  // Determine the total cost by the available choices and chosen bundle
  entryBundles.forEach((entry) => {
    const numberOfTickets = parseInt(entry.tickets, 10); // cast provided string to int
    if (numberOfTickets === numberOfEntries) {
      totalCost = parseFloat(entry.price).toFixed(2);
    }
  });

  // Given the plans to make the larger ticket bundles work out cheaper per entry,
  // let's determine the *equivalent* price per ticket, as per the CAPI 'Contents' requirements:
  const equivalentPricePerTicket = totalCost / numberOfEntries;

  const fbpValue = getCookie('_fbp');
  const isOptingIn = parseInt(getCookie('OptanonConsent', 'CRCC4'), 10);

  const capiFields = {
    'action-source': 'website',
    contents: [{
      id: prizeID,
      quantity: numberOfEntries,
      item_price: equivalentPricePerTicket
    }],
    'content-type': 'product',
    currency: 'GBP',
    'event-name': isProd ? 'PrizeathonTracking_22' : 'TestEvent',
    'event-source-url': window.location.href,
    'number-of-items': 1,
    'order-id': transactionID,
    'opt-out': !isOptingIn,
    'fbp-browser-id': fbpValue,
    value: `£${totalCost}`
  };

  // Always run on prod, but gives us the ability to test on
  // other envs by temporarily updating the env var
  if (isProd || submit) {
    axios.post(process.env.GATSBY_ANALYTICS_URL, capiFields).catch((error) => {
      // Swallowing any CAPI submission error, as to not break user journey
      if (typeof Sentry !== 'undefined') {
        Sentry.captureException(error);
      }
    });
  }
};

// Handy lil' helper function, using the raw pathname (so stripping out
// the URL params) and forcing a proper reload the page, since a
// straight-up link to the current URL just won't do it with Gatsby:
export const handleRefresh = () => {
  // Scroll to top first, as being scrolled halfway down on refresh is jarring:
  window.scrollTo(0, 0);
  // Refresh the page:
  window.location = window.location.pathname;
};
