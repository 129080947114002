import React from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import {
  CardDs
} from '@comicrelief/component-library';
import renderRichText from '../RichText/RichText';
import CtaLinkIcon from '../CtaLinkIcon/CtaLinkIcon';
import isInternalUrl from '../../utils/isInternalUrl';
import CardsCarousel from './CardsCarousel/CardsCarousel';
import {
  Wrapper,
  Grid,
  Items,
  HideText
} from './CardsDs.style';

const CardsDs = ({ data }) => {
  const {
    title,
    cards,
    backgroundColour: bgCards,
    layout,
    columnsOnMd,
    paddingOptions,
    carouselOfCards
  } = data;

  const cardsBackground = snakeCase(bgCards || 'white');
  const paddingOption = paddingOptions ? snakeCase(paddingOptions) : null;

  return (
    <Wrapper
      bgColour={cardsBackground}
      paddingOption={paddingOption}
      columnsOnMd={columnsOnMd}
    >
      <HideText tag="h2">{title}</HideText>
      <Grid columnsOnMd={columnsOnMd}>

        {(cards && cards.map((card) => {
          const {
            body: cardBody,
            id,
            image,
            backgroundColour,
            link,
            linkLabel
          } = card;

          const fallback = image?.fallback?.src;
          const imageLow = image?.placeHolder?.src;
          const images = image?.fluid?.srcSet;
          const bgColour = snakeCase(backgroundColour || 'white');
          const description = image?.description ? image.description : '';
          const isInternalLink = link && isInternalUrl(link);
          const target = isInternalLink ? 'self' : 'blank';

          return (
            <Items
              key={id}
              layout={layout}
              columnsOnMd={columnsOnMd}
              isCarousel={carouselOfCards}
            >
              <CardDs
                target={target}
                link={link}
                linkLabel={linkLabel}
                imageLow={imageLow}
                image={fallback}
                images={images}
                backgroundColor={bgColour}
                imageAltText={description}
                icon={<CtaLinkIcon url={link} label={linkLabel} />}
              >
                {cardBody && renderRichText(cardBody, bgColour, 'card')}
              </CardDs>
            </Items>
          );
        }))}

        <CardsCarousel
          carouselData={data}
          isCarousel={carouselOfCards}
        />

      </Grid>
    </Wrapper>
  );
};

CardsDs.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        body: PropTypes.shape({
          raw: PropTypes.string.isRequired
        }),
        image: PropTypes.shape({
          fallback: PropTypes.shape({
            src: PropTypes.string
          }),
          placeHolder: PropTypes.shape({
            src: PropTypes.string
          }),
          fluid: PropTypes.shape({
            srcSet: PropTypes.string
          }),
          description: PropTypes.string
        }),
        backgroundColour: PropTypes.string,
        link: PropTypes.string,
        linkLabel: PropTypes.string
      })
    ).isRequired,
    backgroundColour: PropTypes.string,
    layout: PropTypes.string,
    columnsOnMd: PropTypes.bool,
    paddingOptions: PropTypes.string,
    carouselOfCards: PropTypes.bool
  }).isRequired
};

export default CardsDs;
