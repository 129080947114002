import React from 'react';
import styled from 'styled-components';
import {
  Card, Text, spacing, Link
} from '@comicrelief/component-library';
import PropTypes from 'prop-types';

const Section = styled.section.attrs({
  'data-test': 'meet-the-team'
})`
  text-align: center;
  background-color: ${({ theme, color }) => theme.color(color)};
`;

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    flex-direction: row;
    padding: 0;
  }
`;

const Title = styled(Text)`
  padding-top: ${spacing('xl')};
`;

const Item = styled(Card)`
  padding: 0 0 ${spacing('md')};
  margin: 0 0 ${spacing('md')};

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: calc(100% / 2 - ${spacing('l')} - 2 * ${spacing('l')});
    margin: 0 ${spacing('md')} ${spacing('md')};
  }

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    width: calc(100% / 3 - ${spacing('md')} - 2 * ${spacing('md')});
  }
`;

const PersonInfo = styled.div`
  padding-top: ${spacing('l')};
`;

const Container = styled.div`
  width: 100%;
`;

const compare = (a, b) => {
  // Split the full name into array of strings:
  const personAName = a.node.title.toUpperCase().split(' ');
  const personBName = b.node.title.toUpperCase().split(' ');

  // Grab the last part of the name; the surname:
  const surnameA = personAName[personAName.length - 1];
  const surnameB = personBName[personBName.length - 1];

  let comparison = 0;

  // Compare string 'value' of the 2 names:
  if (surnameA > surnameB) {
    comparison = 1;
  } else if (surnameA < surnameB) {
    comparison = -1;
  }
  return comparison;
};

const PeopleList = ({ people }) => {
  const positions = [
    { id: 10, user: 'BOARD OF TRUSTEES', color: 'white' },
    { id: 20, user: 'CEO AND DIRECTORS', color: 'grey_light' },
    { id: 30, user: 'FOUNDER', color: 'white' },
    { id: 40, user: 'LIFE PRESIDENTS', color: 'grey_light' }
  ];

  const team = (user, color) => people.sort(compare).map(({
    node: {
      id, title, role, path, image, type
    }
  }) => {
    const customImage = image?.fixed?.src;
    const lowImage = image?.placeHolder?.src;
    const bgColor = color || 'white';
    const description = image?.description;

    return (
      user.toLowerCase().indexOf(type[0].toLowerCase()) > -1 && (
      <Item
        imageLow={lowImage}
        image={customImage}
        images={customImage}
        key={id}
        backgroundColor={bgColor}
        imageAltText={description}
      >
        <PersonInfo>
          <Text tag="p" color="deep_violet" size="m" weight="bold" uppercase>
            {role}
          </Text>
          <Text tag="h3" color="deep_violet" size="xl">
            <Link href={`/about-comic-relief/${path}`}>{title}</Link>
          </Text>
        </PersonInfo>
      </Item>
      )
    );
  });
  return (
    <Container data-test="meet-the-team-block">
      {positions.map(({ id: index, user, color }) => (
        <Section color={color} key={index}>
          <Title tag="h2" color="deep_violet" size="xxl" uppercase family="Anton">
            {/* Update the title dynamically */}
            { user === 'CEO AND DIRECTORS' && Object.values(team(user, color)).filter(Boolean).length < 2 ? 'CEO' : user }
          </Title>
          <Wrapper>{team(user, color)}</Wrapper>
        </Section>
      ))}
    </Container>
  );
};

PeopleList.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        image: PropTypes.shape({
          fluid: PropTypes.shape({
            srcSet: PropTypes.string.isRequired
          }).isRequired,
          placeHolder: PropTypes.shape({
            src: PropTypes.string.isRequired
          }).isRequired
        }).isRequired
      }).isRequired
    })
  ).isRequired
};

export default PeopleList;
