import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ImpactSlider as ImpactSliderCL } from '@comicrelief/component-library';

import renderRichText from '../RichText/RichText';
import { formatItems, handleScroll, handleUrlAmount } from './_utils';

const ImpactSlider = ({ data }) => {
  const [formattedItems, setFormattedItems] = useState(null);
  const [currentAmount, setCurrentAmount] = useState(null);
  let urlParams;

  const {
    cartId,
    id,
    increment,
    maximum,
    whiteBackground,
    sliderHeading,
    body,
    defaultSliderValue,
    opacityAnimation
  } = data;

  if (typeof window !== 'undefined' && document) {
    urlParams = new URLSearchParams(window.location.search);
  }

  useEffect(() => {
    // If we've got an 'amount' URL param (set by navigating
    // back from Donate), assign the slider's value accordingly:
    const thisAmount = handleUrlAmount(urlParams);
    if (thisAmount) {
      setCurrentAmount(thisAmount);
    } else {
      // Otherwise, if the CMS hasn't provided a 'standard' default,
      // just use the lowest possible value; a single step:
      setCurrentAmount(defaultSliderValue || increment);
    }
    // Format the CMS moneybuy content to suit the component's prop requirements:
    setFormattedItems(formatItems(data));
  }, []);

  useEffect(() => {
    // Use our render flag to ensure there's something to scroll *to*:
    if (formattedItems) handleScroll(urlParams);
  }, [formattedItems]);

  // Only render once we're ready to go:
  if (formattedItems && currentAmount) {
    return (
      <ImpactSliderCL
        // Prefixed to avoid any IDs starting with a number
        rowID={`slider-${id}`}
        donateLink={process.env.GATSBY_DONATE_URL}
        cartID={cartId}
        heading={sliderHeading}
        items={formattedItems}
        step={increment}
        max={maximum}
        opacityAnimation={opacityAnimation}
        backgroundColour={whiteBackground ? 'white' : 'grey_extra_light'}
        defaultSliderValue={currentAmount}
        id={id}
      >
        {body && renderRichText(body)}
      </ImpactSliderCL>
    );
  }
  return null;
};

ImpactSlider.propTypes = {
  data: PropTypes.shape({
    // Moneybuy #1:
    moneybuy1PriceDecimal: PropTypes.number.isRequired,
    moneybuy1Description: PropTypes.string.isRequired,
    moneybuy1Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    // Moneybuy #2:
    moneybuy2PriceDecimal: PropTypes.number.isRequired,
    moneybuy2Description: PropTypes.string.isRequired,
    moneybuy2Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    // Moneybuy #3:
    moneybuy3PriceDecimal: PropTypes.number.isRequired,
    moneybuy3Description: PropTypes.string.isRequired,
    moneybuy3Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    // Moneybuy #4:
    moneybuy4PriceDecimal: PropTypes.number.isRequired,
    moneybuy4Description: PropTypes.string.isRequired,
    moneybuy4Image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    body: PropTypes.shape({
      raw: PropTypes.string
    }).isRequired,
    cartId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    increment: PropTypes.number.isRequired,
    maximum: PropTypes.number.isRequired,
    defaultSliderValue: PropTypes.number,
    opacityAnimation: PropTypes.bool,
    whiteBackground: PropTypes.bool,
    sliderHeading: PropTypes.string.isRequired
  }).isRequired
};

export default ImpactSlider;
