import styled, { css } from 'styled-components';

import {
  bounceInRight, faceBounceEntry, backgroundUnfold
} from './animations';

const StyledOuterContainer = styled.div`
  width: 100%;
  font-family: Montserrat;
  font-weight: 700;
  background-color: ${(props) => {
    switch (props.step) {
      case 1:
        return ({ theme }) => theme.color('red');
      case 4:
        return ({ theme }) => theme.color('rnd_23_pink');
      default:
        return ({ theme }) => theme.color('rnd_23_teal');
    }
  }};
  color: ${({ theme }) => theme.color('rnd_23_black')};

  /* background image curtain unveil effect  */
  /* beginning of jokes */
  ${props => props.step === 2 && props.backgroundSplash === true && css`
    background-image: linear-gradient(-40deg, ${({ theme }) => theme.color('rnd_23_teal')} 50%, ${({ theme }) => theme.color('red')} 50%);

    background-size: 400% 100%;
    animation: ${backgroundUnfold} 0.8s ease-in-out;
    background-position: right top;
  `}
`;

const StyledInnerContainer = styled.div`
  margin: 0 auto;
  max-width: 55vh;
  height: 82vh;
  @-moz-document url-prefix() {
    height: 83vh;
  }
`;

/**
 * Large single noses on step 2 & 3
 */
const StyledNoseCircle = styled.div`
  margin: -2vh auto 0;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin: 2vh auto;
  }
  @media ${({ theme }) => theme.allBreakpoints('L')} {
    margin: 0 auto;
  }
  --nose-size: 210px;
  width: var(--nose-size);
  height: var(--nose-size);
  border: 3px solid ${({ theme }) => theme.color('white')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ noseChoice, theme }) => (noseChoice === 2 ? theme.color('blue') : theme.color('red'))};
  box-shadow: inset -20px -20px 40px rgba(0, 0, 0, 0.4);
  ${props => props.step === 2 && css`
    animation: ${faceBounceEntry} 1s linear;`
}
`;

const StyledFaceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 70%;
  animation-delay: 0.5s;
`;

/**
 * Questions & Answers
 */
const StyledSpeechWrapper = styled.div`
  margin: 1vh auto 0;
  width: 80%;
  min-height: 150px;
  ${props => props.step === 2 && css`
    animation: ${bounceInRight} 1.2s linear;`
}
`;

const StyledSpeechArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSpeechArrowLeft = styled.div`
  border-bottom: 28px solid ${({ theme }) => theme.color('rnd_24_teal_dark')};
  border-left: 28px solid transparent;
  border-radius: 0px 6px 0px 0px;
  margin-right: 24%;
`;

const StyledSpeechArrowRight = styled.div`
  border-bottom: 28px solid ${({ theme }) => theme.color('white')};
  border-right: 28px solid transparent;
  border-radius: 6px 0px 0px 0px;
  margin-left: 24%;
`;

const StyledSpeechBubble = styled.div`
  background: ${({ step, theme }) => (step === 2 ? theme.color('rnd_24_teal_dark') : theme.color('white'))};
  color: ${({ step, theme }) => (step === 2 ? theme.color('white') : theme.color('rnd_24_teal_dark'))};
  font-size: 1.2rem;
  margin: 0 auto 0;
  padding: 28px 18px;
  border-radius: 16px;
  text-align: center;
`;

const StyledIconButton = styled.button`
  width: 60px;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  border: 4px solid transparent;
  transition: 0.2s;
  svg {
    display: block;
    path {
      fill: ${props => props.iconColor};
    }
    rect:nth-of-type(2) {
      fill: ${props => props.iconColor};
    }
  }
  :hover, :active, :focus {
    border: 4px solid ${props => props.iconColor};
  }
`;

export {
  StyledOuterContainer,
  StyledInnerContainer,
  StyledSpeechBubble,
  StyledSpeechWrapper,
  StyledNoseCircle,
  StyledFaceWrapper,
  StyledSpeechArrowWrapper,
  StyledSpeechArrowLeft,
  StyledSpeechArrowRight,
  StyledIconButton
};
