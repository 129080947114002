/* eslint-disable prefer-destructuring */
import * as yup from 'yup';
import 'yup-phone';
import { isValid } from 'postcode';
import axios from 'axios';
import emailValidation from '../../utils/emailValidation';
import {
  FIELDS,
  FUNDRAISING_GROUPS,
  TARGET_OTHER,
  SCHOOL_FSU_SCHOOL_SEGMENTS
} from './constants';

const trimmedString = () => yup.string().trim();
const trimmedOptionalString = () => yup.string().trim().default('').nullable(true);
const requiredString = message => trimmedString().required(message);
const phoneValidate = yup
  .string()
  .matches(
    /^(((((\+44)|(0044))\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((((\+44)|(0044))\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((((\+44)|(0044))\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\\#(\d{4}|\d{3}))?$/
  );

// ['yes'] = 1, ['no'] = 0, [] = null
export const transformMarketingPref = (val) => {
  if (val[0] === 'yes') {
    return 1;
  }
  if (val[0] === 'no') {
    return 0;
  }
  return null;
};

// Because this value has already been validated to be one or the other,
// we're safe to make this binary assumption:
export const isMobileOrLandline = (number) => {
  const isMobile = number.match(
    /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/
  );
  // Given that both 'SMS' and 'Phone' opt-in can utilise a mobile, pass both fields
  if (isMobile && isMobile.length > 0) {
    return [FIELDS.MP_MOBILE, FIELDS.MP_PHONE];
  }
  // Else, it's a landline, so just pass the landline associated MP option
  return [FIELDS.MP_PHONE];
};

// Helper function required for our phone implentation
export const removeSpace = value => value.replace(/ /g, '');

/* eslint-disable no-template-curly-in-string */
export const getRules = (showTShirtField, showPhoneNumberField) => ({

  [FIELDS.FIRST_NAME]: requiredString('Please enter your first name')
    .matches(
      /^[A-Za-z][A-Za-z' -]*$/,
      "This field only accepts letters and ' - and must start with a letter"
    )
    .max(25, 'Your first name must be between 1 and ${max} characters long'),

  [FIELDS.LAST_NAME]: requiredString('Please enter your last name')
    .matches(
      /^[a-zA-Z0-9][a-zA-Z0-9'.,/()& -]*$/,
      "This field only accepts alphanumeric characters and , . ( ) / & ' - and must start with an alphanumeric character"
    )
    .max(50, 'Your last name must be between 1 and ${max} characters long'),

  [FIELDS.EMAIL]: emailValidation,

  [FIELDS.CONFIRM_EMAIL]: emailValidation.oneOf(
    [yup.ref(FIELDS.EMAIL), null],
    'Emails must match'
  ),

  [FIELDS.GROUP]: requiredString('Please select a group').typeError(
    'Please select a group'
  ),

  [FIELDS.SEGMENT]: trimmedString()
    .when(FIELDS.GROUP, ([thisGroup], schema) => (
      thisGroup === FUNDRAISING_GROUPS.WORK
      // This default seems to not work on FIRST change to thisGroup, only subsequent ones...
        ? schema.default(FUNDRAISING_GROUPS.WORK)
        : schema.required('Please make a selection')
    )),

  // JOB currently refers to the "What is your role?" field
  [FIELDS.JOB]: trimmedString()
    .when([FIELDS.GROUP, FIELDS.SEGMENT], ([thisGroup, thisSegment], schema) => (
      thisGroup === FUNDRAISING_GROUPS.SCHOOL
      && thisSegment !== SCHOOL_FSU_SCHOOL_SEGMENTS.PARENT_OR_CARER.value
      && thisSegment !== SCHOOL_FSU_SCHOOL_SEGMENTS.YOUTH.value
      && thisSegment !== SCHOOL_FSU_SCHOOL_SEGMENTS.SPORTS_CLUB.value
        ? schema.required('Please make a selection')
        : schema.nullable(true).default(null))),

  [FIELDS.ORG_NAME]: trimmedString()
    .when([FIELDS.GROUP, FIELDS.SEGMENT], ([thisGroup, thisSegment], schema) => (
      thisGroup === FUNDRAISING_GROUPS.PUBLIC
      || thisGroup === FUNDRAISING_GROUPS.WORK
      || thisSegment === SCHOOL_FSU_SCHOOL_SEGMENTS.PARENT_OR_CARER.value
        ? schema.nullable(true).default(null)
        : schema.required('This is required')
    )),

  [FIELDS.ORG_SIZE]: trimmedString().nullable(true).default(null),

  [FIELDS.INDUSTRY_TYPE]: trimmedString().nullable(true).default(null),

  [FIELDS.LINE1]: requiredString('Address line 1 is required')
    .max(100, 'Must be between 1 and ${max} characters long')
    .matches(
      /^[a-zA-Z0-9'.,/()& -]*$/,
      "This field only accepts alphanumeric characters and , . ( ) / & ' -"
    ),
  [FIELDS.LINE2]: trimmedOptionalString()
    .max(100, 'Must be between 1 and ${max} characters long')
    .matches(
      /^[a-zA-Z0-9'.,/()& -]*$/,
      "This field only accepts alphanumeric characters and , . ( ) / & ' -"
    ),
  [FIELDS.LINE3]: trimmedOptionalString()
    .max(100, 'Must be between 1 and ${max} characters long')
    .matches(
      /^[a-zA-Z0-9'.,/()& -]*$/,
      "This field only accepts alphanumeric characters and , . ( ) / & ' -"
    ),
  [FIELDS.TOWN]: requiredString('Town/city is required')
    .max(50, 'Town/city must be between 1 and ${max} characters long')
    .matches(/^[a-zA-Z0-9'.,/()& -]*$/, 'This field only accepts alphanumeric characters and , . ( ) / & \' -'),
  [FIELDS.POSTCODE]: requiredString('Postcode is required')
    .test('valid-postcode', 'Please provide a valid postcode', v => !v || isValid(v)),

  // I can't find any usage of these any more, but leaving a papertrail, just in case:
  // [FIELDS.TOOLS]: yup.array().of(yup.string().trim().default('')),
  // [FIELDS.IDEAS]: yup.array().of(yup.string().trim().default('')),

  [FIELDS.TARGET_TEXT]: requiredString(
    'Please choose at least one option'
  ).typeError('Please choose at least one option'),

  [FIELDS.TARGET_POUNDS]: yup.number().when(FIELDS.TARGET_TEXT, {
    is: TARGET_OTHER,
    then: schema => schema
      .typeError('Please enter a number')
      .min(1, 'Please enter a target greater than £0')
      // Need a max to prevent a JustGiving API error. After discussing with team, we are
      // just going with something that no one would ever realistically hit (but which
      // doesn't produce a JG error.)
      .max(100000000, 'Please enter a lower number')
  }),

  [FIELDS.PHONE]:
    showPhoneNumberField
    && trimmedString()
      .transform(removeSpace)
      .test('test-phone', 'Please enter a valid UK phone number', (value) => {
        // As this field is only rendered for CWG journeys,
        // that it's appropriate to validate it
        if (value) return phoneValidate.isValidSync(value);
        return true;
      }),

  [FIELDS.PAGE_TITLE]: trimmedString()
    .trim()
    .matches(
      /^[a-zA-Z0-9!?$£@:;'",.-\s]*$/,
      'Please enter only letters, numbers and any of these special characters:  ! ? $ £ @ : ; \' " , . -'
    )
    .max(100, 'Please enter a maximum of ${max} characters')
    .required('Please enter a name for your page')
    .min(3, 'Please enter at least ${min} characters'),

  [FIELDS.PAGE_STORY]: trimmedString()
    .max(1000, 'Please enter a maximum of ${max} characters')
    .required('Please enter a summary for your page'),

  // CWG Standard journey only
  [FIELDS.TSHIRT_SIZE]:
    showTShirtField && requiredString('Please enter your t-shirt size')
});

// Loosely match strings after normalising
const normalisedStringsMatch = (
  str1,
  str2,
  normalise = str => str.trim().toLowerCase()
) => normalise(str1) === normalise(str2);

// Match postcodes after normalising
const postcodesMatch = (postcode1, postcode2) => normalisedStringsMatch(postcode1, postcode2, postcode => postcode.toLowerCase().replace(/\s/g, ''));

/**
 * Gets the crosl ID of a school
 *
 * Returns null if the submitted school data (name and postcode) no longer matches the selected
 *  school (i.e. the user has manually edited it.)
 *
 * @param formData
 * @param selectedSchool
 * @returns {null|string}
 */
export const getSchoolId = ({ formData, selectedSchool }) => {
  if (
    selectedSchool
    && normalisedStringsMatch(formData[FIELDS.ORG_NAME], selectedSchool.name)
    && postcodesMatch(formData[FIELDS.POSTCODE], selectedSchool.post_code)
  ) {
    return String(selectedSchool.id);
  }
  return null;
};

/**
 * Gets the address ID
 *
 * Returns null if the submitted address no longer matches the selected address (i.e. the user has
 *  manually edited the address after making their selection.)
 *
 * @param formData
 * @param selectedAddress
 * @returns {null|number}
 */
export const getAddressId = ({ formData, selectedAddress }) => {
  if (
    selectedAddress
    && normalisedStringsMatch(formData[FIELDS.LINE1], selectedAddress.Line1)
    && postcodesMatch(formData[FIELDS.POSTCODE], selectedAddress.postcode)
  ) {
    return selectedAddress.address_id;
  }
  return null;
};

/**
 * getCookie helper function, covers both _very specific_ scenarios;
 * directly getting the '_fbp' cookie value, or the 'CRCC4' subvalue
 * of the 'OptanonConsent' cookie, associated with marketing opt-in/outs
 * on our .comicrelief.com subdomains.
 *
 * @param {string} name
 * @param {string} subValue
 * @returns {(string|undefined)} :
 * Either a string value associated with this cookie, or undefined if no match
 */
export function getCookie(name, subValue = null) {
  // Decode for ease of filtering
  const decodedCookies = decodeURIComponent(document.cookie);
  let cookieVal;

  // Ensure cookie is present
  if (decodedCookies.includes(name)) {
    // Return our desired cookie from the decodedCookies string
    cookieVal = decodedCookies.split(`${name}=`)[1];
    // Trim off any content after it
    cookieVal = cookieVal.split(';')[0];

    // If we're after a specific subvalue of this cookie, check it exists first
    if (subValue && cookieVal.includes(subValue)) {
      // Grab the value following the subValue label
      cookieVal = cookieVal.split(`${subValue}:`)[1];
      // As this subValue will only ever be a single 'boolean'-style string value,
      // just grab that one characters
      cookieVal = cookieVal.substring(0, 1);
    } else if (subValue && !cookieVal.includes(subValue)) {
      // Else, if the requested subvalue isn't present, reset
      cookieVal = undefined;
    }
  }
  return cookieVal;
}

export const handleCAPI = (formData, uuid, isCWG, isCWGSchools) => {
  const isProd = process.env.GATSBY_ENVIRONMENT === 'production';
  const submit = process.env.GATSBY_ANALYTICS_SUBMIT === 'true';
  const fbpValue = getCookie('_fbp');
  const isOptingIn = parseInt(getCookie('OptanonConsent', 'CRCC4'), 10);

  let contentCategory = formData.fundraising_group;
  if (isCWG) {
    // As CAPI fields are limited, Hiral agreed to prefix this with audience/group to delineate
    const thisPrefix = isCWGSchools
      ? 'srcw22-schools-fundraising-reg--'
      : 'srcw22-standard-fundraising-reg--';
    contentCategory = thisPrefix.concat(contentCategory);
  }

  // Defaults for non-prod envs
  let eventName = 'TestEvent';
  if (isProd) {
    if (isCWG) eventName = isCWGSchools ? 'SRCW-Schools22' : 'SRCW-Move22';
    else eventName = 'CF22_Conversion';
  }

  const capiFields = {
    'event-name': eventName,
    'event-source-url': window.location.href,
    'action-source': 'website',
    'opt-out': !isOptingIn,
    'content-category': contentCategory,
    'fbp-browser-id': fbpValue,
    status: 'registered',
    'external-id': uuid
  };

  // Always run on prod, but gives us the ability to test on
  // other envs by temporarily updating the env var
  if (isProd || submit) {
    axios.post(process.env.GATSBY_ANALYTICS_URL, capiFields).catch((error) => {
      // Swallowing any CAPI submission error, as to not break user journey
      if (typeof Sentry !== 'undefined') {
        Sentry.captureException(error);
      }
    });
  }
};
