import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, spacing } from '@comicrelief/component-library';

const UnstyledFieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0 0 ${spacing('l')} 0};
`;

const UnstyledLegend = styled.legend`
  padding: 0;
  margin: 0;
`;

const Fieldset = React.forwardRef((
  {
    legend = '',
    description = '',
    children,
    ...otherProps
  }, ref
) => (
  <UnstyledFieldset {...otherProps} ref={ref}>
    <div css={{ marginBottom: (legend || description) ? spacing('m') : 0 }}>
      {legend && (
        <UnstyledLegend>
          <Text tag="h3" size="xl" uppercase bold css={{ marginBottom: spacing('md') }}>{legend}</Text>
        </UnstyledLegend>
      )}
      {description && <Text tag="p">{description}</Text>}
    </div>
    {children}
  </UnstyledFieldset>
));

Fieldset.propTypes = {
  legend: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired
};

export default Fieldset;
