import React from 'react';
import {
  ErrorText,
  Link
} from '@comicrelief/component-library';

const fatalErrorMessage = (
  <ErrorText weight="bold">
    Sorry, something went wrong. Please refresh the page and try again, or contact
    {' '}
    <Link href="mailto:fundraising@comicrelief.com">fundraising@comicrelief.com</Link>
    {' '}
    if the problem persists.
  </ErrorText>
);

export default fatalErrorMessage;
