import styled from 'styled-components';
import loveMonsterImage from '../../../static/assets/monster.png';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color('grey_light')};
  display: flex;
  flex-direction: column;

  // Keeping this handy to repurpose:
  ${({ pageTheme, theme }) => (pageTheme === 'love-monster') && `
    &::after {
      content: "";
      background: url(${loveMonsterImage}) no-repeat;
      display: block;
      width: 200px;
      height: 113px;
      background-size: 100% 100%;
      position: fixed;
      bottom: 0;
      right: -18px;
      z-index: 3;

      @media ${theme.allBreakpoints('L')} {
        width: 350px;
        height: 197px;
        background-size: 100% 100%;
        right: -30px;
      }

      @media ${theme.allBreakpoints('XL')} {
        width: 500px;
        height: 281px;
        background-size: 100% 100%;
        right: -45px;
      }
    }
  `}
`;

export default Wrapper;
