import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { snakeCase } from 'lodash';
import {
  Text, Box, spacing, hideVisually, containers
} from '@comicrelief/component-library';

import renderRichText from '../RichText/RichText';

const Wrapper = styled.div`
  background: ${({ background, theme }) => theme.color(background)};
  padding: ${spacing('md')} ${spacing('md')} 0;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('xl')} ${spacing('l')} 0;
  }
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const Copy = styled.span`
  ${({ centred }) => (centred ? 'text-align: center' : null)}
`;

const Grid = styled.div`
  display: flex;
  width: 100%;
  max-width: ${containers.medium};
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    flex-direction: row;
  }
`;

const Items = styled.div`
  margin: 0 0 ${spacing('md')};
  width: 100%;
  text-align: left;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% / 2 - ${spacing('l')});
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    margin: 0 ${spacing('md')} ${spacing('l')};
  }
  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    flex-basis: ${({ layout }) => {
    switch (layout) {
      case '3 columns':
        return `calc(100% / 3 - ${spacing('l')}); flex-grow: 0`;
      case '4 columns':
        return `calc(100% / 4 - ${spacing('l')}); flex-grow: 0`;
      default:
        return null;
    }
  }
}
  }
`;

const HideText = styled(Text)`
  ${hideVisually};
`;

const Boxes = ({ data }) => {
  const {
    title, cards, layout, backgroundColour
  } = data;

  const bgColour = snakeCase(backgroundColour);

  return (
    <Wrapper background={bgColour}>
      <HideText tag="h2">
        {title}
      </HideText>
      <Grid>
        {cards
          && cards.map((card) => {
            const {
              body, id, image, centredText, squaredCorners
            } = card;

            const fallback = image?.fallback?.src;
            const imageLow = image?.placeHolder?.src;
            const images = image?.fluid?.srcSet;
            const description = image?.description;

            return (
              <Items key={id} layout={layout}>
                <Box
                  imageLow={imageLow}
                  image={fallback}
                  images={images}
                  imageAltText={description}
                  squaredCorners={squaredCorners}
                >
                  <Copy centred={centredText}>
                    {body && renderRichText(body, bgColour, 'card')}
                  </Copy>
                </Box>
              </Items>
            );
          })}
      </Grid>
    </Wrapper>
  );
};

Boxes.propTypes = {
  data: PropTypes.shape({
    cards: PropTypes.shape([]),
    title: PropTypes.string,
    backgroundColour: PropTypes.string,
    layout: PropTypes.string,
    squaredCorners: PropTypes.bool
  }).isRequired
};

export default Boxes;
