import React from 'react';

export default function ArrowLeft() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="background" d="M2.86204 55.5C2.86204 58.5683 14.2754 94.7373 44.8375 94.7373C75.3996 94.7373 98.3374 69.3396 94.8374 43.3991C90.5118 11.3394 62.3416 -2.16059 37.3418 3.33935C12.3419 8.83929 0.774196 36.6281 2.5084 52.8498C5.69995 82.7158 34.1363 101.203 62.3375 92.8396C93.8904 83.4807 105.176 46.906 86.3374 21.3464C66.0441 -3.54969 36.9923 1.15565 28.7754 8.5" fill="white" />
      <path d="M2.86204 55.5C2.86204 58.5683 14.2754 94.7373 44.8375 94.7373C75.3996 94.7373 98.3374 69.3396 94.8374 43.3991C90.5118 11.3394 62.3416 -2.16058 37.3418 3.33935C12.3419 8.83929 0.774196 36.6281 2.5084 52.8498C5.69995 82.7158 34.1363 101.203 62.3375 92.8396C93.8904 83.4807 105.176 46.906 86.3374 21.3464C66.0441 -3.54969 36.9923 1.15564 28.7754 8.5" stroke="#191B1D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M76.0276 49.9649C69.6009 48.4374 61.1126 47.0491 51.1112 47.0752C41.2799 47.1012 29.9039 47.4004 23.5476 48.9247C27.8709 43.6783 33.8068 39.8887 37.4249 34.8282C32.337 39.5404 27.25 44.2541 22.1621 48.9662C27.3786 52.3303 32.5951 55.6943 37.8117 59.0583" stroke="#1D1E1C" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
