import React from 'react';
import { Text } from '@comicrelief/component-library';

const bold = (children, options) => {
  const fontSize = options.type === 'card' ? 'sm' : 'inherit';
  return (
    <Text weight="bold" size={fontSize}>
      {children}
    </Text>
  );
};

export default bold;
