import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  Text, Picture, spacing, containers, ShareButton
} from '@comicrelief/component-library';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import renderRichText from '../../components/RichText/RichText';
import NewsBlock from '../../components/NewsBlock/NewsBlock';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color('grey_light')};
  display: flex;
  flex-direction: column;
`;

const Container = styled.article`
  background: ${({ theme }) => theme.color('white')};
  width: 100%;
  max-width: ${containers.small};
  margin: ${spacing('l')} auto;
  padding: ${spacing('l')} ${spacing('xl')};
`;

const Header = styled.header`
  padding-top: ${spacing('l')};
  text-align: center;
  width: 100%;
  max-width: ${containers.small};
  margin: 0 auto;
`;

const Image = styled.div`
  width: 100%;
  margin: ${spacing('l')} auto;

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    width: 130%;
    margin-left: -15%;
  }
`;

const Body = styled.div`
  width: 100%;
  word-break: break-word;
`;

const PressRelease = ({ data }) => {
  const {
    article: { edges },
    related: { edges: relatedPressReleases }
  } = data;

  const post = edges[0].node;
  const {
    title, displayDate, image, path, summary, body
  } = post;

  const seoSummary = summary !== null && typeof summary.summary !== 'undefined' ? summary.summary : '';
  const seoImage = image ? image.seoImage?.src : null;
  const fluidImage = image ? image.fluid?.srcSet : null;
  const lowImage = image ? image.placeHolder?.src : null;
  const description = image ? image.description : null;

  return (
    <Layout>
      <SEO
        title={title}
        description={seoSummary}
        image={seoImage}
        pathname={`press-releases/${path}`}
        article
        excludeFromSearchEngineIndexing
      >
        {({ siteUrl, seo }) => (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Article',
              author: 'Comic Relief',
              headline: seo.title,
              description: seo.description,
              datePublished: displayDate,
              mainEntityOfPage: seo.url,
              image: {
                '@type': 'imageObject',
                url: seo.image
              },
              publisher: {
                '@type': 'Organization',
                name: 'Comic Relief',
                logo: {
                  '@type': 'imageObject',
                  url: `${siteUrl}assets/logo.jpg`
                }
              }
            })}
          </script>
        )}
      </SEO>
      <Wrapper data-test="press-release">
        <Header>
          <Text tag="h1" size="xxl" color="black" height="1.2" uppercase>
            {title}
          </Text>
          <Text tag="p" size="m">
            {displayDate}
          </Text>
        </Header>
        <Container>
          <div data-test="social-share">
            <ShareButton />
          </div>
          {image ? (
            <Image>
              <Picture
                alt={description}
                images={fluidImage}
                imageLow={lowImage}
                objectFit={false}
              />
            </Image>
          ) : null}
          <Body>{body ? renderRichText(body, 'white', 'news') : null}</Body>
        </Container>
        <div data-test="press-release-related">
          <NewsBlock data={relatedPressReleases} isPressRelease relatedArticlesRow />
        </div>
      </Wrapper>
    </Layout>
  );
};

PressRelease.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.shape({
      edges: PropTypes.shape([])
    }).isRequired,
    related: PropTypes.shape({
      edges: PropTypes.shape([])
    }).isRequired
  }).isRequired
};

export default PressRelease;

export const pageQuery = graphql`
  query getPressRelease($slug: String, $type: String) {
    article: allContentfulPageArticle(filter: { path: { eq: $slug } }) {
      edges {
        node {
          ...ArticleList
        }
      }
    }
    related: allContentfulPageArticle(
      filter: { type: { eq: $type } }
      limit: 3
      sort: { fields: displayDate, order: DESC }
    ) {
      edges {
        node {
          ...ArticleList
        }
      }
    }
  }
`;
