/* eslint-disable no-underscore-dangle */
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';

import {
  Pagination, spacing, ArticleTeaser, containers
} from '@comicrelief/component-library';
import Layout from '../../components/Layout/Layout';
import singleMessage from '../../components/SingleMessage/SingleMessage';
import BiteSize from '../../components/BiteSize/BiteSize';

import SEO from '../../components/SEO/SEO';

const Content = styled.article`
  background-color: ${({ theme }) => theme.color('grey_light')};
  width: 100%;
  padding: ${spacing('xl')} ${spacing('md')};
`;

const Section = styled.section`
  max-width: ${containers.medium};
  padding: 0 0 ${spacing('xl')};
  margin: 0 auto;

  > article:not(:last-child) {
    margin-bottom: ${spacing('l')};
  }
`;

const handleSelect = () => {
  setTimeout(() => {
    const position = document.getElementById('top').offsetTop
      - document.getElementById('top').scrollTop
      + document.getElementById('top').clientTop;
    window.scrollTo({
      top: position,
      behavior: 'smooth'
    });
  }, 100);
};

const handlePaginationUrl = (page) => {
  if (page === 1) {
    return '/press-releases/';
  }
  return `/press-releases/page-${page}`;
};

const PressReleases = (props) => {
  const articles = get(props, 'data.allContentfulPageArticle.edges');
  const pageInfo = get(props, 'data.allContentfulPageArticle.pageInfo');
  const {
    title: currentPageTitle, summary, paragraphs
  } = get(
    props,
    'data.allContentfulPageLandingPage.edges[0].node'
  );

  return (
    <Layout>
      <SEO
        title={currentPageTitle}
        pathname="press-releases"
        description={summary}
      >
        {({ siteUrl }) => (
          <script type="application/ld+json" key="carousel">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'ItemList',
              itemListElement: articles.map((article, position) => ({
                '@type': 'ListItem',
                position,
                url: `${siteUrl}news/${article.node.path}`
              }))
            })}
          </script>
        )}
      </SEO>
      {paragraphs.map((paragraph) => {
        switch (paragraph.__typename) {
          case 'ContentfulBiteSize': {
            return (
              <BiteSize data={
              {
                body: paragraph.body,
                ctaLabel: paragraph.ctaLabel,
                ctaLink: paragraph.ctaLink
              }
          }
              />
            );
          }
          default: {
            return singleMessage(paragraph);
          }
        }
      })}
      <Content data-test="press-releases">
        <Section id="top">
          {articles.map(({ node }) => {
            const {
              image, path, displayDate, categories, title, id
            } = node;
            const description = image ? image.title : title;
            const campaign = categories && categories.length > 0 ? categories[0].title : 'default';
            const URL = `/press-releases/${path}`;
            return (
              <ArticleTeaser
                href={URL}
                category={campaign}
                date={displayDate}
                title={title}
                alt={description}
                logoSize="80px"
                key={id}
              />
            );
          })}
        </Section>
        {pageInfo.pageCount > 1 && (
          <Pagination
            disabledBackgroundColor="grey_dark"
            disabledColor="grey_light"
            maxPages={10}
            totalPages={pageInfo.pageCount}
            currentPage={pageInfo.currentPage}
            createURL={value => handlePaginationUrl(value)}
            onSelect={handleSelect}
            showFirst
            showPrevious={false}
            showNext={false}
            showLast
          />
        )}
      </Content>
    </Layout>
  );
};

export default PressReleases;

export const pageQuery = graphql`
  query getPress($skip: Int, $limit: Int) {
    allContentfulPageArticle(
      skip: $skip
      limit: $limit
      sort: { fields: [displayDate], order: DESC }
      filter: { type: { eq: "Press Release" } }
    ) {
      totalCount
      pageInfo {
        perPage
        currentPage
        hasPreviousPage
        hasNextPage
        itemCount
        pageCount
      }
      edges {
        node {
          ...ArticleList
        }
      }
    }
    allContentfulPageLandingPage(filter: { path: { eq: "press-releases-content" } }) {
      edges {
        node {
          path
          title
          summary {
            summary
          }
          imageSeo {
            ...Picture
          }
          paragraphs {
            ...PressReleasesListParagraph
            ...SingleMessageParagraph
            ...BitesizeParagraph
          }
        }
      }
    }
  }
`;
