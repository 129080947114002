import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SwivelJiggle2 } from '../sharedStyles';
import HomeIcon from './HomeIcon';
import EndIcon from './EndIcon';

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 15px;
`;

const NavIcon = styled.button`
  width: 80px;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  border: 4px solid transparent;
  transition: 0.2s;
  ${SwivelJiggle2}
`;

export default function Navbar({ step, setStep }) {
  const handleNavClick = () => {
    if (step === 2) {
      setStep(3);
    }
    if (step === 3) {
      window.dataLayer.push({
        event: 'brb_page_end'
      });
      setStep(1);
    }
  };

  return (
    <Wrapper>
      <NavIcon onClick={handleNavClick}>
        {step === 2
          ? (
            <EndIcon />
          )
          : (
            <HomeIcon />
          )}
      </NavIcon>
    </Wrapper>
  );
}

Navbar.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired
};
