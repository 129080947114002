import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  Text, spacing, containers
} from '@comicrelief/component-library';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import renderBody from '../../components/RichText/RichText';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color('grey_light')};
  display: flex;
  flex-direction: column;
`;

const Container = styled.article.attrs({
  'data-test': 'default'
})`
  background: ${({ theme }) => theme.color('white')};
  width: 100%;
  max-width: ${containers.small};
  margin: ${spacing('l')} auto;
  padding: ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('l')} ${spacing('xl')};
  }
`;

const Header = styled.header`
  padding-top: ${spacing('l')};
  text-align: center;
  width: 100%;
  max-width: ${containers.small};
  margin: 0 auto;
`;

class Default extends React.Component {
  render() {
    const page = get(this.props, 'data.allContentfulPageDefault.edges[0].node');

    const { title, path, body } = page;
    const seoSummary = '';
    const seoImage = '';

    return (
      <Layout>
        <SEO title={`${title} | Comic Relief`} description={seoSummary} image={seoImage} pathname={path} article />
        <Wrapper>
          <Header>
            <Text tag="h1" size="xxl" color="black" weight="bold" uppercase>
              {title}
            </Text>
          </Header>
          <Container>{body ? renderBody(body, 'white', 'defaultPage') : null}</Container>
        </Wrapper>
      </Layout>
    );
  }
}

export default Default;

export const pageQuery = graphql`
  query getDefault($slug: String) {
    allContentfulPageDefault(filter: { path: { eq: $slug } }) {
      edges {
        node {
          title
          path
          body {
            raw
            references {
              ...Accordion
              ...Asset
              ...HtmlCode
            }
          }
        }
      }
    }
  }
`;
