export { default as schoolImg } from './jpg/FReg-Schools.jpg';
export { default as workImg } from './jpg/FReg-Work.jpg';
export { default as publicImg } from './jpg/FReg-Public.jpg';
export { default as jgImg } from './jpg/FReg-4x3-JG.jpg';
export { default as packImg } from './jpg/FReg-4x3-PhysPack.jpg';
export { default as digitalImg } from './jpg/FReg-4x3-DigPack.jpg';
export { default as downloadsImg } from './jpg/FReg-4x3-Tools.jpg';
export { default as fancyDressImg } from './jpg/FReg-4x3-S_FancyDress.jpg';
export { default as bakingImg } from './jpg/FReg-4x3-S_Baking.jpg';
export { default as sponsoredChallengeImg } from './jpg/FReg-4x3-S_SponsoredChallenge.jpg';
export { default as quizOrGamesImg } from './jpg/FReg-4x3-S_Quiz.jpg';
export { default as talentShowImg } from './jpg/FReg-4x3-S_TalentShow.jpg';
export { default as sportActivityImg } from './jpg/FReg-4x3-S_Sport.jpg';
export { default as somethingElseImg } from './jpg/FReg-4x3-S_SomethingElse.jpg';
export { default as otherImg } from './jpg/FReg-Goal4.jpg';
export { default as target1Img } from './jpg/FReg-Goal1.jpg';
export { default as target2Img } from './jpg/FReg-Goal2.jpg';
export { default as target3Img } from './jpg/FReg-Goal3.jpg';
// New for CWG22:
export { default as cwgRunImg } from './Activity-4x3-S_Run.png';
export { default as cwgWalkImg } from './Activity-4x3-S_Walk.png';
export { default as cwgCycleImg } from './Activity-4x3-S_Cycle.png';
export { default as cwgSwimImg } from './Activity-4x3-S_Swim.png';
export { default as cwgWorkImg } from './CWG22-Workplace.jpeg';
export { default as cwgPublicImg } from './CWG22-Group_or_individual.jpeg';
// Error icon for new red-background Marketing Prefs:
export { default as errorIcon } from './error-icon--white.svg';
