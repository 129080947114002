import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Heading from './Heading';
import Pointer from './Pointer';
import Inputs from './Inputs';
import RedButton from './RedButton';
import StickerDark from './StickerDark';
import StickerLight from './StickerLight';
import {
  Form,
  CenterSection,
  Column,
  StickerDarkWrapper,
  StickerLightWrapper,
  PointerWrapperLeft,
  PointerWrapperRight
} from './step1Styles';

const schema = yup.object().shape({
  schoolName: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 àÀáÁâÂãÃäÄåÅæÆçÇèÈéÉêÊëËìÌíÍîÎïÏðÐòÒóÓôÔõÕöÖøØùÙúÚûÛüÜýÝÿŸß\-' ]{0,100}$/, 'School name must be alphanumeric and less than or equal to 100 characters')
    .trim()
    .max(100, 'School name must be less than or equal to 100 characters'),
  teacherSurname: yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9 àÀáÁâÂãÃäÄåÅæÆçÇèÈéÉêÊëËìÌíÍîÎïÏðÐòÒóÓôÔõÕöÖøØùÙúÚûÛüÜýÝÿŸß\-' ]{0,50}$/, 'Teacher surname must be alphanumeric and less than or equal to 50 characters')
    .trim()
    .max(50, 'Teacher surname must be less than or equal to 50 characters')
});

export default function Step1({
  setShowPopUp,
  userDetails = { schoolName: '' },
  setUserDetails
}) {
  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    shouldFocusError: true
  });

  const onSubmit = async (data) => {
    // tracking
    if (!window.dataLayer) window.dataLayer = [];
    window.dataLayer.push({
      event: 'brb_main_button'
    });

    // mix form data with user link parameter data
    const updatedUserDetails = {
      ...userDetails,
      schoolName: data.schoolName,
      teacherSurname: data.teacherSurname
    };

    // update state
    setUserDetails(updatedUserDetails);

    // send to ERP endpoint
    const endpoint = process.env.GATSBY_BRBCHALLENGE_ENDPOINT;

    fetch(endpoint, {
      method: 'POST',
      mode: 'no-cors',
      body: JSON.stringify(updatedUserDetails),
      headers: { 'Content-Type': 'application/json' }
    });

    setShowPopUp(true);
  };

  return (
    <>
      <Heading />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CenterSection>
          <Column>
            <PointerWrapperLeft>
              <Pointer />
            </PointerWrapperLeft>
            <StickerDarkWrapper>
              <StickerDark />
            </StickerDarkWrapper>
          </Column>
          <RedButton />
          <Column>
            <StickerLightWrapper>
              <StickerLight />
            </StickerLightWrapper>
            <PointerWrapperRight>
              <Pointer />
            </PointerWrapperRight>
          </Column>
        </CenterSection>
        <Inputs
          register={register}
          userDetails={userDetails}
          schoolNameError={errors?.schoolName}
          teacherSurnameError={errors?.teacherSurname}
        />
      </Form>
    </>
  );
}

Step1.propTypes = {
  setShowPopUp: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    schoolName: PropTypes.string
  }),
  setUserDetails: PropTypes.func.isRequired
};
